import Layout from '@/layout/index';

const router = [
  {
    path: '/interactive',
    component: Layout,
    redirect: '/interactive/question',
    meta: {
      auth: true,
      title: '直播互动',
      name: 'Interactive',
      icon: 'saasicon_interactive_img',
      level: 1
    },
    children: [
      {
        path: 'question',
        component: () => import('@/views/MaterialModule/question'),
        meta: { auth: true, title: '表单', name: 'questionMgr', auth_key: 'ui.hide_survey' }
      },
      {
        path: 'addQuestion',
        component: () => import('@/views/MaterialModule/addQuestion'),
        meta: {
          auth: true,
          title: '创建表单',
          name: 'addQuestionMgr',
          auth_key: 'ui.hide_survey',
          activeMenu: '/interactive/question'
        },
        hidden: true
      },
      {
        path: 'prize',
        component: () => import('@/views/MaterialModule/prize'),
        meta: { auth: true, title: '奖品', name: 'prizeMgr', auth_key: 'ui.hide_lottery' }
      },
      {
        path: 'advertCard',
        component: () => import('@/views/LiveModule/Brands/advertisementCard'),
        meta: { auth: true, title: '广告', name: 'advertCard', auth_key: 'ad_recommend' }
      },
      {
        path: 'products',
        component: () => import('@/views/MaterialModule/Products/index'),
        meta: { auth: true, title: '商品与优惠券', name: 'products', auth_key: 'product_show' }
      },
      {
        path: 'addProduct',
        component: () => import('@/views/MaterialModule/Products/create'),
        meta: { auth: true, title: '创建商品', name: 'createproducts', auth_key: 'product_show' },
        hidden: true
      },
      {
        path: 'addCoupon',
        component: () => import('@/views/MaterialModule/Products/createCoupon'),
        meta: { auth: true, title: '创建优惠券', name: 'createCoupon', auth_key: 'product_show' },
        hidden: true
      },
      {
        path: 'addProduct/:goods_id',
        component: () => import('@/views/MaterialModule/Products/create'),
        meta: { auth: true, title: '编辑商品', name: 'editproducts', auth_key: 'product_show' },
        hidden: true
      },
      {
        path: 'editCoupon/:couponId',
        component: () => import('@/views/MaterialModule/Products/createCoupon'),
        meta: { auth: true, title: '编辑优惠券', name: 'editCoupon', auth_key: 'product_show' },
        hidden: true
      },
      {
        path: 'viewCoupon/:couponId',
        component: () => import('@/views/MaterialModule/Products/viewCoupon'),
        meta: { auth: true, title: '商品与优惠券', name: 'viewCoupon', auth_key: 'product_show' },
        hidden: true
      },
      {
        path: 'copyCoupon/:couponId',
        component: () => import('@/views/MaterialModule/Products/createCoupon'),
        meta: { auth: true, title: '复制优惠券', name: 'copyCoupon', auth_key: 'product_show' },
        hidden: true
      },
      {
        path: 'gift',
        component: () => import('@/views/MaterialModule/gift'),
        meta: { auth: true, title: '礼物', name: 'giftMgr', auth_key: 'ui.show_gift' }
      },
      {
        path: 'pushCard',
        component: () => import('@/views/MaterialModule/PushCard/list'),
        meta: { auth: true, title: '推屏卡片', name: 'pushCardMgr', auth_key: 'push_screen_card' }
      },
      {
        path: 'exam',
        component: () => import('@/views/MaterialModule/ExamMgr/exam'),
        meta: { auth: true, title: '快问快答', name: 'examMgr', auth_key: 'exam' }
      },
      {
        path: 'addExam',
        component: () => import('@/views/MaterialModule/ExamMgr/addExam'),
        meta: { auth: true, title: '创建问卷', name: 'addExamMgr', auth_key: 'exam' },
        hidden: true
      },
      {
        path: 'addPushCard',
        component: () => import('@/views/MaterialModule/PushCard/addCard'),
        meta: {
          auth: true,
          title: '创建推屏卡片',
          name: 'addPushCardMgr',
          auth_key: 'push_screen_card'
        },
        hidden: true
      }
    ]
  }
];
export default router;
