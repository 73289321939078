import Layout from '@/layout/index';

const router = [
  {
    path: '/AI',
    component: Layout,
    redirect: '/AI/chat/list',
    meta: { auth: true, title: 'AI智能', name: 'AI', icon: 'saasicon_ai_img', level: 1 },
    children: [
      {
        path: 'chat/list',
        component: () => import('@/views/AIModule/chat/list'),
        meta: { auth: true, title: 'AI聊天', name: 'AIChatList', showOneChildren: true }
      },
      {
        path: 'chat/create',
        component: () => import('@/views/AIModule/chat/edit'),
        meta: {
          auth: true,
          title: '创建',
          level: 2,
          name: 'AIChatCreate',
          activeMenu: '/AI/chat/list'
        },
        hidden: true
      },
      {
        path: 'chat/edit/:id',
        component: () => import('@/views/AIModule/chat/edit'),
        meta: {
          auth: true,
          title: '编辑',
          level: 2,
          name: 'AIChatEdit',
          activeMenu: '/AI/chat/list'
        },
        hidden: true
      }
    ]
  }
];
export default router;
