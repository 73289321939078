import Layout from '@/layout/index';

const router = [
  {
    path: '/other',
    component: Layout,
    redirect: '/other/msgList',
    meta: { auth: true, title: '辅助业务', name: 'other', icon: 'vh-icon-s-help' },
    hidden: true,
    children: [
      {
        path: 'msgList',
        component: () => import('@/views/PlatformModule/Message/list'),
        meta: { auth: true, title: '消息中心', name: 'msgList', icon: 'table' }
      },
      {
        path: 'msgDetail/:str(\\d+)',
        component: () => import('@/views/PlatformModule/Message/detail'),
        meta: {
          auth: true,
          title: '消息详情',
          name: 'msgDetail',
          icon: 'table',
          activeMenu: '/other/msgList'
        },
        hidden: true
      },
      {
        path: 'downloadList',
        component: () => import('@/views/PlatformModule/download'),
        meta: { auth: true, title: '下载中心', name: 'downloadList', icon: 'table' }
      }
    ]
  }
];
export default router;
