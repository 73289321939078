<template>
  <div class="vh-chat-editorArea">
    <div class="input-box">
      <div class="label">欢迎短语</div>
      <div class="input-content">
        <vh-input
          v-model="menuInfo.welcome_content"
          v-clearEmoij
          :maxlength="15"
          autocomplete="off"
          show-word-limit
        ></vh-input>
      </div>
    </div>

    <div class="chat-tips">
      <img src="./chat-tips@2x.png" alt="" />
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      menuInfo: {
        required: false
      }
    },
    data() {
      return {
        welcome: ''
      };
    },

    methods: {
      textChange() {}
    }
  };
</script>
<style lang="less" scoped>
  .vh-chat-editorArea {
    padding: 20px;
    .label {
      display: inline-block;
      width: 60px;
      line-height: 40px;
      margin-right: 10px;
      font-size: 14px;
    }
    .input-content {
      display: inline-block;
      width: 350px;
    }
    .chat-tips {
      margin-top: 24px;
      img {
        max-width: 422px;
      }
    }
  }
</style>
