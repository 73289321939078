<template>
  <div class="file-download-template">
    <ul class="file_list" v-if="menuInfo.components && menuInfo.components.style == 'list'">
      <li v-for="item in list" :key="item.id">
        <div class="file_item">
          <icon
            class="word-status"
            :icon-class="(item.ext || item.file_ext) | wordStatusCss"
          ></icon>
          <div class="file_info">
            <p class="file_name ellsips">{{ item.file_name }}</p>
            <span class="file_size">
              {{ item.file_size || item.size }}/{{ (item.ext || item.file_ext).toUpperCase() }}
            </span>
          </div>
          <vh-button type="primary" round size="mini">下载</vh-button>
        </div>
      </li>
    </ul>
    <ul class="file_list card" v-if="menuInfo.components && menuInfo.components.style != 'list'">
      <li v-for="item in list" :key="item.id">
        <div class="file_item">
          <img
            class="cover_img"
            :src="item.img_info.img_url || file_default"
            :key="item.img_info.img_url || file_default"
            v-parseImgOss="{ url: item.img_info.img_url, default: 1 }"
          />
          <div class="file_info card_info">
            <div>
              <p class="file_name ellsips">{{ item.file_name }}</p>
              <p class="file_desc">{{ item.file_desc }}</p>
            </div>

            <p class="file_size">
              <template>
                {{ item.file_size || item.size }}/{{ (item.ext || item.file_ext).toUpperCase() }}
              </template>

              <vh-button type="primary" round size="mini">下载</vh-button>
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  const staticList = [
    {
      file_name: '企业直播知识进阶地图2.0',
      file_size: '12KB',
      file_ext: 'doc',
      file_id: '62e92545',
      file_type: '0',
      file_desc: '聚焦企业直播全新热门场景、新概念、新元素，对以往地图进行...',
      img_info: {
        img_url: '//cnstatic01.e.vhall.com/common-static/images/file_default1.png'
      }
    },
    {
      file_name: 'B2B企业直播营销实操指南',
      file_size: '12KB',
      file_ext: 'xls',
      file_id: 'ae3ca82d',
      file_type: '0',
      file_desc: '从趋势、场景、行动及效果评估4大维度出发，助力To B企业新...',
      img_info: {
        img_url: '//cnstatic01.e.vhall.com/common-static/images/file_default2.png'
      }
    },
    {
      file_name: '企业直播培训策略白皮书',
      file_size: '12KB',
      file_ext: 'ppt',
      file_id: 'fd09106d',
      file_type: '0',
      file_desc: '',
      img_info: {
        img_url: '//cnstatic01.e.vhall.com/common-static/images/file_default1.png'
      }
    }
  ];
  export default {
    props: {
      menuInfo: {
        required: false
      }
    },
    name: 'component-download',
    data() {
      return {
        file_default: '//cnstatic01.e.vhall.com/common-static/images/file_default.png'
      };
    },
    computed: {
      list() {
        return this.menuInfo.components.data.length > 0
          ? this.menuInfo.components.data
          : staticList;
      }
    }
  };
</script>

<style lang="less" scoped>
  .file-download-template {
    padding: 0 10px;
    height: 100%;
    overflow: auto;
    .file_list {
      li {
        margin-bottom: 16px;
        .file_item {
          display: flex;
          align-items: center;
          .cover_img {
            width: 68px;
            height: 96px;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.06);
          }
          /deep/ .iconfont-v3 {
            font-size: 32px;
          }
          .file_info {
            width: 0;
            flex: 1;
            padding-left: 8px;
            &.card_info {
              height: 96px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
            .file_name {
              line-height: 22px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85);
            }
            .file_desc {
              font-size: 12px;
              line-height: 20px;
              word-break: break-all;
              text-overflow: ellipsis;
              text-overflow: -webkit-ellipsis-lastline;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .file_size {
              font-size: 12px;
              line-height: 17px;
              color: #595959;
              // margin-bottom: -3px;
            }
          }
          ::v-deep .vh-button {
            font-size: 12px;
          }
        }
      }
      &.card {
        /deep/ .iconfont-v3 {
          font-size: 48px;
        }
        .file_size {
          margin-top: 2px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
</style>
