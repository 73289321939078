<template>
  <div class="vh-editor-wrapbox" :style="{ height: height }">
    <vue-tinymce
      ref="editor"
      :content="value"
      :setting="setting"
      :disabled="disabled"
      @change="sendContent"
    ></vue-tinymce>
    <span
      class="set-placeholder"
      v-if="!value"
      onselectstart="return false;"
      unselectable="on"
      @click="handleTinyClick"
    >
      {{ placeholder }}
    </span>
    <div class="word-count">
      <span :class="value && currentCount > 0 && currentCount < (maxWord || 1000) ? 'active' : ''">
        {{ value ? currentCount : 0 }}
      </span>
      / {{ maxWord || '1000' }}
    </div>
  </div>
</template>

<script>
  import tinymce from 'tinymce/tinymce';
  //样式
  // import 'tinymce/skins/content/default/content.min.css';
  // import 'tinymce/skins/ui/oxide/skin.min.css';
  // import 'tinymce/skins/ui/oxide/content.min.css';
  //主题
  import 'tinymce/themes/silver';
  import 'tinymce/icons/default/icons.min.js';
  import 'tinymce/models/dom';
  //插件
  import 'tinymce/plugins/image'; //图片插件
  import 'tinymce/plugins/quickbars'; //快速栏插件
  import 'tinymce/plugins/wordcount'; //快速栏插件
  import 'tinymce/plugins/fullscreen'; //全屏插件
  // import 'tinymce/plugins/paste'; //复制插件
  import 'tinymce/plugins/link'; //超链接插件
  import 'tinymce/plugins/table'; //表格插件
  // import 'tinymce/plugins/media'; //视频插件
  import './tinymce.less';

  import { sessionOrLocal } from '@/utils/utils';
  import VueTinymce from './editorPlugin';
  // import { v1 as uuidV1 } from 'uuid';

  export default {
    name: 'vhall-editor',
    props: {
      modelType: {
        type: String,
        defaut: 'common'
      },
      id: {
        type: String,
        default: function () {
          return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '');
        }
      },
      value: {
        required: true,
        default: ''
      },
      toolbar: {
        type: String,
        required: false,
        default:
          'fontsize bold forecolor backcolor italic underline anchor | alignleft aligncenter alignright alignjustify | link | image | fullscreen'
      },
      height: {
        type: [Number, String],
        required: false,
        default: 360
      },
      isImage: {
        type: Boolean,
        required: false,
        default: true
      },
      saveType: {
        type: String,
        required: false,
        default: 'other'
      },
      maxWord: {
        required: false,
        default: () => 1000
      },
      placeholder: {
        required: false,
        default: () => ''
      },
      disabled: {
        default: false
      }
    },

    components: {
      VueTinymce
    },

    created() {},
    updated() {},
    data() {
      return {
        // content: this.value || '',
        tinymceId: this.id,
        vm: null,
        setting: {
          selector: `#${this.tinymceId}`,
          resize: 'both',
          plugins: `fullscreen link wordcount  ${this.isImage ? 'image' : ''} table`,
          // 字体Icon 库。  等瑞芳提供完整时 进行替换
          icons_url: '//cnstatic01.e.vhall.com/saas/common_libs/editor/icons.js',
          icons: 'vhall',
          image_dimensions: false,
          // media_dimensions: false, // 上传视频设置宽高
          // media_alt_source: true, // 上传视频设置视频加载失败的提示语
          toolbar: this.toolbar,
          tooltip: '',
          quickbars_selection_toolbar:
            'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
          // file_picker_types: 'media',
          // file_picker_callback: (callback, value, meta) => {
          //   if (meta.filetype == 'media') {
          //     let input = document.createElement('input'); //创建一个隐藏的input
          //     input.setAttribute('type', 'file');
          //     let that = this;
          //     input.onchange = function () {
          //       let file = this.files[0]; //选取第一个文件
          //       that.uploadVideo(file).then(domain_url => {
          //         callback(domain_url, { title: file.name }); //将url显示在弹框输入框中
          //       });
          //     };
          //     //触发点击
          //     input.click();
          //   }
          // },
          // media_live_embeds: true,
          // 引入汉化组件
          language_url: require('../../common/js/tinynce/zh_CN.js'),
          language: 'zh_CN',
          skin_url:
            'https://cnstatic01.e.vhall.com/common-static/middle/tinymce/5.10.3/skins/ui/oxide',
          content_css: [
            'https://cnstatic01.e.vhall.com/common-static/middle/tinymce/5.10.3/skins/ui/oxide/content.min.css',
            'https://cnstatic01.e.vhall.com/common-static/middle/tinymce/5.10.3/skins/ui/main.css'
          ],
          font_formats:
            'sans-serif=sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=Comic Sans MS,sans-serif;Courier New=courier new, courier;Helvetica=helvetica;Symbol=symbol;Tahoma=tahoma, arial, helvetica, sans-serif;Times New Roman=times new roman, times',
          paste_as_text: true, //只粘贴文本
          height: this.height || 300,
          menubar: false, // 隐藏菜单
          default_link_target: '_blank',
          convert_urls: false, // 关闭url自动识别转换
          content_style: `body {font-family:'-apple-system', 'BlinkMacSystemFon', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif} div{font-size: 14px;} p{color:#1a1a1a; margin: 0px; border:0px ; font-size: 14px; padding: 0px; word-break: break-all;}`, // 关闭默认p标签间距
          font_size_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
          // paste_data_images: false, // 允许粘贴图像
          images_file_types: 'jpeg,jpg,png,gif,bmp',
          urlconverter_callback: (url, node, onSave, name) => {
            if (node === 'img' && url.startsWith('blob:')) {
              // Do some custom URL conversion
              // console.log('urlConverter:', url, node, onSave, name)
              tinymce.activeEditor && tinymce.activeEditor.uploadImages();
            }
            // Return new URL
            return url;
          },
          images_upload_handler: (blobInfo, success, failure) => {
            console.log('========== upload info ============');
            let files = new window.File([blobInfo.blob()], blobInfo.filename(), {
              type: blobInfo.type
            });
            console.log(files, '当前文件对象');
            let pathVo = {
              live: `webinars/intro-imgs/${dayjs().format('YYYYMM')}`,
              customTabImg: `interacts/menu-imgs`,
              special: `webinars/subject-imgs`,
              other: `webinars/saas-tinymce`
            };
            const param = {
              token: sessionOrLocal.get('token', 'localStorage') || '',
              platform: 17,
              type: 'image',
              resfile: files,
              path: pathVo[this.saveType]
            };
            return this.$fetch('uploadImage', param, {
              'Content-Type': 'multipart/form-data'
            })
              .then(res => {
                if (res && res.code === 200) {
                  return res.data.domain_url;
                } else {
                  // debugger;
                  return res.msg || '上传失败';
                }
              })
              .catch(res => {
                return res.msg || '上传失败';
              });
          }
        },
        currentCount: 0
      };
    },
    watch: {
      value: {
        handler(newVal) {
          if (newVal) {
            this.sendContent(newVal);
          }
        },
        immediate: true
      }
    },
    methods: {
      // getHeaders() {
      //   let headers = {
      //     token: sessionOrLocal.get('token', 'localStorage') || '',
      //     platform: 17,
      //     'request-id': uuidV1()
      //   };
      //   // 取缓存userId相关
      //   if (window.sessionStorage.getItem('userId')) {
      //     headers['gray-id'] = window.sessionStorage.getItem('userId');
      //   }
      //   return headers;
      // },
      // // 上传视频
      // uploadVideo(file) {
      //   console.log(file);
      //   let date = new Date();
      //   return this.$fetch(
      //     'uploadImage',
      //     {
      //       path: `interacts/upload/${date.getFullYear()}-${date.getMonth() + 1}`,
      //       resfile: file,
      //       type: 'video'
      //     },
      //     {
      //       'Content-Type': 'multipart/form-data'
      //     }
      //   ).then(res => {
      //     if (res.code === 200) {
      //       return res.data.domain_url;
      //     } else {
      //       this.$vhMessage.error('视频上传失败');
      //     }
      //   });
      // },
      handleTinyClick() {
        this.$refs.editor?.getInstance()?.focus();
      },
      // 内容修改后，将信息返回
      sendContent(text) {
        this.currentCount = this.$refs.editor
          ?.getInstance()
          ?.plugins?.wordcount?.body?.getCharacterCount();
        if (this.currentCount > this.maxWord) {
          if (this.vm) {
            this.vm.close();
            this.messageInfo();
          } else {
            this.messageInfo();
          }
          // this.$vhMessage.warning('您输入的内容超出1000限制，已自动取消')
          this.$refs.editor.getInstance().setContent(this.value);
          this.$emit('input', this.value);
          return;
        } else {
          this.$emit('input', text);
        }
      },
      //文案提示问题
      messageInfo() {
        const message =
          this.modelType == 'restriction'
            ? `您输入的内容超出${this.maxWord}限制`
            : `您输入的内容超出${this.maxWord}限制，已自动取消`;
        this.vm = this.$vhMessage({
          showClose: false,
          duration: 2000,
          message,
          type: 'warning'
        });
      }
    }
  };
</script>
<style lang="less" scoped>
  /deep/ .tox-statusbar {
    display: none !important;
  }
  // /deep/.tox-tinymce{
  //   padding-bottom: 25px !important;
  // }
  /deep/.tox .tox-tbtn svg {
    display: block;
    fill: #666 !important;
  }
  /deep/.tox .tox-tbtn {
    color: #666;
  }
  /deep/.tox .tox-tbtn:hover,
  /deep/.tox .tox-tbtn:active {
    background: #e6e6e6;
    color: #666 !important;
  }
  .vh-editor-wrapbox {
    position: relative;
    /deep/ .tox-tinymce {
      border-radius: 4px;
      border: 1px solid #ccc;
    }
    /deep/.tox-tinymce {
      padding: 0;
    }
    /deep/ .tox-toolbar__primary {
      background: #f7f7f7;
      border-bottom: 1px solid #ccc;
    }
    /deep/ .tox .tox-tbtn {
      width: 30px;
      height: 30px;
    }
    /deep/ .tox .tox-tbtn--select {
      width: 70px;
    }
  }
  /deep/ .tox.tox-tinymce {
    &:hover,
    &:focus {
      border-color: #999;
    }
  }
  .word-count {
    position: absolute;
    right: 25px;
    bottom: 0;
    font-size: 14px;
    color: #d9d9d9;
    line-height: 40px;
    > span {
      color: rgb(191, 191, 191);
      &.active {
        color: rgb(38, 38, 38);
      }
    }
  }
  .set-placeholder {
    position: absolute;
    top: 42px;
    left: 10px;
    z-index: 500;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: text;
  }
</style>
