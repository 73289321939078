<template>
  <div
    :class="[
      type === 'textarea' ? 'vh-textarea' : 'vh-input',
      inputSize ? 'vh-input--' + inputSize : '',
      {
        'is-disabled': inputDisabled,
        'is-exceed': inputExceed,
        'vh-input-group': $slots.prepend || $slots.append,
        'vh-input-group--append': $slots.append,
        'vh-input-group--prepend': $slots.prepend,
        'vh-input--prefix': $slots.prefix || prefixIcon,
        'vh-input--suffix': $slots.suffix || suffixIcon || clearable || showPassword
      }
    ]"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <template v-if="type !== 'textarea'">
      <!-- 前置元素 -->
      <div class="vh-input-group__prepend" v-if="$slots.prepend">
        <slot name="prepend"></slot>
      </div>
      <input
        :tabindex="tabindex"
        v-if="type !== 'textarea'"
        class="vh-input__inner"
        v-bind="$attrs"
        :type="showPassword ? (passwordVisible ? 'text' : 'password') : type"
        :disabled="inputDisabled"
        :readonly="readonly"
        :autocomplete="autoComplete || autocomplete"
        ref="input"
        @compositionstart="handleCompositionStart"
        @compositionupdate="handleCompositionUpdate"
        @compositionend="handleCompositionEnd"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        @keyup.enter="handleChange"
        :aria-label="label"
        :style="textStyle"
      />
      <!-- 前置内容 -->
      <span class="vh-input__prefix" v-if="$slots.prefix || prefixIcon">
        <slot name="prefix"></slot>
        <i class="vh-input__icon" v-if="prefixIcon" :class="prefixIcon"></i>
      </span>
      <!-- 后置内容 -->
      <span class="vh-input__suffix" v-if="getSuffixVisible()">
        <span class="vh-input__suffix-inner">
          <template v-if="!showClear || !showPwdVisible || !isWordLimitVisible">
            <slot name="suffix"></slot>
            <i class="vh-input__icon" v-if="suffixIcon" :class="suffixIcon"></i>
          </template>
          <i
            v-if="showClear"
            class="vh-input__icon vh-icon-circle-close vh-input__clear"
            @mousedown.prevent
            @click="clear"
          ></i>
          <i
            v-if="showPwdVisible"
            class="vh-input__icon vh-icon-view vh-input__clear"
            @click="handlePasswordVisible"
          ></i>
          <span ref="limit_count" v-if="isWordLimitVisible" class="vh-input__count">
            <span class="vh-input__count-inner">
              <span
                :class="[
                  'vh-input__count-inner__numerator',
                  `${value}`.length && `${value}`.length != $attrs.maxlength
                    ? 'vh-input__count-inner__numerator-hasnum'
                    : '',
                  `${value}`.length && `${value}`.length == $attrs.maxlength
                    ? 'vh-input__count-inner__numerator-maxnum'
                    : ''
                ]"
              >
                {{ textLength }}
              </span>
              <span class="vh-input__count-inner__denominator">
                <span ref="separator">/</span>
                <span ref="limit_total">{{ upperLimit }}</span>
              </span>
            </span>
          </span>
        </span>
        <i
          class="vh-input__icon"
          v-if="validateState"
          :class="['vh-input__validateIcon', validateIcon]"
        ></i>
      </span>
      <!-- 后置元素 -->
      <div class="vh-input-group__append" v-if="$slots.append">
        <slot name="append"></slot>
      </div>
    </template>
    <textarea
      v-else
      :tabindex="tabindex"
      class="vh-textarea__inner"
      @compositionstart="handleCompositionStart"
      @compositionupdate="handleCompositionUpdate"
      @compositionend="handleCompositionEnd"
      @input="handleInput"
      ref="textarea"
      v-bind="$attrs"
      :disabled="inputDisabled"
      :readonly="readonly"
      :autocomplete="autoComplete || autocomplete"
      :style="textareaStyle"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
      :aria-label="label"
    ></textarea>
    <span v-if="isWordLimitVisible && type === 'textarea'" class="vh-input__count">
      <span
        :class="[
          'vh-input__count-inner__numerator',
          `${value}`.length && `${value}`.length != $attrs.maxlength
            ? 'vh-input__count-inner__numerator-hasnum'
            : '',
          `${value}`.length && `${value}`.length == $attrs.maxlength
            ? 'vh-input__count-inner__numerator-maxnum'
            : ''
        ]"
      >
        {{ textLength }}
      </span>
      <span class="vh-input__count-inner__denominator">
        <span ref="separator">/</span>
        <span ref="limit_total">{{ upperLimit }}</span>
      </span>
    </span>
  </div>
</template>

<script>
  import { Input } from 'vhall-ui';
  export default {
    extends: Input,
    mounted() {
      this.calcWidth =
        this.$refs.limit_total && this.$refs.limit_total.offsetWidth
          ? this.$refs.limit_total.offsetWidth * 2 + this.$refs.separator.offsetWidth + 15 + 'px'
          : '30px';
    },
    data() {
      return {
        calcWidth: ''
      };
    },
    computed: {
      textStyle() {
        return {
          paddingRight: this.calcWidth
        };
      }
    }
  };
</script>

<style lang="less"></style>
