import Layout from '@/layout/index';
import other from './other';
const router = [
  {
    path: '/cms/website',
    name: 'cms',
    component: Layout,
    redirect: '/cms/website',
    meta: {
      auth: true,
      auth_key: 'cms.website',
      title: '聚合站点',
      name: 'Cms',
      icon: 'cmsicon_website_img',
      level: 1
    },
    children: [
      {
        path: '/cms/website',
        component: () => import('@/views/Cms'),
        meta: { auth: true, title: '聚合站点', auth_key: 'cms.website', name: 'cmsWebsite' }
      }
    ]
  },
  {
    path: '/cms/index',
    name: 'cms',
    component: Layout,
    redirect: '/cms/index',
    meta: {
      auth: true,
      auth_key: 'cms.page',
      title: '单场大会',
      name: 'Cms',
      icon: 'saasicon_single_img',
      level: 1
    },
    children: [
      {
        path: '/cms/index',
        component: () => import('@/views/Cms'),
        meta: { auth: true, title: '单场大会', auth_key: 'cms.page', name: 'cmsList' }
      }
    ]
  },
  {
    path: '/cms/series',
    name: 'cms',
    component: Layout,
    redirect: '/cms/series',
    meta: {
      auth: true,
      auth_key: 'cms.series',
      title: '系列会',
      name: 'Cms',
      icon: 'cmsicon_series_img',
      level: 1
    },
    children: [
      {
        path: '/cms/series',
        component: () => import('@/views/Cms'),
        meta: { auth: true, title: '系列会', auth_key: 'cms.series', name: 'cmsSeries' }
      }
    ]
  },
  {
    path: '/cms/embed-setting',
    name: 'cmsEmbedSetting',
    component: Layout,
    redirect: '/cms/embed-setting/link',
    meta: {
      auth: true,
      auth_key: 'cms.show_global_embed_link',
      title: '运营设置',
      name: 'CmsEmbedSetting',
      icon: 'saasicon_Settings_img',
      level: 1,
    },
    children: [
      {
        path: 'link',
        name: 'link',
        component: () => import('@/views/Cms'),
        meta: {
          auth: true,
          name: 'GlobalEmbedLinkSet',
          auth_key: 'cms.show_global_embed_link',
          title: '全局嵌入设置',
          activeMenu: '/cms/embed-setting/link',
          level: 2,
          showOneChildren: true,// 控制只有一个children时展示
        }
      },
    ]
  },
  {
    path: '/cms/edit',
    component: () => import('@/views/Cms/AliveCms.vue'),
    meta: { title: 'cms站点编辑', name: 'CmsEdit' },
    hidden: true,
    children: [
      {
        path: '/cms/edit/:path',
        component: () => import('@/views/Cms/AliveCms.vue'),
        meta: { auth: false },
        hidden: true
      }
    ]
  },
  {
    path: '/cms/:path',
    component: Layout,
    meta: { title: 'cms站点编辑', name: 'CmsEdit' },
    hidden: true,
    children: [
      {
        path: '/cms/:path',
        component: () => import('@/views/Cms'),
        meta: { auth: false },
        hidden: true
      }
    ]
  },
  ...other
];
export default router;
