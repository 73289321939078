<template>
  <div class="search-area">
    <vh-form :inline="true" :model="searchParams" ref="searchForm" onsubmit="return false;">
      <vh-form-item v-for="(item, index) in searchAreaLayout" :key="index">
        <vh-button v-if="item.type == 5" @click="deletedChecked" round>批量删除</vh-button>
        <!-- 日期时间选择器 -->
        <vh-date-picker
          round
          v-else-if="item.type == 2"
          v-model="searchParams[item.key]"
          value-format="yyyy-MM-dd"
          type="daterange"
          prefix-icon="iconfont-v3 saasicon_date"
          @change="changeDate"
          :unlink-panels="true"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        />
        <!-- 日期选择器 -->
        <vh-date-picker
          round
          v-model="searchParams[item.key]"
          v-else-if="item.type == 4"
          value-format="yyyy-MM-dd"
          @change="changeDate"
          type="date"
          prefix-icon="iconfont-v3 saasicon_date"
          placeholder="选择日期"
        ></vh-date-picker>
        <!-- 下拉框 -->
        <vh-select
          filterable
          round
          v-model="searchParams[item.key]"
          v-else-if="item.type == 3"
          style="width: 160px"
          @change="changeDate"
          :placeholder="item.placeholder"
        >
          <vh-option
            v-for="(opt, optIndex) in item.options"
            :key="optIndex"
            :label="opt.label"
            :value="opt.value"
          />
        </vh-select>
        <vh-checkbox
          round
          v-model="searchParams[item.key]"
          v-else-if="item.type == 7"
          @change="changeDate"
        >
          {{ item.name }}
        </vh-checkbox>
        <vh-button v-else-if="item.type == 6" @click="searchList" size="medium" round>
          查询
        </vh-button>
        <!-- 输入框 -->
        <vh-input
          v-else
          round
          class="search-tag"
          :placeholder="placeholder"
          v-model="searchParams[item.key]"
          clearable
          v-clearEmoij
          @change="changeInput"
          @keyup.enter.native="changeInput"
        >
          <i class="vh-icon-search vh-input__icon" slot="prefix" @click="changeInput"></i>
        </vh-input>
      </vh-form-item>
    </vh-form>
    <div class="export-data" v-if="isExports">
      <vh-button plain :disabled="isForbidExport" round size="medium" @click="exportData">
        导出数据
      </vh-button>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        searchParams: {
          searchIsTime: '1',
          type: 1
        },
        pickerOptions: {
          // disabledDate是一个函数,参数是当前选中的日期值,这个函数需要返回一个Boolean值,
          disabledDate: time => {
            return this.dealDisabledData(time);
          }
        }
      };
    },
    props: {
      searchAreaLayout: Array,
      isDate: {
        type: Boolean,
        default: false
      },
      isExports: {
        type: Boolean,
        default: true
      },
      isForbidExport: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: true
      },
      placeholder: {
        type: String,
        default: '请输入标题'
      },
      scene: {
        type: String,
        require: false,
        default: ''
      }
    },
    created() {
      if (this.isDate) {
        this.initPage();
      }
    },
    methods: {
      initPage() {
        // 初始化设置日期为最近一周
        const end = new Date();
        const start = new Date();
        end.setTime(end.getTime() - 3600 * 1000 * 24);
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        this.searchParams.searchTime = [
          dayjs(start).format('YYYY-MM-DD'),
          dayjs(end).format('YYYY-MM-DD')
        ];
      },
      dealDisabledData(time) {
        // 设置选择的日期小于当前的日期,小于返回true,日期不可选
        if (this.scene === 'center_data') {
          return time.getTime() > Date.now() - 8.64e7; //设置选择今天之前的日期（不能选择当天）
        } else {
          return time.getTime() > Date.now(); //设置选择今天以及今天以前的日期
        }
      },
      changeDate() {
        if (this.$route.path == '/finance/infoDetail') {
          return;
        }
        this.$emit('onSearchFun');
      },
      changeInput() {
        this.$emit('onSearchFun');
      },
      deletedChecked() {
        this.$emit('deletedChecked');
      },
      searchList() {
        this.$emit('onSearchFun');
      },
      exportData() {
        this.$emit('onExportData');
      }
    }
  };
</script>
<style lang="less" scoped>
  .search-area {
    position: relative;
    .search {
      margin-left: 20px;
      background: #fb3a32;
      color: #fff;
      border: 0;
    }
    .export-data {
      position: absolute;
      right: 0;
      top: 0;
    }
    .search-tag {
      width: 220px !important;
    }
  }
</style>
