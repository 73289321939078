<template>
  <div id="app" :class="[isEmbed ? 'embed-app' : '', appCls]">
    <router-view />
  </div>
</template>
<script>
  export default {
    data() {
      return {
        isCollapse: false
      };
    },
    computed: {
      isEmbed() {
        return this.$route.path.indexOf('embed') > -1;
      },
      appCls() {
        return this.$route.meta?.appCls || '';
      }
    }
  };
</script>
<style lang="less">
  html {
    height: 100%;
  }
  body {
    margin: 0 0 !important;
  }
  #app {
    min-width: 1366px;
    height: 100%;
    background: #f7f7f7;
    &.embed-app {
      min-width: auto;
    }
  }
</style>
