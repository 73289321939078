import Layout from '@/layout/index';
const router = [
  {
    path: '/data',
    component: Layout,
    redirect: '/data/info',
    meta: {
      auth: true,
      title: '活动数据',
      name: 'dataMgr',
      icon: 'saasicon_charts_img'
    },
    children: [
      {
        path: 'info',
        component: () => import('@/views/StatisticsModule/dataInfo'),
        meta: { auth: true, title: '数据总览', name: 'dataInfo' }
      },
      {
        path: 'live',
        component: () => import('@/views/StatisticsModule/dataLive'),
        meta: { auth: true, title: '活动数据', name: 'dataLive' }
      }
    ]
  }
];
export default router;
