export default {
  data() {
    return {
      businessSiteLink: '', // 全局嵌入链接
      businessShortDomain: '' // B租户短链服务域名
    };
  },
  methods: {
    /**
     * 获取B租户域名配置列表
     * config_subtype
     *    0-全局嵌入链接
     *    1-event嵌入链接
     *    2-live嵌入链接
     *    3-短链接
     */
    getBusinessDomainConfigList() {
      let params = {
        business_uid: this.userId, //B端用户id
        config_type: 0 //配置类型，0-链接
        // config_subtype: 0, //配置子类型，如：config_type=0时，0-全局嵌入链接 1-event嵌入链接 2-live嵌入链接 3-短链接
      };
      this.$fetch('getBusinessDomainConfigList', params)
        .then(res => {
          let list = res?.data?.list || [];
          this.businessSiteLink = list.find(item => item.config_subtype === 0)?.config_value || '';
          this.businessShortDomain =
            list.find(item => item.config_subtype === 3)?.config_value || '';
        })
        .catch(error => {
          this.businessSiteLink = '';
          console.error(error);
        });
    }
  }
};
