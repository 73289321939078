module.exports = {
  language: {
    name: '简体中文'
  },
  message: {
    living: '直播中',
    preView: '预告',
    end: '结束',
    playBack: '回放',
    views: '次观看',
    original: '原画',
    video720: '超清',
    video480: '高清',
    video360: '标清',
    audio: '音频',
    loading: '加载中...',
    switchVideo: '已为你切换到',
    contentLoading: '内容即将呈现...',
    landscapeTips: '你已经进入横屏模式',
    tip: '提示',
    confirm: '确认',
    voicePlaying: '语音播放中...',
    speed: '倍速',
    switchSpeed: '已为您切换到',
    switchNormal: '已为您切换到正常倍速',
    day: '天',
    hour: '时',
    minute: '分',
    second: '秒',
    replay: '重新播放',
    liveEnd: '直播已结束',
    speedFailed: '倍速设置失败',
    sharpnessFailed: '清晰度设置失败',
    发送太频繁啦: '发送太频繁啦~还有',
    秒可以发言: '秒可以发言',
    // --------白名单----------
    白名单验证: '白名单验证',
    请输入身份信息: '请输入身份信息',
    确认: '确认',
    活动不存在: '活动不存在',
    您已预约成功: '您已预约成功，',
    直播当天请访问此页面观看直播: '直播当天请访问此页面观看直播',
    预约成功: '预约成功',
    您的信息不在白名单中: '您的信息不在白名单中',
    没有白名单: '没有白名单',
    setHostTips: ' 设置成为主讲人',
    normal: '正常',
    hostLeft: '暂时离开了页面，请稍等...',
    host: '主持人',
    compatabilityTipA:
      '当前浏览器不能很好的支持直播的正常播放，为了更好的直播体验，推荐您使用Chrome浏览器进行观看',
    compatabilityTipB: '当前浏览器版本较低，请升级浏览器版本或使用Chrome浏览器',
    downloadBrowser: '点击下载',
    // saas-popup组件相关
    okText: '好的',
    tipText: '提示', // saas-popup组件
    clickToPlay: '您已进入房间，开始观看吧',
    cancelText: '取消',
    confirmText: '确定',
    retryText: '重试',
    knowText: '知道了'
  }
};
