<template>
  <div class="hixianchang-wrapbox">
    <div class="hixianchang-editor-box">
      <div class="editor-box">
        <vh-card class="box-card">
          <div slot="header" class="clearfix card-header">
            <span class="card-title">
              使用流程
              <vh-tooltip effect="dark" placement="top-start">
                <div slot="content">
                  1.通过大屏幕同步展示观众的互动消息，实现文字、图片、投票、抽奖、签到等多种互动游戏；
                  <br />
                  2.可以免费创建活动进行体验，免费版仅支持20人，正式发布需要联系商务进行开通权限；
                  <br />
                  3.互动游戏仅支持在WAP端展示
                </div>
                <i class="vh-tooltip iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
            </span>
            <div class="func-opt">
              <a
                href="https://saas-doc.vhall.com/opendocs/show/1608"
                class="text-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                使用说明
              </a>
            </div>
          </div>
          <div class="step-containter">
            <div class="title">1、创建游戏互动活动</div>
            <div class="content">
              <p class="intro">点击下方按钮进游戏互动管理后台，创建活动并体验</p>
              <div class="form">
                <vh-button
                  type="primary"
                  round
                  size="medium"
                  v-preventReClick
                  @click="hxcProuserLogin()"
                >
                  进入互动管理后台
                </vh-button>
              </div>
            </div>
          </div>

          <div class="step-containter">
            <div class="title">2、关联直播间</div>
            <div class="content">
              <p class="intro">
                填写游戏互动活动ID，关联直播间
                <a
                  href="https://saas-doc.vhall.com/opendocs/show/1608#%E7%9B%B4%E6%92%AD%E5%85%B3%E8%81%94%E6%B8%B8%E6%88%8F%E4%BA%92%E5%8A%A8%E6%B4%BB%E5%8A%A8"
                  class="text-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  如何获取游戏互动活动ID
                </a>
              </p>
              <p class="intro">关联后，直播页面将嵌入游戏互动页面，观众可以边看直播边参与互动</p>
              <div class="form">
                <vh-input
                  class="input-code"
                  placeholder="请输入互动标识"
                  v-model="wall_flag_current"
                ></vh-input>
                <vh-button
                  class="opt-btn"
                  type="primary"
                  round
                  size="medium"
                  v-preventReClick
                  @click="setCodeWebinar"
                  :disabled="wall_flagStatus"
                >
                  {{ associationText }}
                </vh-button>
                <vh-button
                  v-if="wall_flagStatus"
                  class="opt-btn"
                  plain
                  round
                  size="medium"
                  v-preventReClick
                  @click="resetCode()"
                >
                  重置
                </vh-button>
              </div>
            </div>
          </div>

          <div class="step-containter">
            <div class="title">3、发布活动</div>
            <div class="content">
              <p class="intro">免费测试版限20人参与，联系商务升级套餐发布活动</p>
              <div class="form">
                <vh-button
                  v-if="status == 0"
                  class="opt-btn"
                  type="primary"
                  round
                  size="medium"
                  :disabled="!publishBtnStatus"
                  v-preventReClick
                  @click="hxcProwallPublish()"
                >
                  正式发布
                </vh-button>

                <vh-button
                  v-if="status == 1"
                  class="opt-btn"
                  plain
                  round
                  size="medium"
                  :disabled="status == 1"
                >
                  已发布
                </vh-button>

                <vh-button
                  v-if="status == 2"
                  class="opt-btn"
                  plain
                  round
                  size="medium"
                  :disabled="status == 2"
                >
                  已过期
                </vh-button>
              </div>
              <div class="form">
                <vh-button
                  class="opt-btn"
                  plain
                  round
                  size="medium"
                  :disabled="!wall_flag"
                  v-preventReClick
                  @click="hxcManagement()"
                >
                  管理互动
                </vh-button>
                <vh-button
                  class="opt-btn"
                  type="primary"
                  round
                  size="medium"
                  :disabled="!wall_flag"
                  v-preventReClick
                  @click="hxcOpenProwallScreen()"
                >
                  进入大屏幕
                </vh-button>
              </div>
            </div>
          </div>
        </vh-card>
      </div>
    </div>
  </div>
</template>
<script>
  // import { isEmptyObj } from '@/utils/utils.js';
  export default {
    name: 'component-hixianchang',
    props: {
      menuInfo: {
        required: false
      },
      units: {
        required: false
      },
      pre: {
        required: true
      }
    },
    data() {
      return {
        wall_flag: '',
        wall_flag_current: '',
        status: 0, //状态 0 未发布，1已发布 2已过期
        can_publish: 0 // 是否有发布权限， 0：无，1：有
      };
    },
    computed: {
      // 关联按钮显示文案
      associationText() {
        if (this.wall_flag != '') {
          return '已关联';
        } else {
          return '关联';
        }
      },
      //绑定状态 0 未关联，1 已关联
      wall_flagStatus() {
        if (this.wall_flag != '') {
          return true;
        } else {
          return false;
        }
      },
      //正式发布按钮显示逻辑
      publishBtnStatus() {
        if (this.status == 0 && this.can_publish == 1) {
          //可以正式发布
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      console.log('component-hixianchang-------------->', this.menuInfo, this.units, this.pre);

      // 获取配置详情信息
      this.getInfo();
    },
    methods: {
      /**
       * 获取相关配置信息
       * @returns {any}
       */
      getInfo() {
        this.$fetch('interactsFthdInfo', {
          webinar_id: this.$route.params.str
        })
          .then(res => {
            if (res.code == 200) {
              this.wall_flag = res.data.wall_flag || '';
              this.wall_flag_current = res.data.wall_flag || '';
              this.status = res.data.status || 0;
              this.can_publish = res.data.can_publish || 0;
            }
          })
          .catch(err => {
            // this.$vhMessage({
            //   message: err.msg,
            //   showClose: true,
            //   type: 'warning'
            // });
          });
      },
      /**
       * B端用户登录并进入游戏互动页面
       * @returns {any}
       */
      hxcProuserLogin() {
        let params = {
          webinar_id: this.$route.params.str
        };

        this.$fetch('interactsFthdBussinessLogin', params)
          .then(res => {
            if (res.code == 200) {
              window.open(res.data.url, '_blank');
            }
          })
          .catch(err => {
            this.$vhMessage({
              message: err.msg,
              showClose: true,
              type: 'warning'
            });
          });
      },
      //获取大屏幕地址
      hxcOpenProwallScreen() {
        this.$fetch('interactsFthdBigScreen', {
          webinar_id: this.$route.params.str
        })
          .then(res => {
            if (res.code == 200) {
              window.open(res.data.url, '_blank');
            }
          })
          .catch(err => {
            this.$vhMessage({
              message: err.msg,
              showClose: true,
              type: 'warning'
            });
          });
      },

      //活动发布
      hxcProwallPublish() {
        this.$fetch('interactsFthdPublish', {
          webinar_id: this.$route.params.str
        })
          .then(res => {
            if (res.code == 200) {
              this.status = 1;
              this.$vhMessage({
                message: '发布成功',
                showClose: true,
                type: 'success'
              });
            }
          })
          .catch(err => {
            this.$vhMessage({
              message: err.msg,
              showClose: true,
              type: 'warning'
            });
          });
      },
      /**
       * 绑定的活动
       * @returns {any}
       */
      setCodeWebinar() {
        console.log('setCodeWebinar----->', this.$route.params.str);

        this.$fetch('interactsFthdBindWebinar', {
          wall_flag: this.wall_flag_current,
          webinar_id: this.$route.params.str
        })
          .then(res => {
            if (res.code == 200) {
              // this.wall_flag = this.wall_flag_current;
              this.$vhMessage({
                message: '关联成功',
                showClose: true,
                type: 'success'
              });
              // 重新获取最新信息
              this.getInfo();
            }
          })
          .catch(err => {
            this.$vhMessage({
              message: err.msg,
              showClose: true,
              type: 'warning'
            });
          });
      },
      /**
       * 重置绑定的活动
       * @returns {any}
       */
      resetCode() {
        console.log('resetCode----->', this.$route.params.str);
        this.$fetch('interactsFthdResetBind', {
          webinar_id: this.$route.params.str
        })
          .then(res => {
            if (res.code == 200) {
              this.$vhMessage({
                message: '重置成功',
                showClose: true,
                type: 'success'
              });
              // 重新获取最新信息
              this.getInfo();
            }
          })
          .catch(err => {
            this.$vhMessage({
              message: err.msg,
              showClose: true,
              type: 'warning'
            });
          });
      },
      /**
       * 活动管理页地址
       * @returns {any}
       */
      hxcManagement() {
        let params = {
          webinar_id: this.$route.params.str
        };
        this.$fetch('interactsFthdManagement', params)
          .then(res => {
            if (res.code == 200) {
              window.open(res.data.url, '_blank');
            }
          })
          .catch(err => {
            this.$vhMessage({
              message: err.msg,
              showClose: true,
              type: 'warning'
            });
          });
      }
    }
  };
</script>
<style lang="less" scoped>
  .hixianchang-wrapbox {
    margin-bottom: 20px;
  }

  .hixianchang-editor-box {
    padding-top: 16px;

    .text-link {
      color: #0a7ff5;
      :hover {
        color: #0060cf;
      }
    }

    .card-title {
      font-size: 16px;
      font-weight: bold;
    }

    .card-header {
      .func-opt {
        float: right;
        font-size: 14px;
      }
    }

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: '';
    }
    .clearfix:after {
      clear: both;
    }

    .step-containter {
      .title {
        font-size: 14px;
        font-weight: bold;
      }
      .content {
        padding: 10px;
      }
      .input-code {
        width: 170px;
      }
      .opt-btn {
        margin: 0 10px;
      }
      .intro {
        line-height: 20px;
        font-size: 12px;
        a.text-link {
          margin: 0 10px;
        }
      }
      .form {
        padding: 20px 0;
      }
    }
  }
</style>
