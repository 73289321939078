<template>
  <div class="vh-customer-previewPc">
    <template v-for="(item, index) in units">
      <div :key="index" v-if="item.component_id == 1">
        <component-img-txt :info="item" mode="1"></component-img-txt>
      </div>
      <div :key="index" v-if="item.component_id == 2">
        <component-qr-code :info="item" mode="1"></component-qr-code>
      </div>
      <div :key="index" v-if="item.component_id == 3">
        <component-video :info="item" :checkedList="item.webinars" mode="1"></component-video>
      </div>
      <div :key="index" v-if="item.component_id == 4">
        <component-special :info="item" :checkedList="item.subjects" mode="1"></component-special>
      </div>
      <div :key="index" v-if="item.component_id == 5">
        <component-text-link :info="item" mode="1"></component-text-link>
      </div>
      <div :key="index" v-if="item.component_id == 6">
        <component-img-link :info="item" mode="1"></component-img-link>
      </div>
      <div :key="index" v-if="item.component_id == 7" style="margin-bottom: 16px">
        <component-title :info="item" mode="1"></component-title>
      </div>
      <div :key="index" v-if="item.component_id == 8">
        <component-hr :info="item" mode="1"></component-hr>
      </div>
      <div :key="index" v-if="item.component_id == 9">
        <component-rank :info="item" mode="1" :pre="pre"></component-rank>
      </div>
      <div :key="index" v-if="item.component_id == 10">
        <component-third-link :info="item" mode="1" :pre="pre"></component-third-link>
      </div>
    </template>
  </div>
</template>

<script>
  import ComponentVideo from './component-video';
  import ComponentSpecial from './component-special';

  import ComponentImgTxt from '../componet-editor/component-img-txt';
  import ComponentHr from '../componet-editor/component-hr';
  import ComponentQrCode from '../componet-editor/component-qr-code';
  import ComponentTextlink from '../componet-editor/component-text-link';
  import ComponentImglink from '../componet-editor/component-img-link';
  import ComponentTitle from '../componet-editor/component-title';
  import ComponentRank from '../componet-editor/component-rank';
  import componentThirdlink from '../componet-editor/component-third-link';

  export default {
    props: {
      menuInfo: {
        required: true
      },
      units: {
        required: true
      },
      pre: {
        required: true
      }
    },

    components: {
      'component-img-txt': ComponentImgTxt,
      'component-qr-code': ComponentQrCode,
      'component-video': ComponentVideo,
      'component-special': ComponentSpecial,
      'component-text-link': ComponentTextlink,
      'component-img-link': ComponentImglink,
      'component-title': ComponentTitle,
      'component-hr': ComponentHr,
      'component-rank': ComponentRank,
      'component-third-link': componentThirdlink
    },

    data() {
      return {};
    },

    methods: {}
  };
</script>
<style lang="less" scoped></style>
