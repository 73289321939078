// session存储（设置、获取、删除）
import fetchData from '@/api/fetch';
import NProgress from 'nprogress';
import Cookies from 'js-cookie';
import { v1 as uuidV1 } from 'uuid';
import embedInfo from './embedInfo';
import router from '@/router/index';
import store from '../store';
export function isEmbed(path) {
  let checkPath = path || window.location.pathname;
  return checkPath.indexOf('/embed/') > -1;
}

export function embedRoomId() {
  if (isEmbed()) {
    try {
      const liveDetailInfo = JSON.parse(sessionOrLocal.get('liveDetailInfo'));
      return liveDetailInfo.vss_room_id;
    } catch (e) {
      console.error(e);
    }
  }
}

export function jump(route) {
  if (isEmbed()) {
    router.push(route);
  } else {
    const { href } = router.resolve(route);
    window.open(href, '_blank');
  }
}

export const sessionOrLocal = {
  set: (key, value, saveType = 'sessionStorage') => {
    if (!key) return;
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    // alert(`当前${sessionOrLocal.saveType}`);
    window[saveType].setItem(key, value);
  },
  get: (key, saveType = 'sessionStorage') => {
    if (!key) return;
    return window[saveType].getItem(key);
  },
  removeItem: (key, saveType = 'sessionStorage') => {
    if (!key) return;
    window[saveType].removeItem(key);
  },
  clear: (saveType = 'sessionStorage') => {
    window[saveType].clear();
  }
};

// 判断是否IE
export function isIE() {
  return (
    !!window.ActiveXObject || 'ActiveXObject' in window || navigator.userAgent.indexOf('Edge') > -1
  );
}

export function isObject(source) {
  return Object.prototype.toString.call(source) === '[object Object]';
}

export function browserDetect() {
  let chromeReg = /Chrome\/(\d{2})[.\d]+\sSafari\/[.\d]+$/gi;
  let chromeResult = chromeReg.exec(navigator.userAgent);
  let safariReg = /(\d+\.\d)?(?:\.\d)?\s+safari\/?(\d+\.\d+)?/i;
  let safariResult = navigator.userAgent.match(safariReg);
  return chromeResult
    ? !(chromeResult.length == 0 || chromeResult[1] < 61)
    : !(
        !safariResult ||
        safariResult.length == 0 ||
        parseInt(safariResult[1].split('.').join('')) < 121
      );
}

// 尺寸重置
export function resize() {
  if (isIE()) {
    let evt = window.document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(evt);
  } else {
    const resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);
  }
}

/**
 * 防抖原理：一定时间内，只有最后一次操作，再过wait毫秒后才执行函数
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
export const debounce = (function () {
  let timeout = null;
  return function (func, wait = 500, immediate = false) {
    // 清除定时器
    if (timeout !== null) clearTimeout(timeout);
    // 立即执行，此类情况一般用不到
    if (immediate) {
      var callNow = !timeout;
      timeout = setTimeout(function () {
        timeout = null;
      }, wait);
      if (callNow) typeof func === 'function' && func();
    } else {
      // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
      timeout = setTimeout(function () {
        typeof func === 'function' && func();
      }, wait);
    }
  };
})();

export function calculateAudioLevel(level) {
  let audioLevelValue = 1;
  if (level > 0 && level <= 0.04) {
    audioLevelValue = 1;
  } else if (level > 0.04 && level <= 0.16) {
    audioLevelValue = 2;
  } else if (level > 0.16 && level <= 0.36) {
    audioLevelValue = 3;
  } else if (level > 0.36 && level <= 0.64) {
    audioLevelValue = 4;
  } else if (level > 0.64 && level <= 1) {
    audioLevelValue = 5;
  }
  return audioLevelValue;
}

export function calculateNetworkStatus(status) {
  let netWorkStatus = 2;
  if (status >= 0 && status <= 2) {
    netWorkStatus = 2;
  } else if (status > 2 && status <= 9) {
    netWorkStatus = 1;
  } else if (status > 9 && status <= 100) {
    netWorkStatus = 0;
  } else {
    netWorkStatus = 0;
  }
  return netWorkStatus;
}

export function copy(text) {
  return new Promise((resolve, reject) => {
    const inputEl = document.createElement('input');
    inputEl.type = 'text';
    inputEl.value = text;
    inputEl.style.height = '0px';
    inputEl.style.position = 'fixed';
    document.documentElement.appendChild(inputEl);
    inputEl.select();
    try {
      let result = document.execCommand('copy');
      if (result) {
        resolve();
      } else {
        reject(new Error('浏览器暂不支持复制功能，请升级浏览器'));
      }
    } catch (error) {
      reject(error);
    }
    document.documentElement.removeChild(inputEl);
  });
}

/**
 *  判断传入参数的类型，以字符串的形式返回
 **/
export function dataType(obj) {
  if (obj === null) return 'Null';
  if (obj === undefined) return 'Undefined';
  return Object.prototype.toString.call(obj).slice(8, -1);
}

/**
 * 处理对象参数值，排除对象参数值为”“、null、undefined，并返回一个新对象
 **/
export function dealObjectValue(obj) {
  let param = {};
  if (obj === null || obj === undefined || obj === '') return param;
  for (let key in obj) {
    if (dataType(obj[key]) === 'Object') {
      param[key] = dealObjectValue(obj[key]);
    } else if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      param[key] = obj[key];
    }
  }
  return param;
}

/**
 * domainCovert
 **/
export function domainCovert(baseDomain, url) {
  // 若value包含域名，自动反显；
  // 若value不包含域名，拼接展示。
  if (url === '' || url === undefined || url === null) {
    return url;
  } else if (url.indexOf(baseDomain) !== -1) {
    return url;
  } else {
    return `${baseDomain}${url}`;
  }
}

/**
 * parseURL 路径转换 去除
 * //t-alistatic01.e.vhall.com/upload/
 * 或者
 * //t-vhallsaas-static.oss-cn-beijing.aliyuncs.com/upload/
 **/
export function parseURL(url) {
  /* let a = document.createElement('a');
   a.href = url;
   return {
     path: a.pathname.replace(/^([^\/])/,'/$1').replace('/upload/', '')
   };*/
  // 如果图片域名不是 saas 的域名，直接返回原路径
  if (
    !(url.indexOf('t-alistatic01.e.vhall.com') > -1) &&
    !(url.indexOf('cnstatic01.e.vhall.com') > -1)
  ) {
    return {
      path: url
    };
  }
  // 查找/upload/位置，保留之后的路径

  let targetIndex = url.indexOf('/upload/');
  if (targetIndex > 0) {
    return {
      path: url.substr(targetIndex + 8, url.length)
    };
  } else {
    return {
      path: url
    };
  }
}

/**
 * 验证文件格式与大小
 * @param file 文件
 * @param that 提示消息类型
 * @param type 类型
 * @param limitSize 图片的限制大小
 * @returns {Boolean} 验证通过还是失败
 */
export function checkUploadType(file, that, type = 1, limitSize = 2, callback) {
  const typeList = type === 1 ? ['png', 'jpeg', 'gif', 'bmp'] : type;
  let typeArr = file.type.toLowerCase().split('/');
  const isType = typeList.includes(typeArr[typeArr.length - 1]);
  const isLt2M = file.size / 1024 / 1024 < limitSize;
  if (!isType) {
    that.$vhMessage({
      message: `上传图片只能是 ${typeList.join('、')} 格式`,
      showClose: true,
      // duration: 0,
      type: 'error',
      customClass: 'zdy-info-box'
    });
    return false;
  }
  if (!isLt2M) {
    that.$vhMessage({
      message: `上传图片大小不能超过 ${limitSize}M`,
      showClose: true,
      // duration: 0,
      type: 'error',
      customClass: 'zdy-info-box'
    });
    return false;
  }
  /*
  let imgSrc = window.URL.createObjectURL(file);
  let img = new Image();
  img.src = imgSrc;
  img.onload = function () {
    // 我在这里就可以获取到图片的宽度和高度了 img.width 、img.height
    if (img.width !== 1280 && img.height !== 720) {
      that.$vhMessage({
        message: `请上传1280*720尺寸图片`,
        showClose: true,
        // duration: 0,
        type: 'error',
        customClass: 'zdy-info-box'
      });
      return false;
    }
  };
  */
  if (isType && isLt2M) {
    if (callback) {
      callback();
    } else {
      return true;
    }
  }
}

// 加载图片
export function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function (e) {
      // 图片加载完成后执行的代码
      resolve(img, e);
    };
    img.onerror = function (err) {
      reject(err);
    };
    img.src = url;
  });
}

export function getQueryString(name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  let r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
export function hideFavicon() {
  var link = document.createElement('link');
  link.setAttribute('rel', 'icon');
  link.setAttribute('href', 'data:;base64,=');
  document.getElementsByTagName('head')[0].appendChild(link);
}
//给链接追加参数
export function addURLParameters(url, params) {
  if (!url) return '';

  var baseUrl = url.split('?')[0];
  var queryString = url.split('?')[1] || '';
  var existingParams = queryString
    ? queryString.split('&').map(function (param) {
        var parts = param.split('=');
        // 使用decodeURIComponent以确保反编码从URL中取出的参数
        return { key: decodeURIComponent(parts[0]), value: decodeURIComponent(parts[1] || '') };
      })
    : [];

  // 将现有的参数转换为键值对对象
  var paramsObj = {};
  existingParams.forEach(function (param) {
    paramsObj[param.key] = param.value;
  });

  // 添加或更新新参数
  for (var key in params) {
    paramsObj[encodeURIComponent(key)] = encodeURIComponent(params[key]);
  }

  // 构建新的查询字符串
  var newQueryString = Object.keys(paramsObj)
    .map(function (key) {
      return key + '=' + paramsObj[key];
    })
    .join('&');

  // 构造完整的URL
  return baseUrl + (newQueryString ? '?' + newQueryString : '');
}
// 判断是否登录成功
export function checkAuth(to, from, next, that) {
  let isOld = false;
  if (location.search && getQueryString('form') == 1) {
    isOld = true;
  }
  if (
    to.path.indexOf('/keylogin-host') !== -1 ||
    to.path.indexOf('/keylogin') !== -1 ||
    to.path.indexOf('/embedclient') !== -1 ||
    from.path.indexOf('/keylogin') !== -1 ||
    to.path.indexOf('/subscribe') !== -1 ||
    to.path.indexOf('/entryform') !== -1 ||
    to.path.indexOf('/live/watch') !== -1 ||
    to.path.indexOf('/register') !== -1 ||
    to.path.indexOf('/live/room') !== -1 ||
    to.path.indexOf('/forgetPassword') !== -1 ||
    (to.path.indexOf('/live/room') !== -1 && sessionOrLocal.get('interact_token')) ||
    (to.path.indexOf('/chooseWay') !== -1 && sessionOrLocal.get('interact_token')) ||
    to.path.indexOf('/upgrading') !== -1 ||
    to.path.indexOf('/warning/') !== -1 ||
    to.path.indexOf('/cMiddle') != -1 ||
    to.path.indexOf('/privacy') != -1
  ) {
    // 不验证直接进入
    next();
    NProgress.done();
    return;
  }
  // 第一步，判断是否第三方快捷登录
  let user_auth_key = getQueryString('user_auth_key');
  let auth_tag = sessionOrLocal.get('tag', 'localStorage');
  let sourceTag = sessionOrLocal.get('sourceTag');
  let scene_id = 1;
  if (auth_tag) {
    scene_id = auth_tag.indexOf('bind') !== -1 ? 3 : auth_tag === 'withdraw' ? 2 : 1; // 场景id：1登录 2提现绑定 3账户信息-账号绑定
  }
  if (user_auth_key) {
    let params = {
      source: sourceTag ? 2 : 1, // 1 控制塔 2观看端 3admin
      key: getQueryString('user_auth_key'),
      scene_id: scene_id
    };
    fetchData('callbackUserInfo', params, { credentials: true })
      .then(res => {
        if (res.data && res.code === 200) {
          // 登录场景下，存储直接登录
          if (Number(scene_id) === 1) {
            sessionOrLocal.set('token', res.data.token || '', 'localStorage');
            sessionOrLocal.set('tokenExpiredTime', res.data.exp_time, 'localStorage');
            sessionOrLocal.set('tokenRefresh', new Date().getTime(), 'localStorage');
            // sessionOrLocal.set('sso_token', res.data.sso_token || '');
            sessionOrLocal.set('userId', res.data.user_id || '');
            if (sessionOrLocal.get('userId')) {
              // 用户登录完成后，用户ID写入Cookie
              Cookies.set('gray-id', res.data.user_id, { expires: 30 });
            }
          }
          // 非观看页第三方登录场景，均跳转/home
          if (!sourceTag) {
            if (auth_tag) {
              if (auth_tag.indexOf('bind') !== -1) {
                // 绑定成功
                window.location.href = `${window.location.origin}${process.env.VUE_APP_WEB_KEY}/acc/info?tab=2`;
              }
            } else {
              window.location.href = `${window.location.origin}${process.env.VUE_APP_WEB_KEY}/home`;
            }
            return;
          }
        } else {
          // 非200情况下，若是3账户信息-账号绑定，提示当前账号已绑定，请解绑。
          if (auth_tag) {
            if (auth_tag.indexOf('bind') !== -1) {
              sessionOrLocal.set('bind_result', JSON.stringify(res));
              sessionOrLocal.set('user_auth_key', user_auth_key);
              // 绑定成功
              window.location.href = `${window.location.origin}${process.env.VUE_APP_WEB_KEY}/acc/info?tab=2`;
            } else {
              // 获取回调token失败
              that.$vhMessage({
                message: `登录信息获取失败，请重新登录`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
              sessionOrLocal.clear('localStorage');
              sessionOrLocal.clear();
            }
          } else {
            that.$vhMessage({
              message: res.msg || '异常请求，无法操作',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            sessionOrLocal.clear('localStorage');
            sessionOrLocal.clear();
          }
        }
      })
      .catch(e => {
        // 非200情况下，若是3账户信息-账号绑定，提示当前账号已绑定，请解绑。
        if (auth_tag) {
          if (auth_tag.indexOf('bind') !== -1) {
            sessionOrLocal.set('bind_result', JSON.stringify(e));
            sessionOrLocal.set('user_auth_key', user_auth_key);
            // 绑定成功
            window.location.href = `${window.location.origin}${process.env.VUE_APP_WEB_KEY}/acc/info?tab=2`;
          } else {
            // 获取回调token失败
            that.$vhMessage({
              message: '登录信息获取失败，请重新登录',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            sessionOrLocal.clear('localStorage');
            sessionOrLocal.clear();
          }
        } else {
          // 获取回调token失败
          that.$vhMessage({
            message: e.msg || '登录信息获取失败，请重新登录',
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          sessionOrLocal.clear('localStorage');
          sessionOrLocal.clear();
        }
      });
    return;
  }
  // 第二步，判断是否控制台 或者 地址栏token携带进入
  let token = sessionOrLocal.get('token', 'localStorage') || '';
  if (token) {
    // 已登录不准跳转登录页
    if (to.path === '/login') {
      next({ path: '/' });
      NProgress.done();
      return;
    }
    fetchData('planFunctionGet', { scene_id: 3 })
      .then(res => {
        if (res && res.code === 200) {
          let permissions_str = res.data.permissions;
          const permissions = JSON.parse(permissions_str);
          store.commit('setPermissionValue', JSON.parse(res.data.permission_value));
          if (permissions) {
            if (!permissions['ui.hide_favicon']) {
              hideFavicon();
            }
            if (to.meta.name == 'chooseWay' && to.meta.level == undefined) {
              // 每次切换页面都先重置下页面title
              // 如果是邀请的链接
              document.title = '';
            } else if (to.meta.name == 'userHome' && to.meta.level == undefined) {
              // 如果是个人首页
              document.title = '';
            } else if (to.meta.name == 'specialDetail' && to.meta.level == undefined) {
              // 如果是个人专题详情
              document.title = '';
            } else {
              if (!permissions['ui.hide_seo_vhall']) {
                document.title =
                  '直播,网络直播,视频直播,在线教育平台,视频直播平台,网络直播系统,中国最大的网络直播互动平台';
              } else {
                document.title = window.SAAS_vhall_title;
              }
            }
            // 设置全部权限
            sessionOrLocal.set('SAAS_VS_PES', permissions_str, 'localStorage');
          } else {
            sessionOrLocal.removeItem('SAAS_VS_PES');
          }
        }
      })
      .catch(e => {
        console.log(e);
        let out_url = sessionOrLocal.get('SAAS_V3_CTRL_OUT', 'localStorage');
        sessionStorage.clear();
        localStorage.clear();
        if (e.code == 511006) {
          if (out_url) {
            // 清除cookies
            Cookies.remove('gray-id');
            window.location.href = out_url;
          } else {
            next({ path: '/login', query: { form: isOld == 1 ? 1 : 0 } });
          }
        }
        sessionOrLocal.removeItem('SAAS_VS_PES');
      });
    // 登录后，获取用户基本信息
    fetchData('getInfo', { scene_id: 2 })
      .then(res => {
        // debugger;
        if (res.code === 200) {
          if (res.data.is_new_regist == 2) {
            sessionOrLocal.clear();
            sessionOrLocal.clear('localStorage');
            // 清除cookies
            Cookies.remove('gray-id');
            next({ path: '/upgrading' });
            NProgress.done();
          } else {
            // 用户登录完成后，用户ID写入Cookie
            Cookies.set('gray-id', res.data.user_id, { expires: 30 });
          }
          sessionOrLocal.set('userInfo', JSON.stringify(res.data));
          sessionOrLocal.set('userId', JSON.stringify(res.data.user_id));
          sessionOrLocal.set('currentDate', JSON.stringify(res.data.current_date));
          // 获取配置项所有内容
          if (
            to.path.indexOf('/live/') != -1 &&
            (to.query.webinar_id || to.params.id || to.query.id)
          ) {
            fetchData('planFunctionGet', {
              webinar_id: to.query.webinar_id || to.params.id || to.query.id,
              webinar_user_id: res.data.user_id,
              scene_id: 1
            })
              .then(res => {
                if (res && res.code === 200 && res.data.permissions) {
                  let permissions = res.data.permissions;
                  if (permissions) {
                    // 设置活动全部权限
                    sessionOrLocal.set('WEBINAR_PES', permissions, 'localStorage');
                  }
                }
              })
              .catch(e => {
                console.log('刷新等情况下获取活动下接口配置项情况，异常不做任何处理');
              });
          }
        } else {
          sessionOrLocal.set('userInfo', null);
          sessionOrLocal.removeItem('token'); // 无效token, 删除token
        }
        // 获取子账号数据
        fetchData('sonCountGet', {})
          .then(result => {
            if (result && result.code === 200) {
              sessionOrLocal.set(SAAS_V3_COL.KEY_1, JSON.stringify(result.data || {}));
            }
          })
          .catch(e => {});
        // 嵌入页获取活动信息和活动配置
        embedInfo(to, from, next);
        NProgress.done();
      })
      .catch(e => {
        console.log(e);
        let out_url = sessionOrLocal.get('SAAS_V3_CTRL_OUT', 'localStorage');
        if (out_url) {
          // 清除cookies
          Cookies.remove('gray-id');
          sessionOrLocal.clear();
          sessionOrLocal.clear('localStorage');
          window.location.href = out_url;
        } else {
          next({ path: '/login' });
          NProgress.done();
        }
      });
  } else {
    // 若无token，专题详情、个人主页亦是可以登录得
    // 清空缓存后的登录页必须通行(否则死循环)
    const pathArr = ['/special/detail', '/user/home', '/login'];
    const canPass = pathArr.some(path => to.path.indexOf(path) > -1);
    if (canPass) {
      next();
      NProgress.done();
      return;
    }
    // 其余退回登录页
    next({ path: '/login', query: { form: isOld == 1 ? 1 : 0 } });
    NProgress.done();
  }
}

// element-ui 中用来判断 text-overflow 的工具方法 start

const SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g;
const MOZ_HACK_REGEXP = /^moz([A-Z])/;
const ieVersion = Number(document.documentMode);

/* istanbul ignore next */
const camelCase = function (name) {
  return name
    .replace(SPECIAL_CHARS_REGEXP, function (_, separator, letter, offset) {
      return offset ? letter.toUpperCase() : letter;
    })
    .replace(MOZ_HACK_REGEXP, 'Moz$1');
};

/* istanbul ignore next */
export const getStyle =
  ieVersion < 9
    ? function (element, styleName) {
        if (!element || !styleName) return null;
        styleName = camelCase(styleName);
        if (styleName === 'float') {
          styleName = 'styleFloat';
        }
        try {
          switch (styleName) {
            case 'opacity':
              try {
                return element.filters.item('alpha').opacity / 100;
              } catch (e) {
                return 1.0;
              }
            default:
              return element.style[styleName] || element.currentStyle
                ? element.currentStyle[styleName]
                : null;
          }
        } catch (e) {
          return element.style[styleName];
        }
      }
    : function (element, styleName) {
        if (!element || !styleName) return null;
        styleName = camelCase(styleName);
        if (styleName === 'float') {
          styleName = 'cssFloat';
        }
        try {
          var computed = document.defaultView.getComputedStyle(element, '');
          return element.style[styleName] || computed ? computed[styleName] : null;
        } catch (e) {
          return element.style[styleName];
        }
      };

// element-ui 中用来判断 text-overflow 的工具方法 end

// 刷新 token
export const refreshToken = () => {
  const token = sessionOrLocal.get('token', 'localStorage');
  if (
    token !== undefined &&
    token !== 'undefined' &&
    token !== '' &&
    token !== null &&
    token !== 'null'
  ) {
    //发起端、控制台进入页面添加刷新token机制,每七天刷新一次。  7*24*3600*1000 mm
    let tokenRefresh = sessionOrLocal.get('tokenRefresh', 'localStorage') || new Date().getTime();
    tokenRefresh = parseFloat(tokenRefresh);
    const curTime = new Date().getTime();
    const dur = 7 * 24 * 3600 * 1000;
    if (curTime - tokenRefresh > dur) {
      return fetchData('refreshToken')
        .then(res => {
          sessionOrLocal.set('token', res.data.token || '', 'localStorage');
          sessionOrLocal.set('tokenRefresh', new Date().getTime(), 'localStorage');
          sessionOrLocal.set('tokenExpiredTime', res.data.exp_time, 'localStorage');
        })
        .catch(error => {
          // token 失效
          if (error.code == 511006 || error.code == 511007 || error.code == 511004) {
            sessionOrLocal.removeItem('token');
            sessionOrLocal.removeItem('tokenExpiredTime');
          }
        });
    }
  }
};

// 清空Cookies
export const clearCookies = () => {
  const keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (let i = keys.length; i--; ) {
      // document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString();
      Cookies.remove(keys[i], { path: '' });
    }
  }
  // 重点关注如何清空缓存key
  // Cookies.remove('token', { path: '' })
  // Cookies.remove('sso_token', { path: '' })
};

/**
 * @description 多次替换对一个问题做替换(使用uuid作为占位符)
 * @param longText String 替换前文本
 * @param rules Array 替换前后的数据封装体
 * @returns string
 */
export const replaceWithRules = (longText, rules = []) => {
  rules.forEach(rule => {
    rule.tempSign = uuidV1(); // 先替换为占位符
    longText = longText.replace(rule.before, rule.tempSign);
  });
  rules.forEach(rule => {
    longText = longText.replace(rule.tempSign, rule.after);
  });
  return longText;
};

// 解析纯图片地址
export const getImageQuery = url => {
  if (url.indexOf('?') != -1) {
    let arr = url.split('?');
    return arr[0];
  } else {
    return url;
  }
};

// 是否是空对象
export const isEmptyObj = obj => {
  return Object.keys(obj).length === 0;
};

/**
 * 判断是否为空，如果是null/undefined、空字符串、空数组、空对象，则认为是空
 * @param {*} value
 * @returns
 */
export const isEmpty = function (value) {
  // 检查null、undefined
  if (value == null) return true;

  // 检查数组、字符串
  if (typeof value === 'string' || Array.isArray(value)) return value.length === 0;

  // 检查对象
  if (typeof value === 'object') return Object.keys(value).length === 0;

  // 不是null/undefined、字符串、数组、对象，则不认为是空
  return false;
};

/**
 * 将 queryString 转换成 key-value 形式
 * @param {String} url url地址
 * @returns object
 */
export const parseQueryString = url => {
  return [...new URL(url).searchParams].reduce(
    (cur, [key, value]) => ((cur[key] = value), cur),
    {}
  );
};

export const BgImgsSize = ['100% 100%', 'cover', 'contain'];
export const ImgsSize = ['fill', 'cover', 'scale-down'];

export function overHidden(value = '', len = 0) {
  if (value === null || value === undefined) return '';
  if (value.length > len) {
    return value.substring(0, len) + '...';
  }
  return value;
}

/**
 * 分率计算转换,保留小数1位
 */
export function roundRate(number) {
  if (number === '' || number === null || isNaN(number)) return '';
  number = parseFloat(number);
  if (number < 0) return '';
  number = Math.round(number * 10) / 10;
  return `${number}`;
}

/**
 * 过滤字符串中的 " ' & < > 防止xss
 * @param  {string} string The string to escape for inserting into HTML
 * @return {string}
 */

export function escapeHtml(string) {
  const matchHtmlRegExp = /["'&<>]/;
  let str = '' + string;
  let match = matchHtmlRegExp.exec(str);

  if (!match) {
    return str;
  }

  let escape;
  let html = '';
  let index = 0;
  let lastIndex = 0;

  for (index = match.index; index < str.length; index++) {
    switch (str.charCodeAt(index)) {
      case 34: // "
        escape = '&quot;';
        break;
      case 38: // &
        escape = '&amp;';
        break;
      case 39: // '
        escape = '&#39;';
        break;
      case 60: // <
        escape = '&lt;';
        break;
      case 62: // >
        escape = '&gt;';
        break;
      default:
        continue;
    }

    if (lastIndex !== index) {
      html += str.substring(lastIndex, index);
    }

    lastIndex = index + 1;
    html += escape;
  }

  return lastIndex !== index ? html + str.substring(lastIndex, index) : html;
}

/**
 * 通过图片裁切的数据构建服务端特定的数据
 * @param {any} processData
 * @returns {any}
 */
export function buildOssUploadProcessData(processData) {
  const { cropperData, blurryDegree, lightDegree } = processData;
  const blurryDegreeData = '';
  const lightDegreeData = '';
  if (blurryDegree > 0) {
    blurryDegreeData = `,/blur,r_10,s_${blurryDegree * 2}`;
  }
  if (lightDegree) {
    lightDegreeData = `,/bright,${(lightDegree - 10) * 5}`;
  }
  return `image/crop,x_${cropperData.x.toFixed()},y_${cropperData.y.toFixed()},w_${cropperData.width.toFixed()},h_${cropperData.height.toFixed()}`;
}

/**
 * 返回图片地址
 *
 * @param   {[type]}  url   [url 服务端图片地址]
 * @param   {[type]}  mode  [mode 适配的图片显示模式]
 *
 * @return  {[type]}        [return url]
 */
export function buildUrlByMode(url, mode) {
  if (url.indexOf('have-wap-image') != -1) {
    if (url.indexOf('mode') != -1) {
      return url;
    } else {
      return `${url}&mode=${mode}`;
    }
  } else {
    if (url.indexOf('mode') != -1) {
      return url;
    } else {
      return `${url}?mode=${mode}`;
    }
  }
}

export function escapeHTML(title) {
  title = '' + title;
  return title.replace(/<script\b[^>]*>(.*?)<\/script>/gi, '$1') || ' ';
}

export function timeStringToSeconds(time) {
  var parts = time.split(':');
  return parseInt(parts[0], 10) * 3600 + parseInt(parts[1], 10) * 60 + parseInt(parts[2], 10);
}

export function compareTimes(startTime, endTime) {
  var startSeconds = timeStringToSeconds(startTime);
  var endSeconds = timeStringToSeconds(endTime);
  return startSeconds > endSeconds;
}
