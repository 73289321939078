<template>
  <div class="pageBox" v-if="visiblePage">
    <vh-pagination
      v-bind="$props"
      @size-change="sizeChangeHandler"
      @current-change="currentChangeHandler"
      @prev-click="prevClickHandler"
      @next-click="nextClickHandler"
      :page-sizes="pageSizes"
      :pager-count="5"
      :layout="layout"
      align="center"
    ></vh-pagination>
  </div>
</template>

<script>
  import { Pagination } from 'vhall-ui';
  export default {
    props: {
      ...Object.assign(Pagination.props, {
        background: {
          type: Boolean,
          default: true
        },
        layout: {
          type: String,
          default: 'total,sizes, prev, pager, next, jumper'
        },
        pageSizes: {
          type: Array,
          default: () => {
            return [10, 20, 50, 100];
          }
        }
      })
    },
    computed: {
      visiblePage() {
        return this.total >= 10;
      }
    },
    methods: {
      sizeChangeHandler(pageSize) {
        this.$emit('size-change', pageSize);
      },
      currentChangeHandler(current) {
        this.$emit('current-change', current);
      },
      prevClickHandler(current) {
        this.$emit('prev-click', current);
      },
      nextClickHandler(current) {
        this.$emit('next-click', current);
      }
    }
  };
</script>

<style lang="less" scoped>
  .pageBox {
    padding: 20px 0;
  }
</style>
