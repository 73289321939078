<template>
  <div>
    <!-- 文档资料库 -->
    <VhallDialog
      title="编辑"
      :before-close="handleClose"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="480px"
      class="edit-doc-file-image-dialog"
      append-to-body
    >
      <vh-form :model="formData" ref="ruleForm" v-loading="loading" label-width="80px">
        <vh-form-item class="margin32" required label="封面图">
          <upload
            class="upload__avatar"
            ref="webinar_uploader"
            :domain_url="domain_url"
            :saveData="saveImageData"
            :widthImg="336"
            :heightImg="128"
            :on-success="handleUploadSuccess"
            :on-change="handleUploadChange"
            @delete="deleteImage"
            :auto-upload="false"
          >
            <div slot="tip">
              <p>建议尺寸：185*260px，小于4M</p>
              <p>支持jpg、gif、png、bmp</p>
            </div>
          </upload>
        </vh-form-item>
        <vh-form-item label="文件简介" prop="file_desc">
          <vh-input
            class="desc"
            style="width: 334px"
            v-model="formData.file_desc"
            type="textarea"
            :maxlength="50"
            autocomplete="off"
            :autosize="{ minRows: 5 }"
            resize="none"
            show-word-limit
          ></vh-input>
        </vh-form-item>
      </vh-form>
      <!-- 底部按钮 -->
      <div slot="footer">
        <vh-button type="info" plain round size="medium" @click="handleClose">取消</vh-button>
        <vh-button type="primary" size="medium" round @click="handleSubmit">确定</vh-button>
      </div>
    </VhallDialog>
    <!-- 裁剪图片弹窗 -->
    <cropper
      ref="webinarCropper"
      :currentMode="currentMode"
      @cropComplete="cropComplete"
      @resetUpload="resetChooseFile"
      :width="185"
      :height="260"
      :ratio="185 / 260"
    ></cropper>
  </div>
</template>

<script>
  import upload from '@/components/Upload/main';
  import { buildOssUploadProcessData, buildUrlByMode } from '@/utils/utils';
  import cropper from '@/components/Cropper/index';
  export default {
    name: 'editDocFileImage',
    components: {
      upload,
      cropper
    },
    data() {
      return {
        dialogVisible: false,
        loading: false,
        formData: {
          img_url: '',
          // blurryDegree: 0,
          // lightDegree: 10,
          // backgroundSize: {
          //   x: 0,
          //   y: 0,
          //   width: 0,
          //   height: 0
          // },
          file_desc: ''
        },
        file_default: '//cnstatic01.e.vhall.com/common-static/images/file_default.png',
        upload_process_data: '', //上传组件的附加数据
        currentMode: 1 //当前的正在使用的裁切模式
      };
    },
    computed: {
      domain_url() {
        if (!this.formData.img_url) return '';
        return buildUrlByMode(this.formData.img_url, this.currentMode);
      },
      pathUrl: function () {
        return `interacts/screen-imgs/${dayjs().format('YYYYMM')}`;
      },
      // 保存的上传图片的附加数据
      saveImageData() {
        let imageData = {
          path: this.pathUrl,
          type: 'image'
        };
        if (this.upload_process_data) {
          imageData.process = this.upload_process_data;
        }
        return imageData;
      }
    },
    methods: {
      setDefault(item) {
        this.formData.file_desc = item.file_desc;
        this.formData.file_id = item.file_id;
        this.handlerImageInfo(item.img_info.img_url);
      },
      handleClose(done) {
        this.dialogVisible = false;
      },
      // 处理图片
      handlerImageInfo(url) {
        this.formData.img_url = url;
        let obj = parseQueryString(url);
        if (obj.mode) {
          this.currentMode = obj.mode;
        }
      },
      /**
       * 图片裁切完成后的回调函数
       * @param {any} cropperData
       * @param {any} url
       * @param {any} mode
       * @returns {any}
       */
      cropComplete(cropperData, url, mode, blurryDegree, lightDegree) {
        this.currentMode = mode;

        if (cropperData) {
          this.upload_process_data = buildOssUploadProcessData({
            cropperData: cropperData
            // blurryDegree: blurryDegree,
            // lightDegree: lightDegree
          });
        } else {
          this.upload_process_data = '';
        }

        this.$nextTick(() => {
          // 这段代码将在下一个DOM更新循环后执行
          this.$refs.webinar_uploader.upload();
        });
      },
      resetChooseFile() {
        this.$refs.webinar_uploader.resetChooseFile();
      },
      /**
       * 文件上传成功，保存信息
       *
       * @param   {[type]}  res   [服务端结果响应]
       * @param   {[type]}  file  [上传成功的文件对象]
       *
       * @return  {[type]}        [return void]
       */
      handleUploadSuccess(res, file) {
        console.log('handleUploadSuccess------>', res, file);
        if (res.data) {
          this.formData.img_url = res.data.domain_url;
        }
      },
      /**
       * 图片本地裁剪预览
       * @param {*} file
       * @param {*} fileList
       */
      handleUploadChange(file, fileList) {
        console.log('handleUploadChange----->', file, fileList);
        if (file.status == 'ready') {
          this.$checkUploadType(file.raw, this, 1, 4, () => {
            let reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = e => {
              this.$refs.webinarCropper.showModel(e.target.result);
            };
          });
        }
      },
      
      // 删除图片
      deleteImage() {
        this.formData.img_url = '';
        this.formData.blurryDegree = 0;
        this.formData.lightDegree = 10;
      },
      // 确定
      handleSubmit() {
        if (!this.formData.img_url) {
          this.$vhMessage.error('当前为默认封面，请先上传封面图');
          return;
        }
        this.dialogVisible = false;
        console.log(this.domain_url);
        let saveForm = {
          file_desc: this.formData.file_desc,
          img_url: this.domain_url
        };
        console.log('当前保存form数据', saveForm);
        this.$emit('save', saveForm);
      }
    },
    mounted() {}
  };
</script>

<style lang="less" scoped>
  .edit-doc-file-image-dialog {
    .form-slider {
      position: relative;
    }
  }
</style>
