/**
 * 数据和组件的映射关系转换
 * https://test01-saas-api.vhall.com/v3/interacts/menu/components-list
 */

export const componentMap = {
  // 图文
  1: 'img-txt',
  // 二维码
  2: 'qr-code',
  // 直播
  3: 'video',
  // 专题
  4: 'special',
  // 文字链接
  5: 'text-link',
  // 图片链接
  6: 'img-link',
  // 标题
  7: 'title',
  // 分割线
  8: 'hr',
  // 邀请榜
  9: 'rank',
  // 外链嵌入
  10: 'third-link'
};

// 组件的类型映射表
export const TypeMaps = {
  1: 'component-customer',
  2: 'component-doc',
  3: 'component-chat',
  4: 'component-des',
  5: 'component-goods',
  6: 'component-recommend',
  9: 'component-download',
  10: 'component-hixianchang',
  11: 'component-moments',
  12: 'component-summary',
  14: 'component-playback'
};
