<template>
  <div class="embed-layout">
    <section class="embed-layout-main">
      <router-view />
    </section>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    }
  };
</script>

<style lang="less" scoped>
  .embed-layout {
    &-main {
      margin: 0 auto;
      width: 1020px;
      min-width: 1024px;
    }
  }
  @media (min-width: 1374px) {
    .embed-layout-main {
      width: 1374px;
      min-width: 1374px;
    }
  }
</style>
