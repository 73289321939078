import Layout from '@/layout/embedLayout';

const router = [
  {
    path: '/embed/material',
    component: Layout,
    meta: { auth: true, title: '媒资管理', name: 'Material' },
    hide: true,
    children: [
      {
        path: 'video',
        component: () => import('@/views/MaterialModule/video'),
        meta: { auth: true, title: '音视频', name: 'videoMgr' }
      }
    ]
  }
];
export default router;
