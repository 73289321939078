import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css';
import { checkAuth } from '@/utils/utils'; // progress bar style
import ssoAutoLogin from '@/utils/ssoAutoLogin';
import webcasting from './modules/webcasting';
import datahub from './modules/data';
import eventhub from './modules/event';
import common from './modules/common';
import embed from './modules/embed/webcasting';

const isDev = process.env.NODE_ENV == 'development'; // 当前是否为开发环境

// Vue-router 报NavigationDuplicated的可能解决方案
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function push(location) {
  return originalReplace.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

function setRouteProject(routes, project) {
  for (let route of routes) {
    if (route.meta) {
      route.meta.project = project;
    }
    if (route.children) {
      setRouteProject(route.children, project);
    }
  }
  return routes;
}

const envList = [
  'production',
  'pre',
  'test',
  'new_test',
  'test_zt',
  'test4',
  'test5',
  'test6',
  'test01',
  'test02',
  'test03',
  'test04'
];
const base = envList.includes(process.env.VUE_APP_NODE_ENV) ? '/v3/' : '/';
const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base,
    routes: [
      ...setRouteProject(webcasting, 'webcasting'),
      ...setRouteProject(eventhub, 'eventhub'),
      ...setRouteProject(datahub, 'datahub'),
      ...setRouteProject(embed, 'embed'),
      ...common
    ]
  });
const router = createRouter();
NProgress.configure({ showSpinner: false }); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  // 控制单没有gray-init 先执行自动登录校验
  if (!isDev) {
    // await ssoAutoLogin();
  }

  checkAuth(to, from, next, window.vm);
});
router.afterEach(() => {
  window.scrollTo(0, 0);
  NProgress.done();
});
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
const routes = { webcasting, eventhub, datahub };
export { routes };
export default router;
