<template>
  <div class="detailBox" v-loading="loading" element-loading-text="数据获取中" v-if="!loading">
    <pageTitle pageTitle="直播详情"></pageTitle>
    <!--  <pageTitle :title='titleText(liveDetailInfo.webinar_state) + "详情"'></pageTitle> -->
    <vh-row :gutter="16" class="basicInfo">
      <vh-col
        :span="18"
        :lg="18"
        :md="24"
        :sm="24"
        :xs="24"
        :class="liveDetailInfo.webinar_state === 4 ? 'active' : ''"
      >
        <div class="inner">
          <div class="thumb">
            <img
              :class="`webinar_cover webinar_cover_${imageMode}`"
              :src="liveDetailInfo.img_url"
              alt=""
            />
            <span class="liveTag">
              <label class="live-status" v-if="liveDetailInfo.webinar_state == 1">
                <img src="../../common/images/live.gif" alt="" />
              </label>
              {{ liveDetailInfo | liveTag }}
              <span
                v-if="
                  liveDetailInfo.is_new_version == 3 &&
                  (liveDetailInfo.webinar_type == 3 || liveDetailInfo.webinar_type == 6) &&
                  liveDetailInfo.inav_num > 1
                "
              >
                | 1v{{ Number(liveDetailInfo.inav_num) - 1 }}
              </span>
              <span v-if="liveDetailInfo.webinar_type != 6 && hasDelayPermission && isDelay">
                | 无延迟
              </span>
              <span v-if="webinarDirector && isDirector">| 云导播</span>
              <span v-if="portraitScreen">| 竖屏</span>
            </span>
            <span class="hot">
              <i class="iconfont-v3 saasicon_redu">
                {{ liveDetailInfo.pv | unitCovert }}
              </i>
            </span>
          </div>

          <div class="info">
            <div class="hidden_hover">
              <p class="mainColor font-20">
                {{ liveDetailInfo.subject }}
              </p>
              <p class="title_hover">{{ liveDetailInfo.subject }}</p>
            </div>
            <p class="subColor" v-if="liveDetailInfo.webinar_state != 4">
              直播时间：{{ liveDetailInfo.start_time }}
            </p>
            <p class="subDuration" v-else>点播时长：{{ liveDetailInfo.duration }}</p>
            <p class="subColor">
              观看限制：
              <span class="tag">{{ liveDetailInfo.verify | limitTag }}</span>
              <span class="tag" v-if="isForm">报名表单</span>
            </p>
            <!-- * webinar_state  1直播 2预约 3结束 4点播 5回放
                  * webinar_type  1音频直播 2视频直播 3互动直播 5定时直播 6分组直播 -->
            <div class="action-look">
              <vh-button
                round
                plain
                size="small"
                v-if="[3, 5].includes(liveDetailInfo.webinar_state)"
                style="margin-right: 8px"
                @click="resetResume(liveDetailInfo.webinar_state)"
              >
                恢复预告
              </vh-button>
              <vh-popover placement="bottom" trigger="hover" style="margin-right: 8px">
                <div class="invitation-code">
                  <p>活动观看页</p>
                  <img :src="h5WapLink" alt="" v-if="h5WapLink" />
                  <p>
                    <vh-button round type="primary" size="medium" @click="downErCode">
                      下载二维码
                    </vh-button>
                  </p>
                </div>
                <vh-button round plain size="small" slot="reference">扫码</vh-button>
              </vh-popover>
              <div
                class="check-url"
                @mouseout="handlerMouseOut('showFloat')"
                @mouseover="handleMouseIn('showFloat')"
                style="margin-right: 8px"
              >
                <vh-button round plain size="small" class="check-btn">观看链接</vh-button>
                <div
                  class="invitation-code urlCopy float-dom"
                  v-if="showFloat"
                  @mouseout="handlerMouseOut('showFloat')"
                  @mouseover="handleChildOver('showFloat')"
                >
                  <p class="watch_link">
                    观看页
                    <vh-input id="copy-val" v-model="link" style="width: 320px"></vh-input>
                    <span class="notice_txt">iframe嵌入方式请在「嵌入方式」中获取链接</span>
                  </p>
                  <div class="copy-item">
                    <vh-button
                      round
                      size="small"
                      type="primary"
                      data-clipboard-target="#copy-val"
                      @click="doCopy('copy-link')"
                      class="copy-link"
                    >
                      复制
                    </vh-button>
                    <vh-button type="info" plain round size="small" @click="openLink('link')">
                      打开页面
                    </vh-button>
                  </div>
                </div>
              </div>
              <div
                class="check-url"
                @mouseout="handlerMouseOut('embedLinkModal')"
                @mouseover="handleMouseIn('embedLinkModal')"
                v-if="userPermission['cms.show_global_embed_link']"
              >
                <vh-button round plain size="small" class="check-btn">全局嵌入链接</vh-button>
                <div
                  v-if="embedLinkModal && !this.businessSiteLink"
                  class="invitation-code embed-nodata float-dom"
                >
                  <noData
                    null-type="setting"
                    text="未设置‘全局嵌入链接’，设置后可使用嵌入链接预览、分发活动"
                    :height="20"
                  >
                    <router-link target="_blank" :to="{ path: '/cms/embed-setting/link' }">
                      <vh-button type="primary" round>去设置</vh-button>
                    </router-link>
                  </noData>
                </div>

                <div
                  class="invitation-code urlCopy float-dom"
                  v-if="embedLinkModal && this.businessSiteLink"
                  @mouseout="handlerMouseOut('embedLinkModal')"
                  @mouseover="handleChildOver('embedLinkModal')"
                >
                  <p>
                    观看页
                    <vh-input
                      id="copy-embed-val"
                      v-model="embedLink"
                      style="width: 320px"
                    ></vh-input>
                  </p>
                  <div class="copy-item">
                    <vh-button plain type="text" class="set-footer" size="mini" @click="setFooter">
                      设置页脚
                    </vh-button>
                    <vh-button
                      round
                      size="small"
                      type="primary"
                      data-clipboard-target="#copy-embed-val"
                      @click="doCopy('copy-business-link')"
                      class="copy-business-link"
                    >
                      复制
                    </vh-button>
                    <vh-button type="info" plain round size="small" @click="openLink('embedLink')">
                      打开页面
                    </vh-button>
                  </div>
                </div>
              </div>
              <div
                class="check-url director"
                v-if="webinarDirector && isDirector"
                @mouseout="handlerMouseOutDirector"
                @mouseover="handleMouseInDirector"
              >
                <vh-button round size="small" class="check-btn" @click="toDirector(false)">
                  云导播
                </vh-button>
                <div
                  class="float-dom-director"
                  v-if="showFloatDirector && !hasUpdate"
                  @mouseout="handlerMouseOutDirector"
                  @mouseover="handleChildOverDirector"
                >
                  <div class="desc">多路机位+异地推流，画中画分屏直播</div>
                  <div class="flex-box">
                    <span>分辨率</span>
                    <vh-select v-model="dpi" placeholder="请选择" :popper-append-to-body="false">
                      <vh-option label="1280*720" value="1280*720"></vh-option>
                      <vh-option label="1920*1080" value="1920*1080"></vh-option>
                    </vh-select>
                  </div>
                  <div class="indent">设置后，分辨率不支持重复修改</div>
                  <div class="btns">
                    <vh-button type="info" plain round size="small" @click="cancelSetDirector">
                      取消
                    </vh-button>
                    <vh-button round size="small" type="primary" @click="setDpi">确定</vh-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vh-col>
      <vh-col
        :span="6"
        :lg="6"
        :md="24"
        :sm="24"
        :xs="24"
        v-if="liveDetailInfo.webinar_state !== 4"
        class="rightbox"
        style="padding-right: 4px"
      >
        <div class="inner liveTime" v-if="!outLiveTime">
          <p class="subColor">{{ liveDetailInfo.webinar_state | limitText }}</p>
          <p class="mainColor timerBox" v-if="liveDetailInfo.webinar_state === 2">
            <span class="custom-font-barlow first-downTime">{{ time.day }}</span>
            <i>天</i>
            <span class="custom-font-barlow">{{ time.hours }}</span>
            <i>时</i>
            <span class="custom-font-barlow">{{ time.minute }}</span>
            <i>分</i>
            <span class="custom-font-barlow">{{ time.second }}</span>
            <i>秒</i>
          </p>
          <p v-else>
            <span>{{ liveDetailInfo.webinar_state | liveText }}</span>
          </p>
          <vh-button
            round
            type="primary"
            @click="toEndLive"
            v-if="liveDetailInfo.webinar_type == 5 && liveDetailInfo.webinar_state == 1"
          >
            结束直播
          </vh-button>
          <vh-button
            round
            type="primary"
            @click="toRoom"
            :disabled="isAnginOpen"
            v-if="liveDetailInfo.webinar_type != 5"
          >
            发起直播
          </vh-button>
          <div class="alert" v-if="portraitScreen && liveDetailInfo.webinar_type != 5">
            建议使用app或第三方推流发起
            <a class="qr" href="http://e.vhall.com/app" target="_blank">下载</a>
          </div>
        </div>
        <div class="inner liveTime" v-if="outLiveTime && liveDetailInfo.webinar_state == 2">
          <p class="subColor">直播即将开始</p>
          <p><span>观众等待中</span></p>
          <vh-button v-if="liveDetailInfo.webinar_type != 5" round type="primary" @click="toRoom">
            发起直播
          </vh-button>
          <div class="alert" v-if="portraitScreen">
            建议使用app或第三方推流发起
            <a class="qr" href="http://e.vhall.com/app" target="_blank">下载</a>
          </div>
        </div>
      </vh-col>
    </vh-row>
    <item-card
      :type="liveDetailInfo.webinar_state"
      :webinarType="liveDetailInfo.webinar_type"
      :perssionInfo="perssionInfo"
      :childPremission="childPremission"
      :videoType="videoType"
      @blockHandler="blockHandler"
      v-if="isShow"
    ></item-card>
    <begin-play
      :webinarType="liveDetailInfo.webinar_type"
      :webinarId="$route.params.str"
      v-if="liveDetailInfo.webinar_state != 4 && liveDetailInfo.webinar_type != 5"
    ></begin-play>

    <VhallDialog
      title="设置页脚"
      v-if="setFooterDialog"
      :visible.sync="setFooterDialog"
      :close-on-click-modal="false"
      custom-class="set-footer-dialog"
      @close="setFooterDialogClose"
      width="468px"
    >
      <div class="all_op" v-if="isMulLanguageWebinar">
        <span :class="[currentLanguage === '1' ? 'active' : '']" @click="changeLanguage('1')">
          中
        </span>
        <span :class="[currentLanguage === '2' ? 'active' : '']" @click="changeLanguage('2')">
          EN
        </span>
      </div>
      <vh-form label-width="90px" ref="setFooterForm">
        <vh-form-item label="iframe链接" required>
          <vh-input
            v-model="thirdFooter[currentLanguage].iframe_url"
            placeholder="请输入https开头的链接"
            autocomplete="off"
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="样式链接" required>
          <vh-input
            v-model="thirdFooter[currentLanguage].style_url"
            placeholder="请输入https开头的链接"
            autocomplete="off"
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="class名称">
          <vh-input
            v-model="thirdFooter[currentLanguage].class_name"
            placeholder="请输入iframe容器class名称"
            autocomplete="off"
          ></vh-input>
        </vh-form-item>
      </vh-form>
      <div class="footer-tip-box">
        <div class="footer-tip">提示：</div>
        <div class="footer-tip">
          1.使用「自定义样式页脚」功能，请提前和您的研发团队沟通，由其按照要求提供对应内容即可
        </div>
        <div class="footer-tip">2.设置后，仅在PC端展示，活动状态为「预告」状态展示</div>
        <div class="footer-doc">
          <a
            href="https://saas-doc.vhall.com/opendocs/show/1480"
            target="_blank"
            style="color: #0f40f5"
          >
            查看技术文档
          </a>
        </div>
      </div>
      <span slot="footer" class="dialog-footer create-footer">
        <vh-button type="info" size="medium" @click="setFooterDialog = false" plain round>
          取消
        </vh-button>
        <vh-button type="primary" size="medium" v-preventReClick @click="saveFooter" round>
          确定
        </vh-button>
      </span>
    </VhallDialog>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import beginPlay from '@/components/beginBtn';
  import ItemCard from '@/components/ItemCard/index.vue';
  import Env from '@/api/env';
  import { formateDates } from '@/utils/general.js';
  import { sessionOrLocal, parseQueryString, isEmbed } from '@/utils/utils';
  import noData from '@/components/NullPage';
  import EmbedLinkMixin from '../Cms/embedLinkMixin';
  import { DEP_TYPE_NUM } from '@/common/js/constant';
  import regRule from '@/utils/reg-rule';

  export default {
    components: {
      PageTitle,
      ItemCard,
      beginPlay,
      noData
    },
    mixins: [EmbedLinkMixin],
    data() {
      const embedLinkValidate = (rule, value, callback) => {
        if (value) {
          if (!regRule.customerLink.test(value)) {
            callback && callback('请输入正确的链接地址(以https://开头)');
          } else {
            callback();
          }
        } else {
          callback();
        }
      };
      return {
        isEmbed: isEmbed(),
        lowerGradeInterval: null,
        isDelay: false,
        isDirector: false,
        portraitScreen: false,
        hasDelayPermission: false,
        msg: '',
        userId: '',
        imageMode: 3,
        perssionInfo: {},
        isShow: false,
        loading: true,
        isForm: false,
        isExport: false,
        isAnginOpen: false,
        outLiveTime: false,
        liveDetailInfo: {
          webinar_state: 0,
          webinar_type: 0
        },
        showFloat: false,
        showFloatDirector: false,
        timer: null,
        link: `${process.env.VUE_APP_WAP_WATCH}/lives/watch/${this.$route.params.str}`,
        h5WapLink: `${Env.staticLinkVo.aliQr}${process.env.VUE_APP_WAP_WATCH}/lives/watch/${this.$route.params.str}`,
        time: {
          day: 0,
          hours: 0,
          minute: 0,
          second: 0
        },
        dpi: '1280*720',
        hasUpdate: true, //false: 没有修改过
        director_web_url: '', //云导播地址
        embedLinkModal: false, // 全局嵌入链接
        showFloatTimer: null,
        embedLinkModalTimer: null,
        userPermission: {},
        setFooterDialog: false,
        thirdFooter: {
          1: {},
          2: {}
        },
        thirdFooterRules: {
          iframe_url: [
            {
              required: false,
              validator: embedLinkValidate,
              message: '请输入https开头的链接',
              trigger: 'blur'
            }
          ],
          style_url: [
            {
              required: false,
              validator: embedLinkValidate,
              message: '请输入https开头的链接',
              trigger: 'blur'
            }
          ]
        },
        // footerInfo:[],
        webinarLanguageTypes: '', // 1:中文简体2：英文3：西班牙语
        currentLanguage: '1', // 1:中文简体2：英文3：西班牙语
        webinarId: this.$route.params.str
      };
    },
    computed: {
      // titleText() {
      //   return function (val) {
      //     let _text = '直播';
      //     val == 5 ? (_text = '回放') : val == 4 ? (_text = '点播') : (_text = '直播');
      //     return _text;
      //   };
      // },
      childPremission: function () {
        return sessionOrLocal.get('SAAS_V3_SON_PS')
          ? JSON.parse(sessionOrLocal.get('SAAS_V3_SON_PS'))
          : {};
      },
      videoType() {
        //定时直播视频格式 用来确定是否有暖场视频
        return (
          this.liveDetailInfo.webinar_type == 5 &&
          (this.liveDetailInfo.msg_url == '.MP3' || this.liveDetailInfo.msg_url == '.MAV')
        );
      },
      // admin无云导播活动权限
      webinarDirector() {
        //  webinar.director 1:有无延迟权限  0:无权限
        if (
          JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'))['webinar.director'] == '1'
        ) {
          return true;
        } else {
          return false;
        }
      },
      embedLink() {
        // dep_id值为活动id,dep_type为1时代表直播类型
        const queryStr = encodeURIComponent(
          `embedMode=1&dep_id=${this.$route.params.str}&dep_type=${DEP_TYPE_NUM['webinar']}`
        );
        return `${this.businessSiteLink}?dep_params=${queryStr}`;
      },
      isMulLanguageWebinar() {
        return this.webinarLanguageTypes.includes(',');
      }
    },
    created() {
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
      this.getLiveDetail(this.webinarId);
      this.getLanguageList(this.webinarId);
      this.getPermission(this.webinarId);
    },
    mounted() {
      // console.log(this.$route.meta.title, '1111111111111111')
      this.userPermission = JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'));
      if (this.userPermission['cms.show_global_embed_link']) {
        this.getBusinessDomainConfigList();
      }
    },
    beforeDestroy() {
      if (this.lowerGradeInterval) clearInterval(this.lowerGradeInterval);
    },
    methods: {
      handleChildOver(modal) {
        if (this[modal]) {
          clearTimeout(this[`${modal}Timer`]);
        }
        this[modal] = true;
      },
      handleMouseIn(modal) {
        if (this[`${modal}Timer`]) clearTimeout(this[`${modal}Timer`]);
        this[modal] = true;
      },
      handlerMouseOut(modal) {
        if (this[`${modal}Timer`]) clearTimeout(this[`${modal}Timer`]);
        this[`${modal}Timer`] = setTimeout(() => {
          this[modal] = false;
        }, 400);
      },
      handleChildOverDirector() {
        if (this.showFloatDirector) {
          clearTimeout(this.handleTimerDirector);
        }
        this.showFloatDirector = true;
      },
      handleMouseInDirector() {
        if (this.handleTimerDirector) clearTimeout(this.handleTimerDirector);
        this.showFloatDirector = true;
      },
      handlerMouseOutDirector() {
        if (this.handleTimerDirector) clearTimeout(this.handleTimerDirector);
        this.handleTimerDirector = setTimeout(() => {
          this.showFloatDirector = false;
          this.dpi = '1280*720';
        }, 400);
      },
      // 字符截取显示...兼容ie，用js
      fontNumber(date) {
        const length = date.length;
        if (length > 35) {
          var str = '';
          str = date.substring(0, 35) + '...';
          return str;
        } else {
          return date;
        }
      },
      getPermission(id) {
        // 活动权限
        this.$fetch('planFunctionGet', {
          webinar_id: id,
          webinar_user_id: this.userId,
          scene_id: 1
        })
          .then(async res => {
            if (res.code == 200) {
              if (res.data.permissions) {
                sessionOrLocal.set('WEBINAR_PES', res.data.permissions, 'localStorage');
                this.perssionInfo = JSON.parse(sessionOrLocal.get('WEBINAR_PES', 'localStorage'));
                console.log(this.perssionInfo, '>>>>>>1231<<<');
                this.isShow = true;
                this.hasDelayPermission =
                  this.perssionInfo['no.delay.webinar'] &&
                  this.perssionInfo['no.delay.webinar'] == 1
                    ? true
                    : false;
              } else {
                sessionOrLocal.removeItem('WEBINAR_PES');
              }
            }
          })
          .catch(e => {
            console.log(e);
            sessionOrLocal.removeItem('SAAS_VS_PES');
          });
      },
      // 获取基本信息
      getLiveDetail(id) {
        this.loading = true;
        // webinar/info调整-正常的信息展示使用 0
        this.$fetch('getWebinarInfo', { webinar_id: id, is_rehearsal: 0 })
          .then(res => {
            this.liveDetailInfo = res.data;
            this.handlerImageInfo(this.liveDetailInfo.img_url);
            sessionOrLocal.set('liveDetailInfo', this.liveDetailInfo);
            sessionOrLocal.set('webinarState', this.liveDetailInfo.webinar_state);
            sessionOrLocal.set('webinarType', this.liveDetailInfo.webinar_type);
            if (res.data.webinar_state == 4) {
              this.$route.meta.title = '点播详情';
            } else {
              if (res.data.webinar_type == 5) {
                this.$route.meta.title = '定时直播详情';
                this.videoType = res.data.msg_url;
              } else {
                this.$route.meta.title = '直播详情';
              }
            }
            this.getFormInfo(id);
            if (res.data.webinar_state == 1) {
              this.getOpenLive();
            }
            if (res.data.webinar_state == 2) {
              // let date = new Date();
              // let nowTime = date.setTime(date.getTime());
              let nowTime = res.data.time * 1000;
              this.downTime(
                formateDates(nowTime).replace(/-/g, '/'),
                res.data.start_time.replace(/-/g, '/')
              );
            }
            this.isDelay = res.data.no_delay_webinar == 1 ? true : false;
            this.isDirector = res.data.is_director == 1 ? true : false;
            this.portraitScreen = res.data.webinar_show_type == 1 ? false : true;
            // 是否活动标记为云导播活动
            sessionOrLocal.set(`webinar_is_director__${id}`, res.data.is_director);
            if (this.isDirector) {
              this.getLiveDirectorResolution();
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || '获取信息失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            console.log(res);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      // 解析图片地址
      handlerImageInfo(url) {
        let obj = parseQueryString(url);
        this.imageMode = Number(obj.mode) || 3;
      },
      // 获取是否有报名表单
      getFormInfo(id) {
        this.$fetch('regFromGet', { webinar_id: id }).then(res => {
          if (res.code == 200 && res.data.enable_status == 1) {
            this.isForm = true;
          } else {
            this.isForm = false;
          }
        });
      },
      // 下载二维码
      downErCode() {
        const activeName = this.liveDetailInfo.subject;
        this.$vhall_paas_port({
          k: 100055,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: this.$route.params.str,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        let image = new Image();
        // 解决跨域 Canvas 污染问题
        image.setAttribute('crossOrigin', 'anonymous');
        image.onload = function () {
          let canvas = document.createElement('canvas');
          // canvas.width = image.width
          // canvas.height = image.height
          canvas.width = 217;
          canvas.height = 217;
          let context = canvas.getContext('2d');
          context.drawImage(image, 0, 0, 217, 217);
          // context.drawImage(image, 0, 0, image.width, image.height)
          let url = canvas.toDataURL('image/png'); //得到图片的base64编码数据
          let a = document.createElement('a'); // 生成一个a元素
          let event = new MouseEvent('click'); // 创建一个单击事件
          a.download = `${activeName || 'code'}观看端二维码.png`; // 设置图片名称
          a.href = url; // 将生成的URL设置为a.href属性
          a.dispatchEvent(event); // 触发a的单击事件
        };
        image.src = this.h5WapLink;
      },
      // 复制
      doCopy(linkClass) {
        let clipboard = new this.$clipboard(`.${linkClass}`);
        clipboard.on('success', e => {
          this.$vhMessage({
            message: `复制成功`,
            showClose: true,
            type: 'success',
            customClass: 'zdy-info-box'
          });
          e.clearSelection();
          clipboard.destroy();
        });
        clipboard.on('error', e => {
          this.$vhMessage({
            message: `复制失败`,
            showClose: true,
            // duration: 0,
            type: 'success',
            customClass: 'zdy-info-box'
          });
          clipboard.destroy();
        });
      },
      // 结束直播
      toEndLive() {
        //强制结束直播
        this.$vhConfirm('正在直播中，确定结束直播？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,

          roundButton: true,
          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            this.timingliveEnd();
          })
          .catch(() => {});
      },
      timingliveEnd() {
        this.$fetch(
          'liveEnd',
          this.$params({
            webinar_id: this.$route.params.str,
            end_type: 1
          })
        )
          .then(res => {
            if (res && res.code === 200) {
              this.getLiveDetail(this.$route.params.str);
            }
          })
          .catch(e => {});
      },
      // 打开页面
      openLink(linkType) {
        this.$vhall_paas_port({
          k: 100057,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: this.$route.params.str,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        window.open(this[linkType], '_blank');
      },
      //恢复预告
      resetResume(status) {
        if (status === 5) {
          this.$vhConfirm('恢复为预告后，回放将不能观看', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'zdy-message-box',
            lockScroll: false,
            roundButton: true,

            cancelButtonClass: 'zdy-confirm-cancel'
          })
            .then(() => {
              this.reSumeNotice();
            })
            .catch(() => {
              this.$vhMessage({
                type: 'info',
                message: '已取消'
              });
            });
        } else {
          this.reSumeNotice();
        }
      },
      reSumeNotice() {
        this.$fetch('liveEdit', { webinar_id: this.$route.params.str, type: 2 })
          .then(res => {
            this.$vhall_paas_port({
              k: 100054,
              data: {
                business_uid: this.userId,
                user_id: '',
                webinar_id: this.$route.params.str,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            this.$vhMessage({
              message: `恢复预告成功`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
            this.getLiveDetail(this.$route.params.str);
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || '恢复预告失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            console.log(res);
          });
      },
      // 判断是否有起直播的权限
      getOpenLive() {
        this.$fetch(
          'checkLive',
          this.$params({
            webinar_id: this.$route.params.str
          })
        )
          .then(res => {
            if (res && res.code === 200) {
              this.isAnginOpen = false;
            } else {
              this.isAnginOpen = true;
            }
          })
          .catch(e => {
            this.isAnginOpen = true;
          });
      },
      isLiveOrEmbed(originPath, targetPath) {
        return originPath === targetPath || originPath === `/embed${targetPath}`;
      },
      blockHandler(item) {
        if (item.path) {
          const embedPath = this.isEmbed ? '/embed' : '';
          if (this.isLiveOrEmbed(item.path, '/live/edit')) {
            if (this.liveDetailInfo.webinar_state == 4) {
              this.$router.push({
                path: `${embedPath}/live/vodEdit/${this.$route.params.str}`
              });
            } else {
              if (this.liveDetailInfo.webinar_type == 5) {
                this.$router.push({
                  path: `${embedPath}/live/timeEdit/${this.$route.params.str}`
                });
              } else {
                this.$router.push({
                  path: `${item.path}/${this.$route.params.str}`
                });
              }
            }
          } else if (this.isLiveOrEmbed(item.path, '/live/question')) {
            // 问卷
            this.$router.push({
              path: `${item.path}/${this.$route.params.str}`,
              query: {
                roomId: this.liveDetailInfo.vss_room_id,
                query: { type: this.liveDetailInfo.webinar_type }
              }
            });
          } else if (this.isLiveOrEmbed(item.path, '/live/exam')) {
            // 快问快答
            this.$router.push({
              path: `${item.path}/${this.$route.params.str}`,
              query: {
                roomId: this.liveDetailInfo.vss_room_id,
                tab: 1,
                query: { type: this.liveDetailInfo.webinar_type }
              }
            });
          } else if (this.isLiveOrEmbed(item.path, '/live/pushCard')) {
            // 快问快答
            this.$router.push({
              path: `${item.path}/${this.$route.params.str}`,
              query: {
                roomId: this.liveDetailInfo.vss_room_id,
                tab: 1,
                query: { type: this.liveDetailInfo.webinar_type }
              }
            });
          } else if (
            this.isLiveOrEmbed(item.path, `/live/prizeList/${this.$route.params.str}`) ||
            this.isLiveOrEmbed(item.path, `/live/gift/${this.$route.params.str}`)
          ) {
            // 奖品
            this.$router.push({
              path: item.path,
              query: {
                roomId: this.liveDetailInfo.vss_room_id,
                type: this.liveDetailInfo.webinar_type
              }
            });
          } else if (
            this.isLiveOrEmbed(item.path, `/live/interactionData/${this.$route.params.str}`)
          ) {
            // 互动统计
            this.$router.push({
              path: item.path,
              query: { roomId: this.liveDetailInfo.vss_room_id }
            });
          } else if (this.isLiveOrEmbed(item.path, `/live/livingSet/${this.$route.params.str}`)) {
            // 直播间设置
            let isDelay = this.hasDelayPermission && this.isDelay ? 1 : 0;
            this.$router.push({
              path: item.path,
              query: {
                type: this.liveDetailInfo.webinar_type,
                state: this.liveDetailInfo.webinar_state,
                isDelay: isDelay,
                roomId: this.liveDetailInfo.vss_room_id
              }
            });
          } else if (
            this.isLiveOrEmbed(item.path, `/live/safeScreenSet/${this.$route.params.str}`)
          ) {
            // 防录屏
            let isDelay = this.hasDelayPermission && this.isDelay ? 1 : 0;
            this.$router.push({
              path: item.path,
              query: { type: this.liveDetailInfo.webinar_type, isDelay: isDelay }
            });
          } else if (
            this.isLiveOrEmbed(item.path, `/live/InteractReward/${this.$route.params.str}`)
          ) {
            // 互动有礼
            this.$router.push({
              path: item.path,
              query: {
                roomId: this.liveDetailInfo.vss_room_id
              }
            });
          } else if (this.isLiveOrEmbed(item.path, `/live/WatchReward/${this.$route.params.str}`)) {
            this.$router.push({
              path: item.path,
              query: {
                roomId: this.liveDetailInfo.vss_room_id
              }
            });
          } else if (
            this.isLiveOrEmbed(
              item.path,
              `/live/InteractiveRewardSetting/${this.$route.params.str}`
            )
          ) {
            this.$router.push({
              path: item.path,
              query: Object.assign({}, this.$route.query, {
                roomId: this.liveDetailInfo.vss_room_id,
                type: this.liveDetailInfo.webinar_type
              })
            });
          } else {
            this.$router.push({
              path: item.path,
              query: this.isLiveOrEmbed(item.path, `/live/signup/${this.$route.params.str}`)
                ? { type: this.liveDetailInfo.webinar_type, tab: 1 }
                : {
                    type: this.liveDetailInfo.webinar_type,
                    roomId: this.liveDetailInfo.vss_room_id
                  }
            });
          }
        } else {
          console.log(item);
        }
      },
      getAppersInfo() {
        this.$fetch('getVersionInfo', { user_id: this.userId })
          .then(res => {
            if (res.data.arrears.total_fee > 0) {
              this.$vhConfirm(
                `尊敬的微吼会员，您的${
                  res.data.type == 1 ? '流量' : res.data.type == 2 ? '时长资源' : '并发套餐'
                }已用尽，${res.data.type == 2 ? '请联系客服充值' : '请充值'}`,
                '提示',
                {
                  confirmButtonText: res.data.type == 2 ? '知道了' : '去充值',
                  cancelButtonText: '知道了',
                  customClass: 'zdy-message-box',
                  lockScroll: false,
                  roundButton: true,

                  showCancelButton: res.data.type == 2 ? false : true,
                  cancelButtonClass: 'zdy-confirm-cancel'
                }
              )
                .then(() => {
                  if (res.data.type == 2) return;
                  this.$router.push({ path: '/finance/info' });
                })
                .catch(() => {});
            } else {
              this.toLive();
            }
          })
          .catch(e => {
            console.log(e);
          });
      },
      toLive() {
        if (this.isEmbed && window.top != window.self) {
          window.parent.postMessage(
            {
              type: 'vhOpenHref',
              url: `${window.location.protocol}${process.env.VUE_APP_ROOM_WATCH}/lives/room/${this.$route.params.str}`
            },
            '*'
          );
          return;
        }
        if (this.liveDetailInfo.webinar_type == 1) {
          // 跳转发起端地址 协议 +  域名
          let href = `${window.location.protocol}${process.env.VUE_APP_ROOM_WATCH}/lives/room/${this.$route.params.str}`;
          window.open(href, '_blank');
        } else {
          const { href } = this.$router.resolve({
            path: `/live/chooseWay/${this.$route.params.str}/1?type=ctrl`
          });
          window.open(href, '_blank');
        }
      },
      toRoom() {
        // 跳转至发起页面
        this.getAppersInfo(this.userId);
      },
      downTime(targetStartDate, targetEndDate) {
        let targetStart = new Date(targetStartDate);
        let targetEnd = new Date(targetEndDate);
        if (targetEnd.getTime() - targetStart.getTime() < 0) {
          this.outLiveTime = true;
          return false;
        } else {
          let diff = targetEnd.getTime() - targetStart.getTime();
          targetStart.setTime(targetStart.getTime() + 1000);
          let day = Math.floor(diff / (24 * 3600 * 1000));
          this.time.day = day > 9 ? day : `0${day}`;
          let limit1 = diff % (24 * 3600 * 1000);
          let hours = Math.floor(limit1 / (3600 * 1000));
          this.time.hours = hours > 9 ? hours : `0${hours}`;
          let limit2 = limit1 % (3600 * 1000);
          let minute = Math.floor(limit2 / (60 * 1000));
          this.time.minute = minute > 9 ? minute : `0${minute}`;

          let limit3 = limit2 % (60 * 1000);
          let second = Math.floor(limit3 / 1000);
          this.time.second = second > 9 ? second : `0${second}`;
          // console.log(diff, '????????????????????')
          if (diff) {
            let diffSetTime = window.setTimeout(() => {
              this.downTime(targetStart, targetEnd);
              window.clearTimeout(diffSetTime);
            }, 1000);
          } else {
            return `0天0时0分0秒`;
          }
        }
      },
      //设置导播分辨率
      setDpi() {
        this.$fetch(
          'setLiveDirectorResolution',
          this.$params({
            webinar_id: this.$route.params.str,
            resolution_ratio: this.dpi
          })
        )
          .then(res => {
            this.hasUpdate = true;
            this.director_web_url = res.data.director_web_url;
            this.toDirector(true);
          })
          .catch(res => {});
      },
      // 控制台-获取活动分辨率信息
      getLiveDirectorResolution(open = false) {
        this.$fetch(
          'getLiveDirectorResolution',
          this.$params({
            webinar_id: this.$route.params.str
          })
        )
          .then(res => {
            if (res && res.code === 200) {
              //1:未修改 2:已修改不可修改
              this.hasUpdate = res.data.is_update === 2;
              this.director_web_url = res.data.director_web_url;
              if (open) {
                this.openDirector();
              }
            }
          })
          .catch(e => {});
      },
      //打开云导播台
      toDirector(open = false) {
        if (this.hasUpdate) {
          if (this.director_web_url && open) {
            this.openDirector();
          } else {
            this.getLiveDirectorResolution(true);
          }
        }
      },
      openDirector() {
        //打开云导播台
        this.$vhall_paas_port({
          k: 100838,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: this.$route.params.str,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        window.open(this.director_web_url, '_blank');
      },
      cancelSetDirector() {
        this.showFloatDirector = false;
        this.dpi = '1280*720';
      },
      getLanguageList(webinar_id) {
        this.$fetch('languageList', { webinar_id: webinar_id })
          .then(langRes => {
            if (langRes.code == 200) {
              this.webinarLanguageTypes = langRes?.data?.language_types || ''; // 1:中文简体2：英文3：西班牙语
              if (this.isMulLanguageWebinar) {
                this.currentLanguage = '1';
              } else {
                this.currentLanguage = this.webinarLanguageTypes;
              }
            }
          })
          .catch(error => {});
      },
      changeLanguage(type) {
        this.currentLanguage = type;
      },
      setFooter() {
        this.$fetch(
          'getWebinarPageInfo',
          {
            webinar_id: this.webinarId
          }
          // {
          //   'Content-Type': 'application/json'
          // }
        )
          .then(res => {
            if (res && res.code === 200) {
              let footerInfo = res?.data?.list || [];
              let len = footerInfo.length;
              if (len) {
                for (let i = 0; i < len; i++) {
                  let item = footerInfo[i];
                  this.$set(this.thirdFooter, item.lang, item);
                }
              } else {
                if (this.isMulLanguageWebinar) {
                  this.webinarLanguageTypes.split(',').forEach(lan => {
                    this.$set(this.thirdFooter, lan, {
                      iframe_url: '',
                      style_url: '',
                      class_name: 'third-footer-container'
                    });
                  });
                } else {
                  this.$set(this.thirdFooter, this.currentLanguage, {
                    iframe_url: '',
                    style_url: '',
                    class_name: 'third-footer-container'
                  });
                }
              }

              this.setFooterDialog = true;
            } else {
              this.$vhMessage({
                message: res.msg || `查询失败`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `查询失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      setFooterDialogClose() {
        this.setFooterDialog = false;
      },
      saveFooter() {
        // this.$refs.setFooterForm.validate(valid => {
        //   if (valid) {
        let data = [];
        Object.keys(this.thirdFooter).forEach(key => {
          let item = {
            lang: key,
            ...this.thirdFooter[key]
          };
          data.push(item);
        });

        this.$fetch(
          'editWebinarPage',
          {
            webinar_id: this.webinarId,
            data: JSON.stringify(data)
          }
          // {
          //   'Content-Type': 'application/json'
          // }
        )
          .then(res => {
            if (res && res.code === 200) {
              this.setFooterDialog = false;
            } else {
              this.$vhMessage({
                message: res.msg || `保存失败`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `保存失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
        // }
        // });
      },
      
    }
  };
</script>

<style lang="less" scoped>
  .basicInfo {
    // display: flex;
    // min-width: 756px;
    // flex-wrap: wrap;
    // justify-content: space-between;
    .rightbox {
      height: 223px;
    }
    .active {
      width: 100%;
    }
    .inner {
      background: #fff;
      width: 100%;
      height: 100%;
      padding: 24px 22px;
      display: flex;
      border-radius: 4px;
      .info {
        flex: 1;
        // overflow: auto;
        // height: 175px;
        p {
          font-size: 14px;
          line-height: 28px;
          &:nth-child(1) {
            margin-bottom: 16px;
            // height: 56px;
            font-size: 20px;
            // display: table-cell;
            vertical-align: middle;
            cursor: pointer;
          }
          &:last-child {
            margin-bottom: 20px;
          }
          // &:nth-child(2){
          //   margin-bottom: 10px;
          //   line-height: 20px;
          // }
          // &:nth-child(3){
          //   // margin-bottom: 20px;
          //   line-height: 20px;
          // }
        }

        .hidden_hover:hover .title_hover {
          display: block;
        }
        .title_hover {
          position: absolute;
          left: 370px;
          top: 55px;
          border-radius: 4px;
          max-width: 368px;
          word-break: break-all;
          line-height: 17px;
          background: rgba(#1a1a1a, 0.85);
          font-size: 12px;
          color: #fff;
          padding: 8px 10px;
          z-index: 100;
          display: none;
        }
      }
      .thumb {
        width: 312px;
        height: 175px;
        position: relative;
        margin-right: 25px;
        background: #1a1a1a;
        border-radius: 4px;
        .webinar_cover {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          border-radius: 4px;
          &.webinar_cover_1 {
            object-fit: fill;
          }
          &.webinar_cover_2 {
            object-fit: cover;
            object-position: left top;
          }
        }
        .liveTag {
          background: rgba(0, 0, 0, 0.7);
          // background: rgba(247, 245, 245, 0.7);
          color: #fff;
          font-size: 12px;
          padding: 4px 12px;
          border-radius: 20px;
          position: absolute;
          top: 12px;
          left: 12px;
          .live-status {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 4px;
            vertical-align: top;
            img {
              width: 100%;
              height: 100%;
              border-radius: 0;
              object-fit: scale-down;
            }
          }
        }
        .hot {
          position: absolute;
          height: 50px;
          width: 100%;
          /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%); */
          background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.6));
          bottom: 0px;
          left: 0px;
          color: #fff;
          font-size: 14px;
          z-index: 2;
          cursor: pointer;
          border-radius: 0 0 4px 4px;
          i {
            position: absolute;
            left: 14px;
            bottom: 10px;
          }
        }
      }
      .tag {
        border-radius: 20px;
        background: #f2f2f2;
        font-size: 12px;
        padding: 4px 8px;
        margin-right: 8px;
      }
    }
  }
  .float-dom {
    position: absolute;
    top: 40px;
    left: -40px !important;
    width: 420px;
    // height: 150px;
    border-radius: 4px;
    padding: 5px 10px;
    background: #fff;
    border: 1px solid #f2f2f2;
    transform-origin: center top;
    z-index: 2001;
    color: #666;
    line-height: 1.4;
    text-align: justify;
    font-size: 14px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    word-break: break-all;
    &.embed-nodata {
      padding: 0 32px 24px;
      height: initial;
      width: 452px;
    }
    p {
      font-size: 14px !important;
      margin-top: 30px !important;
    }
    .copy-item {
      padding-top: 10px !important;
    }

    .set-footer {
      color: rgba(15, 64, 245, 1);
    }
  }
  .invitation-code {
    text-align: center;
    padding: 2px 40px 10px;
    display: block !important;
    left: 50%;
    p {
      line-height: 40px;
    }
    img {
      margin-bottom: 10px;
      width: 132px;
      height: 132px;
    }
    .copy-item {
      text-align: right;
      padding: 24px 0 10px 0;
    }
    .notice_txt {
      display: block;
      font-size: 12px;
      // line-height: 14px;
      // margin-top: 6px;
      color: #666;
      margin-left: 60px;
      text-align: left;
    }
  }
  .urlCopy {
    padding: 2px 15px;
    p {
      margin-top: 20px;
      &:nth-child(2) {
        padding: 4px;
        font-size: 16px;
        ::v-deep .vh-button {
          font-size: 14px;
          line-height: 24px;
          padding: 2px 20px;
          margin-right: 20px;
        }
      }
      &.watch_link {
        margin-bottom: 0 !important;
      }
    }
  }

  .float-dom-director {
    position: absolute;
    top: 40px;
    left: -40px !important;
    width: 314px;
    height: 217px;
    border-radius: 4px;
    padding: 24px 32px;
    background: #fff;
    border: 1px solid #f2f2f2;
    transform-origin: center top;
    z-index: 2001;
    color: #666;
    line-height: 1.4;
    text-align: justify;
    font-size: 14px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    word-break: break-all;
    .flex-box {
      display: flex;
      align-items: center;
      margin-top: 20px;
      span {
        margin-right: 10px;
      }
      ::v-deep .vh-select {
        width: 194px;
        .vh-input__inner {
          height: 40px;
        }
      }
    }
    .indent {
      text-indent: 50px;
      margin-top: 8px;
    }
    .btns {
      margin-top: 24px;
      text-align: right;
    }
  }
  .mainColor {
    color: #1a1a1a;
  }
  .timerBox {
    span {
      display: inline-block;
      width: 26px;
    }
    .first-downTime {
      padding: 0 3px;
      text-align: right;
      width: 50px;
    }
  }
  .subColor,
  .subDuration {
    color: #666666;
  }
  .action-look {
    margin-top: 10px;
  }
  .check-url {
    display: inline-block;
    min-width: 62px;
    height: 32px;
    position: relative;
  }
  .director {
    margin-left: 8px;
  }
  .font-20 {
    font-size: @20;
    width: 100%;
    position: relative;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
    // &:hover .info .title_hover {
    //   display: block;
    // }
    // max-width: 500px;
    // height: 56px;
    // overflow: hidden;
    // text-overflow:ellipsis;
    // white-space: nowrap;
  }
  .liveTime {
    font-size: 14px;
    justify-content: center;
    flex-direction: column;
    i {
      font-style: normal;
    }
    p {
      text-align: center;
      i {
        vertical-align: text-top;
      }
      &:nth-child(1) {
        margin-bottom: 10px;
      }
      &:nth-child(2) {
        span {
          font-size: 22px;
          font-weight: bold;
        }
        i {
          margin: 0 2px;
        }
        // margin-bottom: 18px;
      }
    }
    .vh-button {
      width: 160px;
      margin: 22px auto 0;
    }

    .alert {
      margin-top: 24px;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      text-align: center;
      .qr {
        color: #3562fa;
        cursor: pointer;
      }
    }
  }
</style>

<style lang="less">
  .set-footer-dialog {
    .all_op {
      display: flex;
      height: 20px;
      justify-content: flex-end;
      margin-bottom: 20px;
      .active {
        z-index: 2;
        color: #fff;
        background-color: #fb2626;
        border: none;
      }

      span {
        z-index: 1;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 2px 7px;
        height: 20px;
        font-size: 12px;
        color: #666;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        cursor: pointer;
        box-sizing: border-box;

        &:nth-child(1) {
          transform: translateX(4px);
        }
      }
    }

    .footer-tip-box {
      .footer-tip {
        margin-bottom: 5px;
        font-size: 10px;
        text-decoration: none;
        color: rgb(79 79 79);
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0;
        line-height: 15px;
      }

      .footer-doc {
        font-size: 10px;
        text-decoration: none;
        color: rgb(15 64 245);
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0;
        line-height: 15px;
      }
    }
  }
</style>
