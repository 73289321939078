<template>
  <div class="vh-customer-previewPc">
    <component-download :menuInfo="menuInfo" :units="units" :pre="pre"></component-download>
  </div>
</template>
<script>
  import ComponentDownload from '../components-view/component-download';

  export default {
    props: {
      menuInfo: {
        required: true // 菜单对象
      },
      units: {
        required: true // 菜单下components集合
      },
      pre: {
        required: true
      }
    },
    components: {
      ComponentDownload
    },

    data() {
      return {};
    },
    methods: {
    }
  };
</script>
<style lang="less" scoped></style>
