import { isEmbed, sessionOrLocal } from './utils';
import fetchData from '@/api/fetch';

// 获取基本信息
function getLiveDetail(id) {
  // webinar/info调整-正常的信息展示使用 0
  return fetchData('getWebinarInfo', { webinar_id: id, is_rehearsal: 0 }).then(res => {
    const liveDetailInfo = res.data;
    sessionOrLocal.set('liveDetailInfo', liveDetailInfo);
    sessionOrLocal.set('webinarState', liveDetailInfo.webinar_state);
    sessionOrLocal.set('webinarType', liveDetailInfo.webinar_type);

    // 是否活动标记为云导播活动
    sessionOrLocal.set(`webinar_is_director__${id}`, res.data.is_director);
  });
}

function getPermission(id) {
  // 活动权限
  return fetchData('planFunctionGet', {
    webinar_id: id,
    webinar_user_id: sessionOrLocal.get('userId'),
    scene_id: 1
  })
    .then(res => {
      if (res.code == 200) {
        if (res.data.permissions) {
          sessionOrLocal.set('WEBINAR_PES', res.data.permissions, 'localStorage');
        } else {
          sessionOrLocal.removeItem('WEBINAR_PES');
        }
      }
    })
    .catch(e => {
      sessionOrLocal.removeItem('WEBINAR_PES');
    });
}

// 嵌入页获取活动信息和活动配置
export default async function embedInfo(to, from, next) {
  const webinar_id = to.params.str;
  if (isEmbed(to.path) && webinar_id) {
    try {
      await Promise.all([getLiveDetail(webinar_id), getPermission(webinar_id)]);
    } catch (err) {
      console.error(err);
    }
  }
  next();
}
