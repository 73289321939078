import Layout from '@/layout/embedLayout';

const router = [
  {
    path: '/embed/AI',
    component: Layout,
    meta: { auth: true, title: 'AI智能', name: 'AI' },
    hide: true,
    children: [
      {
        path: 'chat/list',
        component: () => import('@/views/AIModule/chat/list'),
        meta: { auth: true, title: 'AI聊天', name: 'AIChatList', showOneChildren: true }
      },
      {
        path: 'chat/create',
        component: () => import('@/views/AIModule/chat/edit'),
        meta: {
          auth: true,
          title: '创建',
          level: 2,
          name: 'AIChatCreate'
        },
        hidden: true
      },
      {
        path: 'chat/edit/:id',
        component: () => import('@/views/AIModule/chat/edit'),
        meta: {
          auth: true,
          title: '编辑',
          level: 2,
          name: 'AIChatEdit'
        },
        hidden: true
      }
    ]
  }
];
export default router;
