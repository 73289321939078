<template>
  <div :class="{ 'has-logo': showLogo }">
    <div class="sidebar-logo-container" :class="{ collapse: !sidebar.opened }">
      <transition>
        <!-- 关闭情况下 -->
        <router-link v-if="!sidebar.opened" key="collapse" class="sidebar-logo-link" to="/">
          <!-- <img v-if="logo" :src="logo" class="sidebar-logo" /> -->
        </router-link>
        <!-- 展开情况下 -->
        <div v-else key="expand" class="sidebar-logo-link">
          <a :href="logo_jump_url" v-if="logo" class="sidebar-logo2">
            <img v-if="logo" :src="logo" />
          </a>
          <a :href="logo_jump_url" v-else class="sidebar-logo2">
            <img
              src="../../../common/images/sys/logo-red@2x.png"
              v-if="!hasCms"
              class="sidebar-logo static"
            />
            <span class="logo_txt" v-else>活动平台DEP</span>
          </a>
        </div>
      </transition>
      <!-- 是否收缩按钮 -->
      <hamburger
        :is-active="sidebar.opened"
        :class="`hamburger-container ${sidebar.opened ? 'right' : 'left'}`"
        @toggleClick="toggleSideBar"
      />
    </div>
    <vh-scrollbar wrap-class="scrollbar-wrapper">
      <vh-menu
        :default-active="activeMenu"
        :collapse="!sidebar.opened"
        background-color="#fff"
        active-background-color="#F7F7F7"
        text-color="rgba(0, 0, 0, 0.85)"
        :unique-opened="true"
        active-text-color="#FB2626"
        :collapse-transition="false"
        @select="selectMenu"
        mode="vertical"
        class="vh-menu-reset"
      >
        <sidebar-item
          v-for="route in routes"
          :key="route.path"
          :item="route"
          :childPremission="childPremission"
          :base-path="route.path"
          v-show="isShow(route)"
        ></sidebar-item>
      </vh-menu>
    </vh-scrollbar>
  </div>
</template>

<script>
  import { routes } from '@/router';
  import SidebarItem from './SidebarItem';
  import Hamburger from '../Hamburger/index.vue';
  import { sessionOrLocal } from '@/utils/utils';
  import QnLogo from '@/common/images/temp/QnLogo.png';

  export default {
    components: {
      SidebarItem,
      Hamburger
    },
    data() {
      return {
        sidebar: {
          opened: true,
          withoutAnimation: false
        },
        logo: null,
        logo_jump_url: process.env.VUE_APP_COMPANY_URL,
        childPremission: {},
        userInfo: {},
        permission: {}
      };
    },
    computed: {
      routes() {
        return routes[this.$route.meta.project];
      },
      hasCms() {
        return (
          routes.eventhub.findIndex(el => {
            return el.meta.auth_key && this.permission[el.meta.auth_key];
          }) > -1
        );
      },
      activeMenu() {
        const route = this.$route;
        const { meta, path } = route;
        if (meta.activeMenu) {
          return meta.activeMenu;
        }
        return path;
      },
      showLogo() {
        return sessionOrLocal.get('sidebarLogo') || false;
      }
    },
    methods: {
      isShow(route) {
        if (this.childPremission && Number(this.childPremission.permission_data) === 0) {
          // 子账号有设置权限，数据不展示，碰到name=dataMgr的数据，不展示整个模块。其它模块正常处理
          if (route && route.meta && route.meta.name === 'dataMgr') {
            return false;
          } else {
            return (
              route &&
              route.children &&
              route.children.length > 0 &&
              route.children.filter(item => {
                if (this.vsQuanxian[item.meta.auth_key] > 0) {
                  return true;
                } else {
                  return false;
                }
              })
            );
          }
        } else {
          // TODO 左侧导航菜单
          if (route.meta && route.meta.auth_key && this.vsQuanxian) {
            console.log('左侧', route.meta.auth_key, this.vsQuanxian[route.meta.auth_key]);
            if (this.vsQuanxian[route.meta.auth_key] > 0) {
              // 由权限限制的字段，若大于0，则返回
              return true;
            } else {
              return false;
            }
          } else {
            // 七牛账号不展示以下菜单
            // if (this.userInfo.user_extends.extends_remark == 2 && ['/acc'].includes(route.path)) {
            //   return false;
            // }
            return true;
          }
        }
      },
      getChildPermission() {
        return this.$fetch('getChildPermission')
          .then(res => {
            console.log('当前权限', res.data);
            sessionOrLocal.set('SAAS_V3_SON_PS', JSON.stringify(res.data));
            this.childPremission = res.data;
            this.shieldPage(res.data);
          })
          .catch(res => {
            sessionOrLocal.removeItem('SAAS_V3_SON_PS');
            this.childPremission = {};
          });
      },
      go404() {
        this.$router.push('/warning/404');
      },
      shieldPage(permission) {
        const path = this.$route.path;
        const id = this.$route.params.str;
        // 内容管理，屏蔽回放管理页面
        if (!permission.permission_content && path === `/live/playback/${id}`) {
          this.go404();
        }
        // 数据管理，屏蔽以下五个页面
        if (
          !permission.permission_data &&
          (path === `/live/reportsData/${id}` || // 数据报告
            path === `/live/interactionData/${id}` || // 互动统计
            path === `/live/userData/${id}` || // 用户统计
            path === `/data/info` || // 数据总览
            path === `/data/live`) // 活动数据
        ) {
          this.go404();
        }
      },
      // 开启或者关闭 左侧导航部分
      toggleSideBar() {
        this.sidebar.opened = !this.sidebar.opened;
        sessionOrLocal.set('v3-control-sidebar', JSON.stringify(this.sidebar));
        this.$EventBus.$emit('hamburger', this.sidebar.opened);
      },
      changeImg() {
        console.log(111111111111);
      },
      selectMenu(index, indexPath) {
        switch (index) {
          case '/material/word':
            this.setReport(100510);
            break;
          case '/material/video':
            this.setReport(100517);
            break;
          case '/interactive/question':
            this.setReport(100525);
            break;
          case '/interactive/prize':
            this.setReport(100533);
            break;
          case '/setting/viewer':
            this.setReport(100540);
            break;
          case '/interactive/advertCard':
            this.setReport(100550);
            break;
          case '/interactive/gift':
            this.setReport(100556);
            break;
          case '/data/info':
            this.setReport(100562);
            break;
          case '/data/live':
            this.setReport(100577);
            break;
          case '/setting/function':
            this.setReport(100620);
            break;
          case '/setting/dev':
            this.setReport(100589);
            break;
          case '/setting/logo':
            this.setReport(100615);
            break;
          case '/setting/chat':
            this.setReport(100580);
            break;
          case '/setting/brand':
            this.setReport(100633);
            break;
          case '/setting/player':
            this.setReport(100490);
            break;
          case '/finance/info':
            this.setReport(100688);
            break;
          case '/finance/income':
            this.setReport(100749);
            break;
          case '/acc/info':
            this.setReport(100777);
            break;
          case '/acc/myHome':
            this.setReport(100790);
            break;
          case '/acc/son':
            this.setReport(100805);
            break;
        }
      },
      setReport(k) {
        let userId = sessionOrLocal.get('userId');
        this.$vhall_paas_port({
          k: k,
          data: {
            business_uid: userId,
            user_id: '',
            webinar_id: '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
      },
      pageResize() {
        this.$nextTick(() => {
          let screenWidth = window.screen.width;
          if (screenWidth < 1366) {
            this.sidebar.opened = false;
            sessionOrLocal.set('v3-control-sidebar', JSON.stringify(this.sidebar));
            this.$EventBus.$emit('hamburger', false);
          }
        });
      },
      // 接口-获取开发者信息
      fetchDeveloper() {
        // 是子账号情况下，若路由不是子账号管理 或者 不是开发设置，展示
        this.$fetch('getDeveloper', {}, {}).then(res => {
          const { code, data } = res;
          // 逻辑错误
          if (code !== 200) {
            return;
          }
          // 设置开发者信息
          this.$store.commit('setDeveloper', data);
        });
      }
    },
    async created() {
      this.permission = JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'));
      let userInfo = sessionOrLocal.get('userInfo');
      this.userInfo = JSON.parse(userInfo);
      let vsPersonStr = sessionOrLocal.get('SAAS_VS_PES', 'localStorage');
      if (vsPersonStr) {
        this.vsQuanxian = JSON.parse(vsPersonStr);
      }
      if (userInfo) {
        if (JSON.parse(userInfo).parent_id > 0) {
          // 获取子账号权限，更新
          await this.getChildPermission();
        }
        // 获取开发者权限
        await this.fetchDeveloper();
      }
    },
    mounted() {
      // 从缓存中获取控制台图片
      let userInfo = JSON.parse(sessionOrLocal.get('userInfo'));
      this.logo = userInfo.user_extends ? userInfo.user_extends.logo : '';
      if (userInfo.user_extends.extends_remark == 2) {
        this.logo = QnLogo;
      }

      if (this.logo) {
        this.logo_jump_url = userInfo.user_extends
          ? userInfo.user_extends.logo_jump_url
          : process.env.VUE_APP_COMPANY_URL;
      } else {
        this.logo_jump_url = process.env.VUE_APP_COMPANY_URL;
      }
      this.$EventBus.$on('hamburger', status => {
        this.sidebar.opened = status;
      });
      this.$EventBus.$on('saas_vs_account_change', res => {
        let user_extends = res.user_extends;
        this.logo = user_extends.logo;
        //七牛云特殊处理
        if (user_extends.extends_remark == 2 && !this.logo) {
          this.logo = QnLogo;
        }

        if (this.logo) {
          this.logo_jump_url = user_extends.logo_jump_url || process.env.VUE_APP_COMPANY_URL;
        } else {
          this.logo_jump_url = process.env.VUE_APP_COMPANY_URL;
        }
      });
      let _this = this; //赋值vue的this
      window.onresize = () => {
        // 调用methods中的事件
        _this.pageResize();
      };
    },
    destroyed() {
      window.onresize = null;
    }
  };
</script>
<style lang="less" scoped>
  /*LOGO部分*/
  .sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
  }
  .sidebarLogoFade-enter,
  .sidebarLogoFade-leave-to {
    opacity: 0;
  }
  .sidebar-logo-container {
    position: relative;
    width: 100% !important;
    height: 56px;
    background: #fff;
    text-align: center;
    overflow: hidden;
    border-bottom: 1px solid #f0f0f0;
    z-index: 10;
    .logo_txt {
      color: #fb2626;
      font-size: 22px;
      font-weight: 500;
    }
    & .sidebar-logo-link {
      text-align: center;
      height: 56px;
      padding-right: 56px;
      & .sidebar-logo {
        height: 44px;
        display: block;
        /* &.static {
        height: auto;
        width: 156px;
        float: left;
        margin: 4px 0;
      } */
      }

      a.sidebar-logo2 {
        text-align: left;
        display: flex !important;
        align-items: center;
        justify-content: center;
        height: 56px;
        img {
          height: 40px;
          max-width: 150px;
        }
      }
    }
    &.collapse {
      .sidebar-logo {
        margin-right: 0;
      }
    }
  }
  /*收缩按钮部分*/
  .hamburger-container {
    position: absolute;
    top: calc(50% - 23px);
    line-height: 44px;
    height: 44px;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
    &.right {
      right: 0;
    }
    &.left {
      left: 0;
    }
  }
</style>
