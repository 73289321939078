<template>
  <div class="customer_service_icon">
    <div @click="openCoze" class="coze_btn_text">
      <i class="iconfont-v3 saasline-robot"></i>
      <span>AI助手</span>
    </div>

    <div id="coze_btn">微吼-售后AI助手</div>
  </div>
</template>
<script>
  export default {
    methods: {
      initCoze() {
        const bot_id = '7431525417112043561';
        // 自定义Bot名称和icon
        const title = '微吼-售后AI助手';

        new CozeWebSDK.WebChatClient({
          config: {
            bot_id
          },
          componentProps: {
            title,
            icon: 'https://cnstatic01.e.vhall.com/common-static/images/service_avatar.png',
            width: 560,
            lang: 'zh-CN',
            layout: 'pc'
          },
          el: document.getElementById('coze_btn')
        });
      },
      openCoze() {
        document.getElementsByClassName('ab1ac9d9bab12da47298')[0].click();
      }
    },
    mounted() {
      this.initCoze();
      this.$EventBus.$on('openCoze', this.openCoze);
    }
  };
</script>
<style scoped lang="less">
  .customer_service_icon {
    overflow: hidden;
    height: 20px;
    line-height: 18px;
    width: 70px;
    color: #666666;
    font-size: 14px;
    .saasline-robot {
      font-size: 18px;
    }
    #coze_btn {
      margin-top: 10px;
    }
    .coze_btn_text {
      i {
        vertical-align: middle;
        margin-right: 5px;
      }
      span {
        vertical-align: middle;
      }
    }
  }
</style>
