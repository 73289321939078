<template>
  <div class="thirdlink-wrapbox">
    <div class="thirdlink-previewbox" v-if="mode == 1">
      <div v-if="!info.url" class="no-data">暂未配置有效地址</div>
      <iframe
        class="iframe-label"
        v-if="info.url"
        allow="camera *;microphone *; display-capture"
        allowfullscreen="true"
        border="0"
        :src="info.url"
        width="100%"
        :height="info.pageHeight"
      ></iframe>
    </div>

    <!-- 编辑 -->
    <div class="thirdlink-editor-box" v-if="mode == 2">
      <vh-card class="box-card">
        <div slot="header" class="clearfix card-header">
          <span class="card-title">
            外链嵌入
            <vh-tooltip effect="dark" placement="top-start">
              <div slot="content">
                1、单个tab下只支持设置一个外链嵌入组件，但支持多个tab都设置一个<br/>
                2、单个tab下支持外链嵌入与其他组件混合设置<br/>
                3、页面布局设置，宽度100%自适应，高度自定义配置<br/>
              </div>
              <i class="vh-tooltip iconfont-v3 saasicon_help_m"></i>
            </vh-tooltip>
          </span>
          <div class="func-opt">
            <a href="https://saas-doc.vhall.com/opendocs/show/1607" class="text-link" target="_blank" rel="noopener noreferrer">使用说明</a>
          </div>
        </div>

        <div class="step-containter">
          <div class="title">链接配置</div>
          <div class="content">
            <div class="form">
              <vh-form
                :model="info"
                ref="linkForm"
                :rules="linkFormRules"
                label-width="100px"
                class=""
              >
                <vh-form-item label="页面链接" prop="url">
                  <vh-input
                    v-model="info.url"
                    placeholder="请输入http://或https://开头的跳转地址"
                  ></vh-input>
                </vh-form-item>
                <vh-form-item label="页面高度" prop="pageHeight">
                  <vh-input type="number" v-model="info.pageHeight" placeholder="请输入页面高度">
                    <template slot="append">px</template>
                  </vh-input>
                </vh-form-item>
                <vh-form-item label="绑定参数">
                  <vh-checkbox-group v-model="info.checkedUrlParams">
                    <vh-checkbox v-for="item in urlParams" :label="item.key" :key="item.key">
                      {{ item.name }}
                    </vh-checkbox>
                  </vh-checkbox-group>
                </vh-form-item>
              </vh-form>
            </div>
          </div>
        </div>
      </vh-card>
    </div>
  </div>
</template>

<script>
  import regRule from '@/utils/reg-rule.js';
  export default {
    name: 'component-third-link',
    props: {
      // 1. 显示  2. 编辑
      mode: {
        required: true,
        default: 1
      },
      info: {
        required: false
      },
      //
      pre: {
        required: true
      }
    },
    data() {
      const validateUrl = (rule, value, callback) => {
        const result = regRule.url.test(value);
        if (result) {
          this.updateInfo();
          callback();
        } else {
          callback(new Error('请输入http://或https://开头的地址'));
        }
      };

      const validatePageHeight = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入页面高度'));
        } else {
          // 更新信息

          this.updateInfo();
          callback();
        }
      };

      return {
        //地址栏扩展参数
        urlParams: [
          {
            key: 'VH_DEP_webinar_id',
            name: '活动ID(VH_DEP_webinar_id)'
          },
          {
            key: 'VH_DEP_third_user_id',
            name: '三方用户ID(VH_DEP_third_user_id)'
          },
          {
            key: 'VH_DEP_user_nick_name',
            name: '观众昵称(VH_DEP_user_nick_name)'
          },
          {
            key: 'VH_DEP_user_avatar',
            name: '观众头像(VH_DEP_user_avatar)'
          }
        ],

        linkFormRules: {
          url: [{ required: true, validator: validateUrl, trigger: 'blur' }],
          pageHeight: [{ required: true, validator: validatePageHeight, trigger: 'blur' }]
        }
      };
    },
    mounted() {
      console.log('component-thirdlink-------------->', this.mode, this.info, this.pre);
    },
    methods: {
      // 统一更新信息
      updateInfo() {
        this.$emit('updateInfo', this.info);
      }
    }
  };
</script>
<style lang="less" scoped>
  .thirdlink-wrapbox {
    .iframe-label {
      border: none;
    }
  }

  .thirdlink-previewbox {
    .no-data {
      text-align: center;
    }
  }

  .thirdlink-editor-box {
    padding-top: 16px;

    .text-link {
      color: #0a7ff5;
      :hover {
        color: #0060cf;
      }
    }

    .card-title {
      font-size: 16px;
      font-weight: bold;
    }

    .card-header {
      .func-opt {
        float: right;
        font-size: 14px;
      }
    }

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: '';
    }
    .clearfix:after {
      clear: both;
    }

    .step-containter {
      .title {
        font-size: 14px;
        font-weight: bold;
      }
      .content {
        padding: 10px;
      }
      .input-code {
        width: 150px;
      }
      .opt-btn {
        margin: 0 10px;
      }
      .intro {
        line-height: 20px;
        font-size: 12px;
        a.text-link {
          margin: 0 10px;
        }
      }
      .form {
        padding: 20px 0;
      }
    }
  }
</style>
