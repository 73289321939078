<template>
  <div class="vh-customer-componets-editor-preview">
    <component
      :is="componetPreview"
      :units.sync="currentInfo.components"
      :menuInfo="currentInfo"
      :uuid="currentInfo.uuid"
      :pre="pre"
    ></component>
  </div>
</template>
<script>
  import { TypeMaps } from '../componentMap';
  // 预览组件区域
  import componentCustomer from './component-customer';
  import ComponentDes from './component-des';
  import componentDownload from './component-download';

  export default {
    props: {
      currentInfo: {
        required: true
      },
      pre: {
        required: true
      }
    },

    components: {
      'component-customer': componentCustomer,
      'component-des': ComponentDes,
      'component-download': componentDownload
    },

    computed: {
      type: function () {
        return this.currentInfo.type;
      },

      componetPreview: function () {
        return TypeMaps[this.type];
      }
    },

    created() {
      // EventBus.$on(eventsType.INIT_MENU_INFO, this.initInfo)
    },

    methods: {
      initInfo(menuInfo) {
        this.currentInfo = menuInfo;
        if (this.type == 1) {
          this.currentInfo.components = this.currentInfo.components.map(item => {
            return {
              ...item
            };
          });
        }
      }
    }
  };
</script>
<style lang="less" scoped>
  .vh-customer-componets-editor-preview {
    height: 100%;
  }
</style>
