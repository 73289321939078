<template>
  <div class="height-question">
    <div class="show-question question-base" v-if="dialogVisible">
      <div class="show-main data-base">
        <p class="title">
          选择问卷
          <i class="vh-icon-close" @click="dialogVisible = false"></i>
        </p>
        <div class="data-base-line">
          <vh-button
            type="primary"
            round
            @click="addQuestion"
            v-preventReClick
            v-if="!(total == 0 && !isSearch)"
          >
            创建问卷
          </vh-button>
          <div class="data-search" v-show="total || isSearch">
            <vh-input
              class="search-dialog-tag"
              v-clearEmoij
              v-model="keyword"
              placeholder="搜索问卷名称"
              clearable
              @blur="getTitle"
              @keyup.enter.native="getTitle"
              style="width: 220px"
              @clear="getTitle"
              round
            >
              <i
                slot="prefix"
                class="vh-icon-search vh-input__icon"
                style="cursor: pointer; line-height: 36px"
                @click="getTitle"
              ></i>
            </vh-input>
          </div>
        </div>
        <div class="data-base-list" v-show="total || isSearch">
          <vh-table
            :data="tableData"
            ref="tableList"
            style="width: 100%"
            :height="isSearch && total == 0 ? 0 : 320"
            v-loadMore="moreLoadData"
            @selection-change="handleSelectionChange"
            @select-all="checkAllQuestion"
          >
            <template>
              <vh-table-column
                type="selection"
                v-if="maxCheckedNum > 1"
                width="55"
                :key="1"
              ></vh-table-column>
              <vh-table-column v-else fixed width="55">
                <template slot-scope="scope">
                  <vh-radio
                    v-model="selectId"
                    class="no_label"
                    :label="scope.row.question_id"
                  ></vh-radio>
                </template>
              </vh-table-column>
            </template>

            <vh-table-column fixed label="标题">
              <template slot-scope="scope">
                <span class="mediaName" :title="scope.row.title">
                  {{ scope.row.title }}
                </span>
              </template>
            </vh-table-column>
            <vh-table-column fixed prop="updated_at" label="更新时间"></vh-table-column>
            <vh-table-column fixed prop="topic_num" label="题目数量"></vh-table-column>
            <vh-table-column width="100" fixed label="操作">
              <template slot-scope="scope">
                <span class="show-hover" style="cursor: pointer" @click="preview(scope.row)">
                  预览
                </span>
              </template>
            </vh-table-column>
          </vh-table>
          <noData :nullType="'search'" :height="50" v-if="isSearch && total === 0"></noData>
        </div>
        <div class="no-live" v-show="total == 0 && !isSearch">
          <noData :nullType="'nullData'" :text="'您还没有问卷，快来创建吧！'" :height="10">
            <vh-button type="primary" round @click="addQuestion" v-preventReClick>
              创建问卷
            </vh-button>
          </noData>
        </div>
        <div v-show="total || (isSearch && total == 0)" class="footer">
          <p class="text" v-if="maxCheckedNum != 1">
            已选择
            <span>{{ checkList.length }}</span>
            个表单
          </p>
          <div slot="footer" class="dialog-footer">
            <vh-button
              round
              type="info"
              plain
              size="medium"
              @click.prevent.stop="handleCloseVisiton"
              v-preventReClick
            >
              取消
            </vh-button>
            <vh-button
              round
              size="medium"
              type="primary"
              @click.prevent.stop="choseSureQuestion"
              :disabled="!selectId && checkList.length < 1"
              v-preventReClick
            >
              确定
            </vh-button>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isShowQuestion">
      <div class="show-question" @click="isShowQuestion = false">
        <div class="show-main" @click.stop="isShowQuestion = true">
          <span class="close-btn">
            <i class="vh-icon-close" @click.stop="isShowQuestion = false"></i>
          </span>
          <vh-scrollbar>
            <div class="question_main">
              <pre-question :questionId="questionId"></pre-question>
            </div>
          </vh-scrollbar>
          <div class="submit-footer">
            <vh-button class="length152" type="primary" disabled size="medium" round>
              提交
            </vh-button>
          </div>
          <!-- 隐私协议合规（活动下） -->
          <!-- <privacy-select scene="question" compType="2"></privacy-select> -->
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  // import PrivacySelect from '@/views/Login/components/privacy-select.vue';
  import preQuestion from '@/components/Question/preQuestion';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import { isEmbed, embedRoomId, jump } from '@/utils/utils';

  export default {
    data() {
      return {
        total: '',
        isSearch: false, //是否是搜索
        dialogVisible: false,
        isShowQuestion: false,
        loading: false,
        keyword: '',
        questionId: '',
        checkList: [],
        totalPages: 1,
        tableData: [],
        isCheckAll: false,
        pageInfo: {
          pageNum: 1,
          pos: 0,
          limit: 10
        },
        selectId: ''
      };
    },
    props: {
      // 场景, 20230907新增，不同的场景可能调用不同接口。
      // 特殊业务类型，0-互动有礼
      bizType: {
        required: false,
        type: [Number, String],
        default: '' // 默认
      },
      maxCheckedNum: {
        required: false,
        type: Number,
        default: 0
      },
      // 默认需要选中的
      selected: {
        type: Array,
        default() {
          return [];
        }
      },
      // 包括的ids
      includeIds: {
        type: Array,
        default() {
          return [];
        }
      },
      // 排除的ids
      excludeIds: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    components: {
      preQuestion,
      noData
      // PrivacySelect
    },
    watch: {
      dialogVisible() {
        if (this.dialogVisible) {
          this.keyword = '';
          this.isCheckAll = false;
          this.pageInfo.pageNum = 1;
          this.pageInfo.pos = 0;
          this.checkList = [];
          this.tableData = [];
          this.getTableList();
          this.selectedDefaultList(this.selected);
        }
      },
      selectId(val) {
        this.handleRadioChange(val);
      }
    },
    mounted() {
      // if (this.dialogVisible) {
      //   this.getTableList();
      // }
    },
    methods: {
      setDefault(checkList) {
        this.checkList = checkList.map(item => item.question_id);
        this.questionList = checkList;
      },
      handleClose(done) {
        this.pageInfo.pageNum = 1;
        this.pageInfo.pos = 0;
        done();
      },
      handleCloseVisiton() {
        this.pageInfo.pageNum = 1;
        this.pageInfo.pos = 0;
        this.dialogVisible = false;
      },
      moreLoadData() {
        if (this.pageInfo.pageNum >= this.totalPages) {
          return false;
        }
        this.pageInfo.pageNum++;
        this.pageInfo.pos = parseInt((this.pageInfo.pageNum - 1) * this.pageInfo.limit);
        this.getTableList();
      },
      handleBtnClick(val) {
        let methodsCombin = this.$options.methods;
        methodsCombin[val.type](this, val);
      },
      getTitle() {
        this.tableData = [];
        this.pageInfo.pageNum = 1;
        this.pageInfo.pos = 0;
        try {
          if (this.$refs.tableList) {
            this.$refs.tableList.clearSelection();
          }
        } catch (e) {}
        this.getTableList();
      },
      getTableList() {
        let formParams = {
          webinar_id: this.$route.params.str,
          room_id: embedRoomId() || this.$route.query.roomId,
          keyword: this.keyword
        };
        formParams.special_biz_type = this.bizType;
        if (this.includeIds && this.includeIds.length) {
          formParams.include_ids = this.includeIds.join(',');
        }
        if (this.excludeIds && this.excludeIds.length) {
          formParams.exclude_ids = this.excludeIds.join(',');
        }
        this.isSearch = this.keyword ? true : false;
        let obj = Object.assign({}, this.pageInfo, formParams);
        this.$fetch('getLiveQuestionList', this.$params(obj)).then(res => {
          this.loading = false;
          this.total = Number(res.data.total);
          let list = res.data.list || [];
          if (this.pageInfo.pos === 0) {
            this.tableData = list;
          } else {
            list && this.tableData.push(...list);
          }

          if (this.isCheckAll) {
            this.$refs.tableList.toggleAllSelection();
          }
          console.log(this.tableData, '55555555555');
          this.totalPages = Math.ceil(res.data.total / this.pageInfo.limit);
          this.selectedDefaultList(this.selected);
        });
      },
      selectedDefaultList(list) {
        if (this.maxCheckedNum > 1) {
          this.$nextTick(() => {
            if (list.length) {
              let selectedList = [];
              if (typeof list[0] === 'object') {
                selectedList = list.map(item => {
                  return item.question_id;
                });
              } else {
                selectedList = list.concat();
              }
              // 如果返回的列表已经选过，就默认选中
              this.tableData.forEach(item => {
                if (selectedList.includes(item.question_id)) {
                  this.$nextTick(() => {
                    this.$refs.tableList.toggleRowSelection(item, true);
                  });
                }
              });
            }
          });
        } else {
          this.selectId = Number(this.selected[0]);
          this.handleRadioChange(this.selectId);
        }
      },
      // 选择活动中的问卷
      async choseSureQuestion() {
        // maxCheckedNum = 20，最多只能添加20个问卷。
        if (this.maxCheckedNum < 1) return;
        if (
          this.maxCheckedNum > 1 &&
          this.checkList &&
          this.checkList.length > this.maxCheckedNum
        ) {
          this.$vhMessage.error(`每次只能添加${this.maxCheckedNum}个问卷`);
          return;
        }
        await this.$fetch('checkExist', {
          form_id: this.maxCheckedNum === 1 ? this.selectId : this.checkList.join(',')
        })
          .then(() => {
            this.dialogVisible = false;
            this.$emit('save', this.questionList);
          })
          .catch(res => {
            this.getTitle();
            this.$vhMessage.error(res.msg);
            return;
          });
      },
      choseShowQueston() {
        this.isShowQuestion = false;
        this.dialogVisible = true;
      },
      addQuestion() {
        // 嵌入页当前页跳转即可
        jump({
          path: `${isEmbed() ? '/embed' : ''}/live/addQuestion`,
          query: {
            webinarId: this.$route.params.str,
            type: 2,
            roomId: embedRoomId() || this.$route.query.roomId
          }
        });
      },
      // 预览
      preview(rows) {
        console.log('预览', rows);
        this.questionId = rows.question_id;
        this.isShowQuestion = true;
        this.dialogVisible = false;
      },
      checkAllQuestion(selection) {
        // 全选
        this.isCheckAll = selection && selection.length > 0;
        this.checkList = selection.map(item => item.question_id);
        this.questionList = selection.filter(item => this.checkList.includes(item.question_id));
      },
      //多选选中
      handleSelectionChange(val) {
        this.checkList = val.map(item => item.question_id);
        this.questionList = val.filter(item => this.checkList.includes(item.question_id));
      },
      //单选选中
      handleRadioChange(val) {
        this.questionList = this.tableData.filter(item => item.question_id == val);
        console.log('单选选中', this.questionList);
      }
    }
  };
</script>
<style lang="less" scoped>
  .search-dialog-tag {
    ::v-deep .vh-input__prefix {
      cursor: pointer;
    }
  }
  .data-base {
    position: relative;
    z-index: 1000;
    .title {
      padding: 32px;
      font-size: 20px;
      i {
        float: right;
        color: #1a1a1a;
      }
    }
    .data-base-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 32px;
    }
    .data-search {
      padding-left: 24px;
    }
    .no-live {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 432px;
    }
  }

  .data-base-list {
    width: 100%;
    margin: 24px 0;
    padding: 0 32px;
    .mediaName {
      font-size: 14px;
      color: #1a1a1a;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .show-hover {
      &:hover {
        color: #fb3a32;
      }
    }
  }
  .text {
    height: 40px;
    padding-top: 8px;
    font-size: 14px;
    padding-left: 32px;
    span {
      color: #fb3a32;
      padding: 0 5px;
    }
    span {
      cursor: pointer;
    }
  }
  .show-question {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.3);
    .show-main {
      position: absolute;
      top: 48%;
      left: 50%;
      background: #fff;
      transform: translate(-50%, -50%);
      width: 750px;
      padding-bottom: 24px;
      border-radius: 4px;
      .question_main {
        max-height: 550px;
        position: relative;
      }
      .close-btn {
        z-index: 100;
        position: absolute;
        top: 16px;
        right: 16px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        padding: 5px;
        i {
          color: #fff;
          font-size: 20px;
          cursor: pointer;
        }
      }

      .submit-footer {
        text-align: center;
        padding-top: 20px;
      }
    }
    .data-base {
      width: 750px;
    }
  }
  .footer {
    height: 40px;
  }
  .dialog-footer {
    position: absolute;
    bottom: 25px;
    right: 30px;
  }
  .no_label {
    /deep/ .vh-radio__label {
      display: none !important;
    }
  }
</style>
