<template>
  <component :is="type" v-bind="linkProps(to)">
    <slot />
  </component>
</template>

<script>
  import regRule from '@/utils/reg-rule.js';
  export default {
    props: {
      to: {
        type: String,
        required: true
      }
    },
    computed: {
      isExternal() {
        return regRule.isExternal.test(this.to);
      },
      type() {
        if (this.isExternal) {
          return 'a';
        }
        return 'router-link';
      }
    },
    methods: {
      linkProps(to) {
        if (this.isExternal) {
          return {
            href: to,
            target: '_blank',
            rel: 'noopener'
          };
        }
        return {
          to: to
        };
      }
    }
  };
</script>
