<template>
  <div class="custormer-menu__editor-box">
    <template v-if="showEditView">
      <component
        :is="componetEditor"
        :menuInfo.sync="currentInfo"
        :units.sync="currentInfo.components"
        :uuid="currentInfo.uuid"
        :pre="pre"
      ></component>
    </template>
  </div>
</template>

<script>
  import eventsType from '../EventConts';
  import { TypeMaps } from './componentMap';
  import componentCustomer from './componet-editor/component-customerEditor';
  import componentChat from './componet-editor/component-chatEditor';
  import componentDownload from './componet-editor/component-download';
  import componentHixianchang from './componet-editor/component-hixianchang';
  import componentMoments from './componet-editor/component-moments';
  import componentSummary from './componet-editor/component-summary';

  export default {
    props: {
      pre: {
        required: true
      }
    },
    data() {
      return {
        currentInfo: {}
      };
    },

    components: {
      componentCustomer,
      componentChat,
      componentDownload,
      componentHixianchang,
      componentMoments,
      componentSummary
    },

    computed: {
      type: function () {
        return Number(this.currentInfo.type);
      },
      componetEditor: function () {
        return TypeMaps[this.type];
      },

      /**
       * 显示对应的编辑组件
       * 1: 自定义菜单 3: 聊天 9: 文件下载 10:游戏互动 精彩时刻:11, 概要总结:12, 这5个是固定的编辑组件
       * @returns {any}
       */
      showEditView() {
        return [1, 3, 9, 10, 11, 12].includes(this.type);
      }
    },

    created() {
      this.$EventBus.$on(eventsType.INIT_MENU_INFO, this.initInfo);
    },

    methods: {
      initInfo(info) {
        this.currentInfo = info;
      }
    }
  };
</script>
<style lang="less" scoped>
  .custormer-menu__editor-box {
    width: 100%;
    height: 100%;
  }
</style>
