const menuTypes = {
  // 菜单 内容更新
  UPDATE_MENU_INFO: 'UPDATE_MENU_INFO',
  // 初始化 菜单内容
  INIT_MENU_INFO: 'INIT_MENU_INFO',
  // 单组件内容变更
  UPDATE_COMPONENT_CONTENT: 'UPDATE_COMPONENT_CONTENT',
  // 编辑单个 自定义组件
  EDITOR_COMPONENT_INFO: 'EDITOR_COMPONENT_INFO',
  // 编辑单个 直播选择 - 选项
  EDITOR_COMPONENT_ITEM_INFO: 'EDITOR_COMPONENT_ITEM_INFO',
  // 编辑单个 专题选择 - 选项
  EDITOR_COMPONENTP_PROJECT_ITEM_INFO: 'EDITOR_COMPONENTP_PROJECT_ITEM_INFO'
};
export default menuTypes;
