<template>
  <div class="moments-wrapbox">
    <div class="moments-top">
      <div
        :class="{
          'moments-top-text': true,
          bold: WEBINAR_PES['ai_creation'] != 1
        }"
      >
        {{
          WEBINAR_PES['ai_creation'] != 1
            ? '「精彩时刻」是收费功能，请联系您的客户经理或专属售后，也可拨打400-888-970转2咨询'
            : '设置后，观众点击精彩时刻片段，视频会自动跳转至该时刻进行播放。请在回放管理中创作「精彩时刻」'
        }}
      </div>
      <vh-button
        v-if="WEBINAR_PES['ai_creation'] != 1"
        class="moments-top-btn"
        @click="contactCustormer"
      >
        在线客服
      </vh-button>
      <vh-button
        v-if="WEBINAR_PES['ai_creation'] == 1"
        class="moments-top-btn active"
        @click="goPage"
      >
        马上创作
      </vh-button>
    </div>
    <div class="moments-middle">
      <div class="moments-middle-tip">
        通过AI能力，智能挑选视频中的关键亮点，让观众更便捷观看自己感兴趣的内容
      </div>
      <div class="moments-middle-message">
        <div class="moments-middle-message-direction">
          <div class="moments-middle-message-direction-item">
            <vh-icon
              class="moments-middle-message-direction-item-icon vh-iconfont vh-a-line-livemanagement"
            ></vh-icon>
            <div class="moments-middle-message-direction-item-text">1个视频</div>
          </div>
          <img
            class="moments-middle-message-direction-icon"
            src="../../../../../common/images/custom-tab/moment-arrow.png"
            alt=""
          />
          <div class="moments-middle-message-direction-item">
            <vh-icon
              class="moments-middle-message-direction-item-icon vh-iconfont vh-line-film"
            ></vh-icon>
            <div class="moments-middle-message-direction-item-text">多个精彩</div>
          </div>
        </div>
        <div class="moments-middle-message-list">
          <div class="moments-middle-message-list-item">
            <div class="moments-middle-message-list-item-title">·AI支持多种生成方式</div>
            <div class="moments-middle-message-list-item-text">
              根据讲解内容关键词生成
              <br />
              根据文本反向定位视频快速添加
            </div>
          </div>
          <div class="moments-middle-message-list-item">
            <div class="moments-middle-message-list-item-title">·增强观众观看效率</div>
            <div class="moments-middle-message-list-item-text">通过精彩时刻了解活动内容</div>
          </div>
        </div>
      </div>
    </div>
    <div class="moments-bottom">
      <div class="moments-bottom-item">
        <h1>管理端 - 智能生成多个精彩视频</h1>
        <p>（多种智能方式）</p>
        <img
          class="moments-bottom-console-img"
          src="../../../../../common/images/custom-tab/moments-console.png"
        />
      </div>
      <div class="moments-bottom-item">
        <h1>观众端 - 精彩时刻快速定位观看</h1>
        <p>（体验更好，观看更直接）</p>
        <img
          class="moments-bottom-wap-img"
          src="../../../../../common/images/custom-tab/moments-wap.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { sessionOrLocal } from '@/utils/utils';
  export default {
    data() {
      return {
        WEBINAR_PES: {}
      };
    },
    created() {
      const permissions = sessionOrLocal.get('WEBINAR_PES', 'localStorage');
      this.WEBINAR_PES = JSON.parse(permissions);
    },
    methods: {
      // 马上创作
      goPage() {
        const liveDetailInfo = JSON.parse(sessionOrLocal.get('liveDetailInfo'));
        // if ((process.env.VUE_APP_AI_HOST || '').indexOf('localhost') > -1) {
        //   const token = sessionOrLocal.get('token', 'localStorage') || '';
        //   window.location.href = `${process.env.VUE_APP_AI_HOST}video/moments/${liveDetailInfo.record_id}?token=${token}`;
        // } else {
        //   window.location.href = `${process.env.VUE_APP_AI_HOST}v3/saas-web-ai/video/moments/${liveDetailInfo.record_id}`;
        // }
        console.log(liveDetailInfo, window.location.origin);
        // window.location.href = `${window.location.origin}/live/playback/${liveDetailInfo.id}`;
        window.open(`${window.location.origin}/v3/live/playback/${liveDetailInfo.id}`, '_blank');
      },

      // 联系客服
      contactCustormer() {
        window.open(`https://vhall.s4.udesk.cn/im_client/?web_plugin_id=15038`, '_blank');
      }
    }
  };
</script>

<style lang="less" scoped>
  .moments-wrapbox {
    padding: 1px 0;
    box-sizing: border-box;

    .moments-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-text {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 22px;
        &.bold {
          font-weight: 600;
        }
      }
      &-btn {
        width: 104px;
        height: 36px;
        padding: 0;
        border: 1px solid #fb2626;
        font-size: 14px;
        color: #fb2626;
        border-radius: 18px;
        margin-top: 24px;

        &.active {
          background: #fb2626;
          border: none;
          color: #ffffff;
        }
      }
    }

    .moments-middle {
      margin-top: 24px;

      &-tip {
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }

      &-message {
        margin-top: 12px;
        background: #faf7f6;
        height: 216px;

        &-direction {
          padding: 12px 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          &-icon {
            width: 20px;
            height: 20px;
            display: block;
            margin: 0 32px;
          }

          &-item {
            height: 54px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px 32px;
            border-radius: 27px;
            background: #ffffff;

            &-icon {
              margin-right: 16px;
              color: #fb2626;
              font-size: 20px;
            }

            &-text {
              font-size: 16px;
              font-weight: 500;
              color: #262626;
            }
          }
        }

        &-list {
          display: flex;
          flex-direction: column;
          align-items: center;

          &-item {
            width: 196px;
            &:last-child {
              margin-top: 8px;
            }
            &-title {
              line-height: 22px;
              font-size: 14px;
              font-weight: 600;
              color: #262626;
            }
            &-text {
              margin-top: 4px;
              line-height: 22px;
              font-size: 14px;
              font-weight: 400;
              color: #262626;
            }
          }
        }
      }
    }

    .moments-bottom {
      height: 311px;
      background: #ebefff;
      margin-top: 12px;
      position: relative;
      padding: 12px 20px 10px 4px;
      display: flex;
      justify-content: center;

      &-item {
        min-width: 172px;
        margin: 0 4px;
        h1 {
          text-align: center;
          line-height: 20px;
          font-size: 12px;
          font-weight: 600;
          color: #262626;
        }
        p {
          margin-top: 2px;
          text-align: center;
          line-height: 20px;
          font-size: 12px;
          font-weight: 500;
          color: #595959;
        }
      }

      &-console-img {
        width: 210px;
        margin-top: 12px;
      }
      &-wap-img {
        width: 110px;
        display: block;
        margin: 0 auto;
        margin-top: 12px;
      }
    }
  }
</style>
