const errorMap = {
  600: '参数错误',
  201: '参数错误',
  5110014: '红包已经被抢光了',
  5110015: '你已经抢过此红包了',
  5110018: '不符合红包领取条件',
  5110022: '红包已过期',
  5110023: '红包还未开始不能抢',
  5140504: '文件上传失败，请重试',
  5140402: '当前上传类型不被允许',
  5140401: '上传文件不存在或文件超过服务器限制，上传文件最大支持50M',
  510000: '参数错误',
  510001: '未知错误',
  510002: '结果数据错误',
  510003: '未定义返回字段类型',
  510004: '未定义返回字段是否必须',
  510005: '返回结果必须是对象',
  510006: '验证规则不可为空',
  510007: '没有权限',
  510008: '未登录',
  510009: '用户不存在',
  510010: '系统错误，请稍后重试',
  510011: '平台参数错误（platform）',
  510012: '非法访问',
  510013: '不能包含中文和特殊字符',
  512000: '删除失败,数据存在异常',
  513270: '文件信息参数错误',
  513271: '工单创建失败',
  513272: '工单创建失败,文件保存失败',
  513273: '工单信息不存在',
  513274: '工单回复失败',
  513275: '状态更新失败',
  513276: '工单问题已解决，禁止回复',
  513277: '请在控制台创建工单',
  513278: '没有权限',
  513301: '文件格式错误',
  513302: '请使用模版文件上传',
  512001: '删除失败',
  512002: '活动不存在或已删除',
  512205: '活动组不存在或已删除',
  512003: '该活动不属于此用户',
  512004: '活动创建失败',
  512005: '活动扩展信息创建失败',
  512006: '活动修改失败',
  512007: '活动修改异常',
  512008: '暖场视频删除异常',
  512009: '暖场视频封面更换失败',
  512010: '暖场视频不存在',
  512011: '暖场视频添加失败',
  512012: '观众分组创建失败',
  512013: '观众分组修改失败',
  512014: '观众分组删除失败',
  512015: '分组不存在',
  512016: '三方验证设置失败',
  512017: '白名单观众不存在',
  512018: '白名单观众修改失败',
  512019: '白名单观众删除失败',
  512020: '白名单观众创建失败',
  512021: '邀请码创建数量超出限制',
  512022: '批量创建白名单观众超出限制',
  512023: '修改观看限制必要参数缺少',
  512024: '此白名单不属于该用户',
  512025: '观看限制保存失败',
  512026: '此活动类型不支持修改嘉宾',
  512027: '角色权限修改失败',
  512028: '创建专题失败',
  512029: '专题不存在',
  512030: '专题删除失败',
  512031: '专题修改失败',
  512032: '点播创建失败',
  512033: '回放不存在',
  512034: '点播不存在场次信息',
  512035: '点播不支持角色设置',
  512036: '此用户信息有部分已存在此分组中',
  512037: '白名单用户不存在',
  512038: '不能对其他账户下的白名单观众进行操作',
  512039: '用户组不存在',
  512040: '嘉宾口令和助理口令不能相同',
  512042: '专题不属于该用户',
  512043: '此音视频不属于该用户',
  512044: '音视频创建失败',
  512045: '音视频修改失败',
  512046: '音视频删除失败',
  512047: '活动扩展信息修改失败',
  512048: '回放不属于该用户',
  512049: '该分组已存在',
  512050: '用户并发量不足创建此活动',
  512051: '活动最高并发超出限制',
  512501: '活动创建者不存在',
  512502: '不支持的活动类型(flash)',
  512503: '不支持的活动类型(旧h5)',
  512504: '不支持的活动类型(点播)',
  512505: '没有找到房间',
  512506: 'live_token已过期',
  512507: 'live_token错误',
  512508: '非互动模式不支持嘉宾参会',
  512509: '未开启角色权限',
  512511: '当前已有主持人进入直播间，请使用其它身份登录',
  512513: '检测到您已登录主办方账号，请先退出账号或使用其他浏览器访问',
  512514: '您已被禁止访问房间！',
  512515: '未找到参会数据',
  512516: '该账号已进入直播',
  512517: '没有互动直播权限',
  512518: '互动模式不支持转播',
  512519: '不能重复转播',
  512520: '转播不能与转播源相同',
  512521: '直播未开始',
  512522: '正在使用主持人身份参与活动,无法进行观看,建议清除浏览器cookie后再进行访问',
  512523: '需要付费',
  512524: '被转播活动未开始直播',
  512525: '需要填写报名表单',
  512526: '检测类型和活动观看限制类型不一致',
  512527: '密码错误',
  512528: '请输入密码',
  512529: '邀请码错误',
  512530: '邀请码已被使用',
  512531: '请输入邀请码',
  512532: '请输入白名单手机号|邮箱|工号|其它',
  512534: '跳转链接',
  512535: '组织者对该活动设置了验证机制',
  512536: '回放不存在',
  512537: '热度不能小于在线人数',
  512538: '组织者对该活动设置了验证机制,验证失败',
  512539: 'sdk验证只支持key值验证',
  512540: '活动已结束',
  512541: '活动现场太火爆，已超过人数上限',
  512542: '访客数据信息不全',
  512543: '此视频暂时下线了',
  512544: '该视频已下线，有疑问请联系客服',
  512545: '该视频转码失败',
  512546: '该视频正在转码中',
  512547: '亲，嵌入页面只支持三种验证方式：默认、密码、第三方K值！无法观看，请联系活动主办方',
  512548: '正在录制中，无法发起直播',
  512549: '非直播中活动',
  512550: '正在直播中，无法发起录制',
  512551: '仅支持音视频',
  512552: '分辨率错误，目前只支持 a,360p,480p,720p,same',
  512553: '回调地址格式错误',
  512554: '上传的点播不允许操作',
  512555: '当前分辨率已经转换，请误重复操作!',
  512556: '当前分辨率正在转换，请误重复操作!',
  512557: '当前分辨率系统转换失败!',
  512558: '当前分辨率的下载任务已请求，请误重复操作!',
  512559: '当前下载的分辨率回放未生成，请先生成后下载!',
  512560: '该视频正在审核中',
  512561: '流信息不存在',
  512562: '无极版流量已达上限',
  512563: '并发已达上限',
  512564: '无极版并发已达上限',
  512565: '活动所有者不是会员，暂不能进入',
  512566: '你有修改的章节正在保存中',
  512567: '不支持的活动类型(必须是点播)',
  512568: '未传入回调地址且回调地址未配置',
  512569: '无权操作主持人邮箱',
  512570: '短信验证码错误',
  512600: '开始或结束活动异常',
  512601: '开始或关闭转播流异常',
  512602: 'symbol参数格式错误',
  512603: '身份信息格式错误',
  512604: '不是活动的回放',
  512605: '白名单失效，请清空缓存后重新填写',
  512606: '报名表单失效，请清空缓存后重新填写',
  512607: '请先执行开始打点',
  512608: '请求流媒体接口失败',
  512609: '主持人参会记录已存在',
  512800: '报名表单不能与白名单同时开启',
  512801: '超过报名表单最大容纳题目数量限制',
  512802: '题目不存在',
  512803: '隐私协议不存在',
  512804: '超过单个题目容纳选项数量限制',
  512805: '没有操作权限',
  512806: '题目选项不存在',
  512807: '选项不能少于2个',
  512808: '当前角色禁止报名',
  512809: '短信验证码错误',
  512810: '报名数据缺失',
  512811: '题目选项必须为一个json串',
  512812: '题目禁止删除',
  512813: '已经添加过隐私协议',
  512814: '该手机已报名',
  512815: '用户已报名,无需重复报名',
  512816: '手机题目信息缺失',
  512817: '未报名',
  512818: '报名数据格式错误,答案录入失败.',
  512819: '预报名excel内容错误.',
  512900: '裁剪时间列表参数错误',
  512901: '转码中，请稍等',
  512902: '请重试',
  512903: '活动日志信息创建失败',
  512904: '活动日志信息修改失败',
  512905: '点播不能取消点默认',
  512906: '请先开始录制，再请求该"',
  513040: '超出严禁词数量限制',
  513041: '操作失败',
  513042: '严禁词已被删除或不存在',
  513043: '严禁词已存在',
  513044: '操作失败',
  513046: '跑马灯水印信息不存在',
  513047: '当前活动不属于当前用户',
  513048: '操作失败',
  513049: '开启水印时图片不能为空',
  513070: '创建失败',
  513071: '更新失败,推广信息不存在',
  513072: '删除失败,推广信息不存在',
  513073: '广告推荐个数已达到最大个数限制，请删除后再进行添加',
  513074: '广告推荐个数已达到最大个数限制，请删除后再进行添加',
  513075: '添加失败',
  513004: '用户加入房间失败',
  513001: '直播过程中禁止调用',
  513002: '直播未开始禁止调用',
  513003: '禁止操作',
  513000: '参会用户不存在',
  513020: '用户无权限',
  513008: '主持人信息缺失',
  513009: '房间不是直播活动',
  513010: '房间已存在',
  513011: '当前参会用户未加入直播间',
  513012: '只有主持人拥有此权限',
  513013: '只有创建者拥有此权限',
  513014: '只有嘉宾允许操作',
  513015: '普通观众无此权限',
  513016: '问答必须处于开启状态',
  513050: '菜单类型与状态不匹配',
  513051: '邀请榜功能组件只能添加一次',
  513052: '一个菜单下最多添加10个图文组件',
  513053: '一个页面内最多添加50个直播',
  513054: '一个页面内最多添加50个专题',
  513055: '不合法的组件数据',
  513057: '自定义菜单数最多为6个',
  513021: '房间不存在',
  513022: '您参与的直播不是当前直播',
  513005: '删除失败',
  513006: '更新失败',
  513007: '房间不存在',
  513026: '当前用户未申请上麦',
  513027: '当前用户没有参会',
  513028: '当前用户已经被邀请',
  513029: '重复上麦',
  513033: '用户未上麦',
  513023: '暂未开放举手',
  513024: '角色不符',
  513030: '没有被邀请',
  513031: '主持人不需要上麦',
  513034: '当前用户无法上麦',
  513017: '音频活动不能使用互动连麦',
  513035: '只有主持人可以对自己下麦',
  513076: '上架失败',
  513077: '下架失败',
  513078: '复制失败',
  513079: '复制商品不存在',
  513080: '商品最大上架数量为:100',
  513081: '添加失败',
  513082: '更新失败',
  513083: '图片保存失败',
  513084: '删除失败,下架后才能删除',
  513085: '删除失败,图片不存在',
  513086: '设置失败,图片不存在',
  513087: '创建失败',
  513088: '更新失败',
  513089: '更新状态失败',
  513090: '创建失败',
  513091: '更新失败',
  513092: '操作失败',
  513093: '保存失败',
  513094: '未开启',
  513095: '创建邀请失败',
  513096: '创建邀请失败，活动ID不匹配',
  513097: '文件地址错误',
  513098: '导出失败',
  513099: '红包金额必须大于零数字',
  513100: '活动不存在',
  513112: '当前用户无权限',
  513280: '未获取到商品详情',
  513281: '折扣价不能大于等于原价',
  513282: '非法的图片ID',
  513283: '商品不存在或已下架',
  513284: '封面必须存在图片中',
  513285: '商品已上架，如需编辑请先做下架处理',
  513200: '发起签到失败！',
  513201: '签到时长不能为空！',
  513202: '是否自动签到不能为空！',
  513203: '签到时间间隔不能为空！',
  513204: '当前活动已签到中！',
  513205: '签到已结束',
  513101: '资料库中已存在该文档',
  513102: '当前活动中已存在该文档',
  513103: '同步到资料库失败',
  513104: '同步到活动失败',
  513105: '当前要同步文档不存在',
  513106: '文档删除异常',
  513107: '当前活动中文档不存在',
  513108: 'document参数不正确',
  513109: '文档已经删除或者不存在',
  513110: '当前文档归属不正确',
  513111: '请求文档不存在',
  513220: '点赞数据更新失败',
  513121: '问题不存在',
  513122: '请勿重复处理',
  513123: '答案不存在',
  513124: '房间开始时间缺失',
  513125: '问题不属于该房间.',
  513126: '提问速度过快,提问接口限制提问速度',
  513230: '分享类型错误',
  513231: '分享失败',
  513232: '分享失败',
  513241: '当前用户无权限操作此房间',
  513240: '超过自定义礼物个数限制',
  513242: '礼物信息不存在或者已删除！',
  513243: '系统礼物不能删除',
  513244: '只能删除自己创建的礼物',
  513245: '创建失败',
  513246: '编辑失败',
  513247: '礼物信息不存在',
  513248: '系统礼物不能编辑',
  513249: '你无权编辑此礼物信息',
  513250: '超过活动使用礼物数量限制',
  513251: 'gift_ids格式错误',
  513532: '不能使用互动连麦相关功能',
  513533: '无效的参会token',
  513534: '活动类型不匹配,禁止操作',
  513535: '未获取到主持人信息',
  513536: '活动不匹配',
  513537: '当前用户无权限',
  513538: '未获取参会用户信息',
  513540: '没有数据可以导出',
  513539: '导入文件时上传失败',
  513544: '请上传素材',
  513541: '文件导出错误',
  513542: '参会角色权限等级低',
  513543: '请联系管理员开通权限',
  513546: '获取key不能为空',
  513547: 'open_id获取失败',
  514000: '邮箱一次最多1000',
  514001: '邮件地址无效',
  514002: '缺少查询ID',
  514003: '缺少下载地址',
  514004: '当前上传类型，含有不被允许类型',
  514005: '当前上传类型不被允许',
  514006: '上传路径中含有特殊字符',
  514007: '上传文件不存在',
  514008: '上传文件不能超过4M',
  514009: '结束时间不能小于开始时间',
  514010: '时间格式不正确',
  514011: '邮箱发送失败',
  514012: '下载中心生成缺少必要参数',
  514013: '重新生成URL请带上https!',
  514021: '创建失败',
  514022: '修改失败',
  514061: '没有正在使用的版本',
  514062: 'system_type系统类型不能为空',
  511001: '图形验证码错误',
  511002: '动态密码错误',
  511003: '账号密码错误！如连续输错4次，您的账号将被锁定',
  511004: '您的账号由于不合规操作，已被封停！',
  511005: '用户Token缺失',
  511006: '用户Token认证失败',
  511007: '用户Token已过期',
  511008: '数据不存在',
  511009: '验证码错误',
  511010: '用户注册中心注册失败',
  511011: '用户已存在',
  511012: '上一步操作结果，验证失败',
  511013: '旧密码不能为空',
  511014: '旧密码错误',
  511015: '密码输入不一致',
  511016: '手机号格式错误',
  511017: '邮箱格式错误',
  511018: '验证码错误',
  511019: '发送验证码失败',
  511020: '子账号没有权限操作',
  511021: '不是子账号',
  511022: '该手机号已被其他账号绑定，请使用其他手机号',
  511023: '该邮箱已被其他账号绑定，请使用其他邮箱',
  511024: '请勿短时间内重复请求',
  511025: '上传头像异常',
  511026: '当前第三方用户已创建,密码数据不一致,请使用更新接口修改数据',
  511027: '当前用户已在SAAS平台上删除, 请先恢复',
  511028: '第三方用户对象不存在',
  511029: '超过子账号个数限制',
  511030: '角色id不正确，非主账号创建的',
  511031: '子账号没有权限创建账号',
  511032: '密码已存在，不允许直接设置',
  511033: '没有权限访问',
  511034: '无效的平台',
  511035: '只能修改自己账号下的数据',
  511036: '用户存在',
  511037: '上传图片过大',
  511038: '上传文件类型不支持',
  511039: '当前账号已绑定，请先解绑',
  511040: '批量创建子账号,数量不能为空',
  511041: '第三方账号信息缺失，请重新扫描',
  511042: '已被其他账号绑定',
  511043: '接口访问次数达到最大值限制',
  511044: '数据签名错误',
  511045: '数据异常，当前登录用户不存在',
  511046: '签名参数缺失',
  511047: '权限key不存在',
  511048: '子账号关系错误',
  511049: '修改菜单排序失败',
  511050: '邮箱不符合要求，必须是公司邮箱！',
  511051: '封号原因不能为空',
  511052: '当前用户没有该增值API，请联系客服',
  511053: '当前账号未绑定，无须解绑',
  511054: '账号所属关系错误',
  511055: '手机号未注册',
  511056: '你不是员工',
  511057: '请输入正确的验证码',
  511058: '无效的Token，平台参数错误',
  511059: '不允许对非会员或者已过期会员添加该配置项',
  511060: '无效的回调事件',
  511061: '子账号数量为1~200个！',
  511062: '该账号为子账号，不能开通子账号权限！',
  511063: '子账号直播中，不允许删除',
  511064: '子账号有收益，不允许删除',
  511065: '请求已过期，请重新发起!',
  511066: '数据签名错误!',
  511501: '数据不存在',
  511502: '数据已存在',
  511503: '数据库错误',
  511504: '权限分类id不能为空',
  511505: '账号被禁言',
  511506: 'SSO注册失败',
  511507: '注册失败',
  511508: '权限使用中，不允许删除子账号权限',
  511509: '超过app最大数量限制',
  511510: '非api用户不允许创建应用',
  511511: '权限ID异常',
  511512: '用户不存在',
  511513: '与已有员工邮箱重复，请重新输入！',
  511514: '与已有员工姓名重复，请重新输入！',
  511515: '有关联用户，不能删除',
  511516: '只能修改自己数据',
  511521: '当前api_url不正确',
  511522: '当前用户没有该增值API，请联系客服',
  511523: '请确保当前API对应地址唯一性',
  511524: 'ID不存在',
  511525: '用户API权限没有开通',
  511528: '该用户已配置该接口！',
  511529: '修改菜单排序失败',
  516000: '未知错误',
  516001: '非法访问',
  516002: '用户不存在',
  516003: '权限验证失败',
  516004: '系统忙，请稍后再试',
  516005: '签名校验失败',
  516006: '活动不存在',
  516007: '当前接口30秒内只能访问一次！',
  516101: '当前套餐剩余有效时间不满一个月，不支持升级',
  516103: '删除订单失败!',
  516104: '无限包无法购买其他套餐',
  516105: '用户类型错误',
  516106: '升级并发必须大于当前并发',
  516107: '存在未生效并发包',
  516108: '您有待处理订单，暂不能新增',
  516109: '当前无可升级套餐，请联系客户经理购买',
  516110: '购买流量数量错误，请重新输入',
  516111: '套餐类型错误',
  516112: '您没有欠费',
  516113: '扩展包人数输入有误',
  516114: '欠费类型错误',
  516115: '订单不存在',
  516117: '处理支付回调失败，签名错误',
  516118: '订单状态异常',
  516119: '子账号不能操作',
  516120: '该账号为子账号，不能新增套餐/资源',
  516121: '请先清零欠费',
  516123: '请先清零回放流量欠费',
  516126: '此账号为父账号，无法新增无极包！',
  516127: '分配量不能超过可用量！',
  516145: '并发人数范围为1至99999人',
  516149: '操作人ID不存在',
  516150: '新增付费用户失败',
  516151: '订单类型错误',
  516200: '活动不存在或不是h5活动',
  516300: '请先绑定手机号',
  516301: '验证码校验失败，请重试',
  516302: '并发预充值套餐无法购买扩展包',
  516303: '含有子账号的账号无法购买扩展包',
  516304: '订单已过期',
  516305: '短信验证码校验失败',
  516306: '请先绑定微信',
  516307: '提现金额不能小于1元',
  516308: '提现金额不能大于余额',
  516309: '流量用户欠费超过100G，暂不能进入',
  516310: '并发到期，暂不能进入',
  516311: '并发用户超过并发限制，暂不能进入',
  516312: '您有进行中的提现，无法再次提现',
  516313: '超过线上单笔最大提现额度，请走线下提现流程',
  516314: '请求下载中心失败',
  516315: 'OSS上传失败',
  516316: '导出文件生成失败',
  516317: '批量初始化子账号付费会员异常',
  516318: '超出可分配资源',
  516319: '子账号分配资源操作异常',
  516320: '存在不属于父账号的子账号',
  516321: '尊敬的会员，您的流量有欠费，请充值',
  516322: '尊敬的会员，您的流量已用尽，请充值',
  516323: '尊敬的会员，您有扩展包欠费，请充值',
  516324: '尊敬的会员，您的并发套餐已到期',
  516325: '订单生成失败',
  516326: '导出文件生成中',
  516327: '必填字段不能为空',
  516328: '查询订单支付详细信息失败',
  516500: '初始化套餐资源包只支持[流量包并发包并发预充值包]',
  516501: '资源包类型异常',
  516502: '当前用户为非付费用户，请先新增付费用户',
  516503: '用户套餐不存在或已失效',
  516504: '暂无有效的套餐，请先添加套餐',
  516505: '无极版只能添加回放流量包',
  516506: '资源包不合法',
  516507: '已存在冲突资源包',
  516508: '保存资源包异常',
  516509: '父账号不支持此资源包',
  516510: '添加套餐资源绑定关系异常',
  516511: '资源包有效时间不能超出套餐有效时间',
  516512: '套餐时间内已有冲突套餐',
  516513: '资源包与计费类型冲突',
  516514: '请选择正确的并发预充值包计算峰值',
  516515: '资源以及金额不能为负',
  516516: '登录状态失效',
  516517: '功能版本不存在',
  516524: '来源不存在',
  516518: '资源包不存在',
  516519: '只有无极版用户能添加',
  516520: '付费用户已存在',
  516521: '注册用户初始化试用套餐失败',
  516522: '付费会员不存在',
  516523: '该用户不是子账号',
  516604: '奖品创建/保存失败',
  516605: '当source为0时房间号/活动号必填',
  516606: '活动不存在或已删除',
  516607: '调用外部接口错误',
  516701: '上传的文件不存在或者错误',
  516702: '抽奖页还没有设置',
  516703: '抽奖已结束',
  516708: '您已参与过了',
  516709: '抽奖类型错误',
  516710: '抽奖进行中,无法再次发起抽奖',
  516711: '口令抽奖时口令不能为空',
  516712: '预设中奖用户校验未通过',
  516713: '您的角色不能发起抽奖',
  516714: '你的角色操作非法',
  516715: '设置的中奖人数不能大于可中奖人数',
  516716: '参数验证失败请检查',
  516719: '获取用户微信openid失败',
  516726: '奖品数量不能大于20',
  516720: '此活动无需付费',
  516721: '您无此权限',
  516722: '抽奖不存在',
  516723: '未查询到中奖信息',
  516727: '活动ID不能为空',
  516801: '获取PAAS票据失败',
  516802: '房间不匹配',
  516803: '无效的问卷',
  516804: '无此操作权限',
  516805: '已参与过，提交失败',
  516806: '表单拷贝失败',
  516807: '房间下问卷个数太多',
  516808: '用户未参会',
  516809: '用户已答问卷',
  516810: '回答参数异常',
  516811: '该用户不存在',
  516812: '房间id参数错误',
  516813: '鉴权异常',
  516814: '子账号信息不存在',
  516815: '该活动不是父账号下子账号的活动',
  516816: '未找到此问卷，请稍后重试',
  516817: '未找到此问卷对应的题目，请稍后重试',
  516820: '提现金额不能小于2元',
  516821: '请上传申请单照片',
  516822: '上传的申请单照片格式错误',
  516823: '该用户正有一笔线下提现正在添加中',
  516824: '添加申请提现金额不能大于用户账户余额',
  516825: '用户账户余额小于0元',
  516826: '发送短信校验码失败',
  516827: '线下提现类型只能为直播和红包收益',
  516828: '有一笔订单正在处理中,请处理后再添加新的线下提现',
  516829: '提现,转账失败',
  517221: '资源参数数值异常(流量需为正数&并发需为正整数)',
  513561: '请输入正确的小组号',
  513562: '分组标人数达到2000上限!'
};

export default errorMap;
