import Layout from '@/layout/index';

const router = [
  {
    path: '/material',
    component: Layout,
    redirect: '/material/word',
    meta: { auth: true, title: '媒资管理', name: 'Material', icon: 'saasicon_datas_img', level: 1 },
    children: [
      {
        path: 'word',
        component: () => import('@/views/MaterialModule/word'),
        meta: { auth: true, title: '文档', name: 'wordMgr' }
      },
      {
        path: 'video',
        component: () => import('@/views/MaterialModule/video'),
        meta: { auth: true, title: '音视频', name: 'videoMgr' }
      }
    ]
  }
];
export default router;
