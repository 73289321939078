/**
 * 全局自定义指令
 */
import Vue from 'vue';
import { preventReClick } from './preventReClick';
// import { clickoutside } from './clickoutside';
import { parseQueryString } from '@/utils/utils';
import regRule from '@/utils/reg-rule.js';

function parseImgHandler(el, binding, vnode) {
  // console.log('-----drag - bind-----', el, binding, vnode);
  const type = binding.value?.type || 'img';
  const url = binding.value?.url;
  let mode = binding.value?.default || 1;
  if (url && regRule.url.test(url)) {
    let obj = parseQueryString(url);
    mode = Number(obj.mode) || 1;
  }
  if (type == 'img') {
    if (mode == 1) {
      el.style['object-fit'] = 'fill';
    }
    if (mode == 2) {
      el.style['object-fit'] = 'cover';
      el.style['object-position'] = 'left top';
    }
    if (mode == 3) {
      el.style['object-fit'] = 'contain';
      el.style['object-position'] = 'center';
    }
  }
  if (type == 'bg') {
    if (mode == 1) {
      el.style['background-size'] = '100% 100%';
    }
    if (mode == 2) {
      el.style['background-size'] = 'cover';
      el.style['background-position'] = 'left top';
    }
    if (mode == 3) {
      el.style['background-size'] = 'contain';
    }
  }
}

export const parseImgOss = {
  name: 'parseImgOss',
  option: {
    bind(el, binding, vnode) {
      parseImgHandler(el, binding, vnode);
    },
    inserted(el, binding, vnode) {
      // console.log('-----drag - inserted-----', el, binding, vnode);
    },
    update(el, binding, vnode, oldVnode) {
      parseImgHandler(el, binding, vnode);
    },
    unbind(el, binding, vnode) {
      // console.log('-----drag - unbind-----', el, binding, vnode);
    }
  }
};
Vue.directive(parseImgOss.name, parseImgOss.option);
Vue.directive(preventReClick.name, preventReClick.option);
// Vue.directive(clickoutside.name, clickoutside.option);

/**
 * 让tooltip快速消失
 */
Vue.directive('tooltipMove', {
  inserted: (el, binding) => {
    el.addEventListener('mouseout', () => {
      let toolTipAll = document.getElementsByClassName('vh-tooltip__popper');
      if (toolTipAll && toolTipAll.length > 0) {
        for (const item of toolTipAll) {
          item.style.display = 'none';
        }
      }
    });
  }
});

/**
 * 表格滚动加载
 */
Vue.directive('loadMore', {
  bind: (el, binding) => {
    let targetDom =
      el.querySelector('.vh-table__body-wrapper') ||
      el.querySelector('.vh-scrollbar__wrap') ||
      el.querySelector('.vh-tabs__content');
    targetDom.addEventListener('scroll', function () {
      let scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
      if (!parseInt(scrollDistance)) {
        binding.value();
      }
    });
  }
});

/**
 * 禁止输入表情
 * @param {*} Vue
 */
Vue.directive('clearEmoij', {
  bind: (el, binding, vnode, oldVnode) => {
    let regex =
      /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2123-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030|(^\s*)/gi;
    const obj = el.querySelectorAll('.vh-input__inner,.vh-textarea__inner')[0];
    let zclearNoNum = function (e) {
      if (e.target.composing) return;
      let match = regex.exec(obj.value);
      if (match) {
        obj.value = obj.value.replace(
          /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2123-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030|(^\s*)/gi,
          ''
        );
        zclearNoNum(e);
      }
      // 触发v-model的更新
      obj.dispatchEvent(new Event('input'));
    };
    let zblur = function (e) {
      setTimeout(() => {
        let match = regex.exec(obj.value);
        if (match) {
          obj.value = obj.value.replace(
            /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2123-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030|(^\s*)/gi,
            ''
          );
        }
        // 触发v-model的更新
        obj.dispatchEvent(new Event('input'));
      }, 50);
    };
    obj.oninput = zblur;

    function onCompositionStart(e) {
      e.target.composing = true;
    }
    function onCompositionEnd(e) {
      e.target.composing = false;
      obj.dispatchEvent(new Event('input'));
    }
    obj.addEventListener('compositionstart', onCompositionStart);
    obj.addEventListener('compositionend', onCompositionEnd);
  },
  update(el, binding, vnode, oldVnode) {}
});

/**
  解决vhall-ui组件aria-hidden属性被错误地使用的问题：
  Blocked aria-hidden on a <input> element because the element that just received focus must not be hidden from assistive technology users. Avoid using aria-hidden on a focused element or its ancestor. Consider using the inert attribute instead, which will also prevent focus. For more details, see the aria-hidden section of the WAI-ARIA specification at https://w3c.github.io/aria/#aria-hidden.
  使用：
  <el-radio-group  v-removeAriaHidden>
      ...
  </el-radio-group>
 */
Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.vh-radio__original');
    ariaEls.forEach(item => {
      item.removeAttribute('aria-hidden');
    });
  }
});
