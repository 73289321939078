<template>
  <div class="file-download-wrapbox">
    <div class="file-download-editor-box">
      <template>
        <div class="file-download-editor-item condition">
          <label class="line-title">选择风格</label>
          <vh-radio-group v-model="editVo.style">
            <vh-radio label="list">列表风格</vh-radio>
            <vh-radio label="card">卡片风格</vh-radio>
          </vh-radio-group>
        </div>
      </template>
      <template>
        <div class="file-download-editor-item condition box-adapter">
          <label class="line-title">下载条件</label>
          <div style="flex: 1; width: 0">
            <vh-radio-group v-model="editVo.where">
              <vh-radio label="0">无条件</vh-radio>
              <vh-radio label="1">
                指定表单
                <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                  <div slot="content">
                    1.该表单通过「问卷」功能进行制作；
                    <br />
                    2.若用户已填写报名表单，则其无需重复填写可直接下载
                  </div>
                  <i class="iconfont-v3 saasicon_help_m icon__max_show"></i>
                </vh-tooltip>
              </vh-radio>
              <vh-radio label="3" class="file-download-editor-item__login">登录</vh-radio>
              <vh-radio label="2">
                设置观看时长
                <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                  <div slot="content">
                    1.用户观看视频进行暂停后，再次点击进行观看，观看时长不会受到影响，继续正常计算
                    <br />
                    2.用户进行倍速播放时，不影响观看时长
                  </div>
                  <i class="iconfont-v3 saasicon_help_m icon__max_show"></i>
                </vh-tooltip>
              </vh-radio>
            </vh-radio-group>
            <br />
            <vh-checkbox
              v-if="editVo.where == 1"
              v-model="editVo.form_allow_download"
              :true-label="1"
              :false-label="0"
            >
              观众已填写报名表单，则无需重复填写问卷即可下载
            </vh-checkbox>
          </div>
        </div>
        <template v-if="editVo.where == 1">
          <template v-if="editVo.where_content">
            <div class="file-download-editor-item">
              <label class="line-title"></label>
              <div class="editor-item-level2 has-data">
                <div class="q_info" @click="editCheckQuestion">
                  <span class="q_txt">{{ editVo.where_title }}</span>
                  <i class="vh-icon-refresh-left"></i>
                </div>
              </div>
            </div>
          </template>
          <div class="file-download-editor-item" v-else>
            <label class="line-title"></label>
            <span class="editor-item-level2" @click="openDialog('question')">
              <i class="vh-icon-plus"></i>
              选择问卷
            </span>
          </div>
        </template>
        <template v-if="editVo.where == 2">
          <div class="file-download-editor-item">
            <label class="line-title"></label>
            <vh-radio-group v-model="editVo.where_watch_duration">
              <vh-radio :label="5">5分钟</vh-radio>
              <vh-radio :label="10">10分钟</vh-radio>
              <vh-radio :label="15">15分钟</vh-radio>
              <vh-radio :label="-1">
                <vh-input
                  v-model="editVo.timeNum"
                  maxlength="3"
                  placeholder="1-300"
                  @input="inputCount"
                >
                  <template slot="append">分钟</template>
                </vh-input>
              </vh-radio>
            </vh-radio-group>
          </div>
        </template>
      </template>
      <template>
        <div class="file-download-editor-item">
          <label class="line-title">选择文件</label>
          <div v-if="editVo.data && editVo.data.length > 0">
            <vh-button
              icon="vh-icon-circle-plus-outline"
              type="text"
              round
              @click="openDialog('doc')"
            >
              添加文件
            </vh-button>
          </div>
        </div>
        <div class="file-download-editor-list">
          <vh-table
            class="img-table"
            ref="docTable"
            :data="editVo.data"
            tooltip-effect="dark"
            style="width: 100%"
            row-key="file_id"
            :height="500"
            v-if="editVo.data && editVo.data.length > 0"
          >
            <vh-table-column label="序号" width="70">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </vh-table-column>

            <vh-table-column label="封面图" width="110" v-if="editVo.style == 'card'">
              <template slot-scope="scope">
                <icon
                  class="word-status"
                  :icon-class="(scope.row.ext || scope.row.file_ext) | wordStatusCss"
                  v-if="editVo.style == 'list'"
                ></icon>
                <img
                  v-else
                  class="cover_img"
                  :src="scope.row.img_info.img_url || file_default"
                  :key="scope.row.img_info.img_url || file_default"
                  v-parseImgOss="{ url: scope.row.img_info.img_url, default: 1 }"
                />
              </template>
            </vh-table-column>
            <vh-table-column label="文件名称" show-overflow-tooltip>
              <template slot-scope="scope">
                <p class="text">
                  <icon
                    class="word-status"
                    :icon-class="(scope.row.ext || scope.row.file_ext) | wordStatusCss"
                    v-if="editVo.style == 'list'"
                  ></icon>
                  {{ scope.row.file_name }}
                </p>
              </template>
            </vh-table-column>
            <vh-table-column label="文件大小" width="100">
              <template slot-scope="scope">
                {{ scope.row.file_size || scope.row.size }}
              </template>
            </vh-table-column>
            <vh-table-column label="操作" width="110">
              <template slot-scope="scope">
                <vh-tooltip
                  class="item"
                  effect="dark"
                  content="编辑"
                  placement="top"
                  v-tooltipMove
                  v-if="editVo.style == 'card'"
                >
                  <i
                    class="iconfont-v3 saasicon-pencil1 ctrl_btn"
                    @click.prevent.stop="editDocImage(scope.row)"
                  ></i>
                </vh-tooltip>
                <vh-tooltip class="item" effect="dark" content="删除" placement="top" v-tooltipMove>
                  <i
                    class="iconfont-v3 saasicon-trash ctrl_btn"
                    @click.prevent.stop="deleteDoc(scope.$index)"
                  ></i>
                </vh-tooltip>
                <vh-tooltip class="item" effect="dark" content="移动" placement="top" v-tooltipMove>
                  <i class="iconfont-v3 saasicon_move ctrl_btn"></i>
                </vh-tooltip>
              </template>
            </vh-table-column>
          </vh-table>
          <null-page :nullType="'nullData'" text="暂无文件，快来添加吧！" v-else :height="60">
            <vh-button type="primary" round @click="openDialog('doc')">添加文件</vh-button>
          </null-page>
        </div>
      </template>
    </div>
    <!-- 选择指定表单（问卷） - 弹窗 -->
    <check-question
      ref="checkQuestionDom"
      :maxCheckedNum="1"
      :selected="[editVo.where_content]"
      @save="setWhereContent"
    ></check-question>
    <!-- 选择（文档） - 弹窗 -->
    <check-docs
      ref="checkDocDom"
      :selected="selectedDos"
      :maxCheckedNum="50"
      @save="setDocData"
    ></check-docs>
    <!-- 编辑文档封面 & 简介-->
    <edit-doc-file-image ref="checkEditDocDom" @save="setDocItemImage"></edit-doc-file-image>
  </div>
</template>
<script>
  import Sortable from 'sortablejs';
  import CheckDocs from '@/components/Doc/checkDoc';
  import EditDocFileImage from '@/components/Doc/editDocFileImage';
  import CheckQuestion from '@/components/Question/checkQuestion';
  import NullPage from '@/views/PlatformModule/Error/nullPage';
  export default {
    props: {
      menuInfo: {
        required: true
      },
      units: {
        required: true
      },
      pre: {
        required: false // 内部无用
      }
    },
    components: {
      CheckDocs,
      CheckQuestion,
      NullPage,
      EditDocFileImage
    },
    data() {
      return {
        editVo: {
          style: 'list', // 风格：list-列表；card-卡片。
          where: '0', // 0-无条件；1-指定表单
          where_content: null, // 指定表单内容
          where_title: '',
          data: [],
          where_watch_duration: 5,
          form_allow_download: 1,
          timeNum: ''
        },
        checkQuestions: [],
        checkDocs: [],
        srcList: [],
        curEditFile: {},
        file_default: '//cnstatic01.e.vhall.com/common-static/images/file_default.png'
      };
    },
    computed: {
      selectedDos() {
        return this.editVo.data.map(item => {
          return item.file_id;
        });
      }
    },
    created() {
      this.editVo = this.units;
      if (!this.editVo.where_watch_duration) {
        this.editVo.where_watch_duration = 5;
      } else {
        this.editVo.where_watch_duration = parseInt(this.editVo.where_watch_duration + '');
      }
      if (
        this.editVo.where_watch_duration == 5 ||
        this.editVo.where_watch_duration == 10 ||
        this.editVo.where_watch_duration == 15
      ) {
        this.editVo.timeNum = '';
      } else if (this.editVo.where_watch_duration == -1) {
      } else {
        this.editVo.timeNum = this.editVo.where_watch_duration;
        this.editVo.where_watch_duration = -1;
      }
      console.log('当前数据情况-合并后', JSON.stringify(this.units));
    },
    mounted() {
      this.tagListDrop();
    },
    methods: {
      // 开启弹窗
      openDialog(key) {
        let formName = key.charAt(0).toUpperCase() + key.slice(1);
        this.$refs[`check${formName}Dom`].dialogVisible = true;
      },
      // 设置选中的问卷
      setWhereContent(questions) {
        if (questions.length < 1) {
          return;
        }
        let ids = questions.map(item => item.question_id);
        this.editVo.where_content = ids.join(',');
        this.editVo.where_title = questions[0].title;
      },
      // 删除选中的问卷
      delCheckQuestion(index) {
        this.checkQuestions.splice(index, 1);
        console.log('删除后，问卷集合', this.checkQuestions);
        this.setWhereContent(this.checkQuestions);
      },
      // 替换选中的问卷
      editCheckQuestion() {
        this.openDialog('question');
      },
      // 设置选中的文档集合
      setDocData(docList) {
        if (this.checkDocs.length == 0 && docList.length > 0) {
          this.$nextTick(() => {
            this.tagListDrop();
          });
        }

        this.checkDocs = docList.map(item => {
          item.file_type = 0;
          item.file_id = item.document_id;
          item.file_desc = '';
          item.img_url = this.covertDefaultImgUrl(item.ext) || '';
          item.file_ext = item.ext;

          return item;
        });
        this.editVo.data = this.checkDocs.map(item => {
          const data = {
            file_name: item.file_name,
            size: item.size,
            file_type: item.file_type,
            file_id: item.document_id,
            file_desc: item.file_desc,
            file_ext: item.file_ext,
            file_size: item.format_size,
            img_info: { img_url: '' }
          };
          const exist = this.editVo.data.find(el => {
            return el.file_id == item.document_id;
          });
          if (exist) {
            data.img_info = exist.img_info;
            data.file_desc = exist.file_desc;
          }

          return data;
        });
      },
      // 设置默认图片
      covertDefaultImgUrl(ext) {
        return 'https://t-alistatic01.e.vhall.com/upload/users/logo-imgs/ba/e1/bae13a3f7402fded318e89b1b45050b3.jpg';
      },
      // 删除单条文档记录
      deleteDoc(index) {
        this.$confirm('删除后将会影响观众下载，确认删除？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box zdy-vh-ui',
          lockScroll: false,
          roundButton: true,
          confirmButtonClass: 'zdy-vh-ui-confirm',
          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            this.editVo.data.splice(index, 1);
          })
          .catch(() => {});
      },
      // 编辑封面图
      editDocImage(item) {
        this.openDialog('editDoc');
        this.curEditFile = item;
        this.$nextTick(() => {
          this.$refs.checkEditDocDom && this.$refs.checkEditDocDom.setDefault(item);
        });
      },
      // 编辑封面图，数据保存
      setDocItemImage(vo) {
        console.log('编辑封面图，数据保存', vo);
        this.curEditFile.file_desc = vo.file_desc;
        this.curEditFile.img_info.img_url = vo.img_url;
      },
      tagListDrop() {
        const tbody = document.querySelector('.vh-table__body-wrapper tbody');
        const _this = this;
        // console.log(tbody);
        tbody &&
          Sortable.create(tbody, {
            handle: '.saasicon_move',
            animation: 500,
            onMove() {
              // console.log('1111');
            },
            onEnd(ev) {
              // console.log('22222');
              const { newIndex, oldIndex } = ev;
              const currRow = _this.editVo.data.splice(oldIndex, 1)[0];
              _this.editVo.data.splice(newIndex, 0, currRow);
              // const current_id = _this.list[newIndex].id;
              // const new_above_id = _this.list[newIndex - 1]?.id;
              // const new_below_id = _this.list[newIndex + 1]?.id;
            }
          });
      },
      inputCount(value) {
        this.editVo.timeNum = value.replace(/[^\d]+/, '');
        if (parseInt(this.editVo.timeNum + '') > 300) {
          this.editVo.timeNum = 300;
        }
      }
    }
  };
</script>
<style lang="less" scoped>
  .vh-customer__preview-pc {
    .file-download-wrapbox {
      margin-bottom: 20px;
    }
  }

  .cover_img {
    width: 36px;
    height: 51px;
    display: block;
    border-radius: 4px;
  }
  .ctrl_btn {
    font-size: 20px;
    color: #3562fa;
    margin-right: 10px;
    cursor: pointer;
  }
  .file-download-previewbox {
  }
  .file-download-editor-box {
    padding-top: 10px;
    .text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .word-status {
      margin-right: 5px;
      ::v-deep i {
        font-size: 18px;
      }
    }
    .file-download-editor-list {
      ::v-deep .vh-table::before {
        height: 0;
      }
    }
    .file-download-editor-item {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 24px;
      .line-title {
        margin-right: 12px;
        width: 56px;
        font-size: 14px;
        line-height: 20px;
        color: #262626;
      }
      .saasicon_help_m {
        margin-left: 5px;
        color: #999;
        &:hover {
          color: #666;
        }
      }
      .vh-input {
        width: 130px;
      }
    }
    .editor-item-level2 {
      width: 360px;
      height: 28px;
      background: rgba(0, 0, 0, 0.06);
      border: 1px dashed #d9d9d9;
      border-radius: 2px;
      text-align: center;
      line-height: 26px;
      color: #262626;
      font-size: 14px;
      cursor: pointer;
      &.has-data {
        border: none;
      }
      .q_info {
        box-sizing: border-box;
        padding: 0 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        i {
          margin-right: 10px;
        }
        .q_txt {
          flex: 1;
          width: 0;
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
</style>
<style lang="less">
  .file-download-wrapbox .file-download-editor-box .file-download-editor-item {
    &.condition {
      .vh-radio-group,
      .vh-radio,
      .vh-radio__label {
        line-height: 20px;
        display: flex;
        align-items: center;
      }

      .vh-radio-group {
        flex-wrap: wrap;
      }
    }
  }

  @media (max-width: 1920px) {
    .file-download-wrapbox .file-download-editor-box .file-download-editor-item {
      &.condition {
        &.box-adapter {
          margin-bottom: 44px;
        }
      }
    }
  }
</style>
