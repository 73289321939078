import Layout from '@/layout/index';

const router = [
  {
    path: '/eventhub/other',
    component: Layout,
    redirect: '/cms/other/msgList',
    meta: { auth: true, title: '辅助业务', name: 'other', icon: 'vh-icon-s-help' },
    hidden: true,
    children: [
      {
        path: 'msgList',
        component: () => import('@/views/PlatformModule/Message/list'),
        meta: { auth: true, title: '消息中心', name: 'msgList', icon: 'table' }
      },
      {
        path: 'msgDetail/:str(\\d+)',
        component: () => import('@/views/PlatformModule/Message/detail'),
        meta: {
          auth: true,
          title: '消息详情',
          name: 'msgDetail',
          icon: 'table',
          activeMenu: '/other/msgList'
        },
        hidden: true
      },
      {
        path: 'downloadList',
        component: () => import('@/views/PlatformModule/download'),
        meta: { auth: true, title: '下载中心', name: 'downloadList', icon: 'table' }
      },
      {
        path: 'info2',
        component: () => import('@/views/AccountModule/info'),
        meta: { title: '账户信息', name: 'accountInfo' }
      }
    ]
  },
  {
    path: '/eventhub/acc',
    component: Layout,
    redirect: '/eventhub/acc/info',
    hidden: true,
    meta: {
      auth: true,
      title: '账户与财务',
      name: 'accountMgr',
      icon: 'saasicon_account_img'
    },
    children: [
      {
        path: 'info',
        component: () => import('@/views/AccountModule/info'),
        meta: { auth: true, title: '账户信息', name: 'accountInfo', activeMenu: '/acc/info' }
      }
    ]
  },
  {
    path: '/datahub/site',
    name: 'cms',
    component: Layout,
    hidden: true,
    meta: {
      title: '站点分析',
      name: 'Datahub',
      icon: 'saasicon_datahub_user_operation_img'
    },
    children: [
      {
        path: '/datahub/site/:id',
        // component: () => import('@/views/DataHub/SiteAnalysis/index.vue'),
        component: () => import('@/views/DataHub/index.vue'),
        meta: {
          auth: true,
          title: '站点分析',
          activeMenu: '/cms/website'
        }
      }
    ]
  }
];
export default router;
