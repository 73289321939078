import Layout from '@/layout/embedLayout';

const router = [
  {
    path: '/embed/other',
    component: Layout,
    meta: { auth: true, title: '辅助业务', name: 'other' },
    hidden: true,
    children: [
      {
        path: 'downloadList/:id',
        component: () => import('@/views/PlatformModule/download'),
        meta: { auth: true, title: '下载中心', name: 'downloadList' }
      }
    ]
  }
];
export default router;
