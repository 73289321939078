module.exports = {
  language: {
    name: 'Eng'
  },
  message: {
    living: 'Live now',
    preView: 'Preview',
    end: 'End',
    playBack: 'Playback',
    views: ' views',
    original: 'Auto',
    video720: '720p',
    video480: '480p',
    video360: '360p',
    audio: 'Audio',
    loading: 'Loading',
    switchVideo: 'Switched successfully ',
    contentLoading: 'Coming soon',
    hostLeft: 'temporarily left the room',
    landscapeTips: 'Landscape mode',
    tip: 'Notification',
    confirm: 'Confirm',
    voicePlaying: 'Audio only...',
    speed: ' Speed',
    switchSpeed: 'Switched successfully',
    switchNormal: 'Switched successfully',
    day: ' Day ',
    hour: ' Hour ',
    minute: ' Min ',
    second: ' Sec',
    replay: 'Replay',
    liveEnd: 'End',
    speedFailed: 'Setting failed',
    sharpnessFailed: 'Setting failed',
    发送太频繁啦: 'Send too often, there are ',
    秒可以发言: ' seconds to speak',
    // --------白名单----------
    白名单验证: '白名单验证',
    请输入身份信息: '请输入身份信息',
    确认: '确认',
    活动不存在: '活动不存在',
    您已预约成功: '您已预约成功，',
    直播当天请访问此页面观看直播: '直播当天请访问此页面观看直播',
    预约成功: '预约成功',
    您的信息不在白名单中: '您的信息不在白名单中',
    没有白名单: '没有白名单',
    setHostTips: ' set to be the host',
    host: 'Host',
    compatabilityTipA:
      'Live Broadcast cannot be smoothly played in current browser, Chrome is recommended.',
    compatabilityTipB: '当前浏览器版本较低，请升级浏览器版本或使用Chrome浏览器',
    downloadBrowser: 'Download here',
    // saas-popup组件相关
    okText: 'OK',
    tipText: 'Remind',
    clickToPlay: 'Click and watch the Webinar',
    cancelText: 'Cancel',
    confirmText: 'Confirm',
    retryText: 'Retry',
    knowText: 'OK'
  }
};
