/*
 * @Description: store
 * @Author: yincece
 */

import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    // 开发者权限
    developer: {},
    permissionValue: {} //admin权限设置的值
  },
  mutations: {
    setDeveloper(state, developer) {
      state.developer = developer;
    },
    setPermissionValue(state, permissions) {
      state.permissionValue = permissions;
    }
  }
});
export default store;
