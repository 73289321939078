<template>
  <div class="page-padding">
    <pageTitle pageTitle="功能配置">
      <div class="title_text" v-if="isInWebinar">
        <p class="switch__box">
          <vh-switch
            v-model="functionOpen"
            active-color="#FB3A32"
            inactive-color="#CECECE"
            @change="closefunctionOpen"
            :active-text="reservationDesc"
          ></vh-switch>
          <span v-if="!isEmbed" @click="toSettingDetail">查看账号下功能配置</span>
        </p>
      </div>
    </pageTitle>
    <!-- 内容区域  -->
    <div class="plan-func-main">
      <div class="plan-func-form">
        <div class="div__func div__view" v-if="keyList.length > 0">
          <div class="div__view__title">观看页设置</div>
          <ul class="switch__list">
            <template v-for="(item, ins) in keyList">
              <!-- 弹窗位置 -->
              <li
                v-if="item.form_type == 'radio'"
                class="radio__box"
                :key="`view_1` + ins"
                v-show="item.isShow"
              >
                <label class="leve3_title label__r12">{{ item.key_name }}</label>
                <div>
                  <p class="setting_txt">{{ item.txt }}</p>
                  <vh-radio-group v-model="item.value" @change="changeStatus($event, item, 1)">
                    <vh-radio :label="0">居中</vh-radio>
                    <vh-radio :label="1">靠右</vh-radio>
                  </vh-radio-group>
                </div>
              </li>

              <!-- 打赏功能、点赞功能、礼物功能、分享功能、举报反馈 -->
              <li v-else class="switch__box" :key="`view_2` + ins" v-show="item.isShow">
                <label class="leve3_title label__r12">{{ item.key_name }}</label>
                <!-- TODO:支付牌照问题 -->
                <vh-switch
                  @click.native="handleSwitchClick(item)"
                  :disabled="item.disabled"
                  v-model="item.value"
                  :active-value="item.activeValue"
                  :inactive-value="item.inactiveValue"
                  active-color="#FB3A32"
                  inactive-color="#CECECE"
                  @change="changeStatus($event, item, 1)"
                ></vh-switch>
                <span class="leve3_title title--999">
                  {{ item.value != item.activeValue ? item.openShow : item.closeShow }}
                </span>

                <vh-checkbox
                  v-if="item.type === 'ui.watch_hide_like' && item.value === 0"
                  v-model="item.child.value"
                  :true-label="item.child.activeValue"
                  :false-label="item.child.inactiveValue"
                  @change="changeStatus($event, item.child, 1)"
                  class="watch-hide-like-num"
                >
                  隐藏点赞数据
                </vh-checkbox>
              </li>
            </template>
            <li class="radio__box" v-if="!isInWebinar">
              <label class="leve3_title label__r12">默认头像</label>
              <div class="radio_content">
                <p class="setting_txt">头像应用至聊天区、邀请卡、礼物、个人资料等位置</p>
                <vh-radio-group
                  v-model="avatarType"
                  @change="changeAvatar('system_default_avatar_switch', avatarType)"
                >
                  <vh-radio :label="0">系统默认</vh-radio>
                  <vh-radio :label="1">上传头像</vh-radio>
                </vh-radio-group>
                <upload
                  v-if="avatarType == 1"
                  class="upload__avatar"
                  ref="webinar_uploader"
                  v-model="defaultAvatar"
                  :domain_url="domain_url"
                  :saveData="saveImageData"
                  :widthImg="130"
                  :heightImg="130"
                  :on-success="UploadAvatarSuccess"
                  :on-error="uploadError"
                  :on-change="handleUploadChange"
                  @delete="deleteImage"
                  :auto-upload="false"
                >
                  <div slot="tip">
                    <p>建议尺寸：100*100px</p>
                    <p>小于2M(支持jpg、png、bmp)</p>
                  </div>
                </upload>
              </div>
            </li>

            <!-- 隐藏菜单 -->
            <li
              class="switch__box"
              v-for="(item, ins) in hideMenuList"
              :key="item.type"
              style="display: flex"
            >
              <label class="leve3_title label__r12" style="flex-shrink: 0">
                {{ item.key_name }}
              </label>
              <vh-switch
                v-model="item.value"
                :active-value="item.activeValue"
                :inactive-value="item.inactiveValue"
                active-color="#FB3A32"
                inactive-color="#CECECE"
                @change="changeStatus($event, item, 1)"
                style="margin-right: 5px"
              ></vh-switch>
              <span
                class="leve3_title title--999"
                style="white-space: nowrap"
                v-html="item.value == 1 ? item.closeShow : item.openShow"
              ></span>
              <vh-tooltip
                v-if="item.type == 'ui.hidden_webinar_state_and_mode'"
                effect="light"
                placement="right"
                :visible-arrow="false"
                v-tooltipMove
                popper-class="preview_status"
                class=""
              >
                <div slot="content" class="preview_status_tooltip">
                  <img
                    v-if="item.value == 1"
                    src="../../common/images/plan-function/hide_status.png"
                    alt=""
                  />
                  <img v-else src="../../common/images/plan-function/show_status.png" alt="" />
                </div>
                <i class="iconfont-v3 saasicon_help_m leve3_title title--999"></i>
              </vh-tooltip>
            </li>
          </ul>
        </div>
        <div class="div__func div__playback" v-if="liveKeyList.length > 0">
          <div class="div__view__title">回放设置</div>
          <ul class="switch__list">
            <li class="switch__box" v-for="(item, ins) in liveKeyList" :key="`playback_` + ins">
              <label class="leve3_title label__r12">{{ item.key_name }}</label>
              <vh-switch
                v-model="item.value"
                :active-value="1"
                :inactive-value="0"
                active-color="#FB3A32"
                inactive-color="#CECECE"
                @change="changeStatus($event, item, 2)"
              ></vh-switch>
              <span class="leve3_title title--999">
                {{ !!item.value ? item.openShow : item.closeShow }}
              </span>
            </li>
            <li class="switch__box record_expire_date" v-if="isInWebinar">
              <label class="leve3_title label__r12">回放有效期</label>
              <div class="record_expire_date_right">
                <div class="leve3_title title--999 record_expire_date_text">
                  <vh-switch
                    v-model="playbackExpirationDateObj.status"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#FB3A32"
                    inactive-color="#CECECE"
                    @change="playbackExpirationStatusChange"
                  ></vh-switch>
                  <span>
                    开启后，设置观看回放视频的有效期，到期后观众
                    <br />
                    无法观看回放视频
                  </span>
                  <vh-popover
                    :popper-class="'playback-expired-popover ' + switchType"
                    placement="right"
                    trigger="hover"
                  >
                    <div>
                      <img
                        v-show="switchType === 'pc'"
                        src="../../common/images/plan-function/pc_playback_expire.png"
                        width="420"
                        height="243"
                        alt=""
                      />
                      <img
                        v-show="switchType === 'app'"
                        src="../../common/images/plan-function/h5_playback_expire.png"
                        width="240"
                        height="504"
                        alt=""
                      />
                    </div>
                    <i
                      class="iconfont-v3 saasicon_help_m tip"
                      style="color: #999999"
                      slot="reference"
                    ></i>
                  </vh-popover>
                </div>
                <div class="record_expire_date_radio_box" v-if="playbackExpirationDateObj.status">
                  <vh-radio-group
                    class="record_expire_date_radio_group"
                    v-model="playbackExpirationDateObj.type"
                    @change="playbackExpirationDateTypeChange"
                  >
                    <vh-radio label="1">全天</vh-radio>
                    <vh-radio label="2">部分时段</vh-radio>
                  </vh-radio-group>
                  <div
                    class="record_expire_date_picker_box"
                    v-if="['2'].includes(playbackExpirationDateObj.type)"
                  >
                    <label class="leve3_title label__r12">选择时间</label>
                    <vh-time-picker
                      is-range
                      class="record_expire_date_picker"
                      v-model="playbackExpirationDateObj.timeRange"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围"
                      size="medium"
                      value-format="HH:mm:ss"
                      round
                      @change="timeRangeChange"
                    ></vh-time-picker>
                  </div>
                  <div
                    class="record_expire_date_picker_box"
                    v-if="['1', '2'].includes(playbackExpirationDateObj.type)"
                  >
                    <label class="leve3_title label__r12">选择日期</label>
                    <vh-date-picker
                      class="record_expire_date_picker"
                      :class="[
                        playbackExpirationDateObj.type === '1'
                          ? 'record_expire_date_time_picker'
                          : ''
                      ]"
                      :type="playbackExpirationDateObj.type === '2' ? 'daterange' : 'datetimerange'"
                      :value-format="
                        playbackExpirationDateObj.type === '2'
                          ? 'yyyy-MM-dd'
                          : 'yyyy-MM-dd HH:mm:ss'
                      "
                      :disabled="disabledPlaybackExpirationDateRange"
                      size="medium"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      round
                      v-model="playbackExpirationDateObj.dateRange"
                      @change="dateRangeChange"
                    ></vh-date-picker>
                  </div>
                  <div>
                    <vh-button
                      :disabled="disabledPlaybackExpirationBtn"
                      type="primary"
                      round
                      style="margin-top: 32px"
                      @click="playbackExpirationHandler"
                    >
                      保存回放有效期
                    </vh-button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="div__func" v-if="loginSettingList.length > 0">
          <div class="div__view__title">登录设置</div>
          <ul class="switch__list">
            <li
              class="switch__box"
              v-for="(item, ins) in loginSettingList"
              :key="`loginSetting_` + ins"
            >
              <label class="leve3_title label__r12">{{ item.key_name }}</label>
              <vh-switch
                v-model="item.value"
                :active-value="1"
                :inactive-value="0"
                active-color="#FB3A32"
                inactive-color="#CECECE"
                @change="changeStatus($event, item, 3)"
              ></vh-switch>
              <span class="leve3_title title--999">
                {{ !!item.value ? item.openShow : item.closeShow }}
              </span>
            </li>
          </ul>
        </div>
        <div class="hide-white" v-if="!functionOpen"></div>
        <div class="hide-white2" v-if="!functionOpen"></div>
      </div>
      <div class="plan-func-preview">
        <!-- 模拟开关 -->
        <div class="zdy--switch">
          <span
            :class="switchType === 'app' ? 'active' : ''"
            @click.prevent.stop="changeSwitch('app')"
          >
            手机预览
          </span>
          <span
            :class="switchType === 'pc' ? 'active' : ''"
            @click.prevent.stop="changeSwitch('pc')"
          >
            PC预览
          </span>
        </div>
        <!--PC预览,begin-->
        <div :class="['plan-func-pc', { zj: !chapterCompute }]" v-show="switchType === 'pc'">
          <span class="share-span" v-if="shareCompute"></span>
          <div class="icon-spans">
            <span class="reward-span" v-if="rewardCompute"></span>
            <span class="gift-span" v-if="giftCompute"></span>
            <span class="like-span" v-if="likeCompute"></span>
          </div>
          <span class="chat-stop" :class="chatCompute ? 'isChatStop' : ''" v-if="chapterCompute">
            {{ !chatCompute ? `您已被禁言` : `说点什么` }}
          </span>
          {{ dialogPosition }}
          <img
            :class="['exam_preview', { right: dialogPosition }]"
            src="../../common/images/plan-function/exam_preview.png"
            alt=""
          />
        </div>
        <!--手机预览,begin-->
        <div :class="['plan-func-app', { visible: !chapterCompute }]" v-show="switchType === 'app'">
          <span class="chat-span">{{ !chatCompute ? `您已被禁言` : `说点什么` }}</span>
          <div class="icon-spans">
            <span class="gift-span" v-if="giftCompute"></span>
            <span class="reward-span" v-if="rewardCompute"></span>
            <span
              class="like-span"
              v-if="likeCompute"
              :class="[likeNumCompute ? 'is-hide-like-num' : '']"
            ></span>
          </div>
        </div>
        <div
          :class="['plan-func-app', 'zj', { visible: chapterCompute }]"
          v-show="switchType === 'app'"
        >
          <span class="chat-span">{{ !chatCompute ? `您已被禁言` : `说点什么` }}</span>
          <div class="icon-spans">
            <span class="gift-span" v-if="giftCompute"></span>
            <span class="reward-span" v-if="rewardCompute"></span>
            <span
              class="like-span"
              v-if="likeCompute"
              :class="[likeNumCompute ? 'is-hide-like-num' : '']"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <begin-play
      :webinarId="$route.params.str"
      v-if="$route.query.type != 5 && webinarState != 4 && isInWebinar"
    ></begin-play>
    <!-- 裁剪组件 -->
    <cropper
      ref="avatarCropper"
      @cropComplete="cropComplete"
      @resetUpload="resetChooseFile"
      :ratio="200 / 200"
      :hideMode="true"
    ></cropper>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import { sessionOrLocal, isEmbed, buildOssUploadProcessData, compareTimes } from '@/utils/utils';
  import beginPlay from '@/components/beginBtn';
  import Upload from '@/components/Upload/main';
  import cropper from '@/components/Cropper/index';
  export default {
    name: 'planFunction',
    components: {
      PageTitle,
      beginPlay,
      Upload,
      cropper
    },
    data() {
      return {
        isEmbed: isEmbed(),
        switchType: 'app',
        userId: '',
        functionOpen: true,
        webinarState: JSON.parse(sessionOrLocal.get('webinarState')),
        keyList: [],
        liveKeyList: [],
        vm: null,
        defaultAvatar: '',
        domain_url: '',
        avatarType: 0,
        upload_process_data: '',
        loginSettingList: [], //登录设置
        hideMenuList: [], // 隐藏菜单
        playbackExpirationDateObj: {
          status: 0,
          type: '1',
          timeRange: '', //  07:36:26,11:36:26
          dateRange: ''
        },
        // 保存 全天 编辑数据
        playbackExpirationDateType1: {
          dateRange: ''
        },
        // 保存 部分时段 编辑数据
        playbackExpirationDateType2: {
          dateRange: ''
        }
      };
    },
    computed: {
      isInWebinar() {
        return this.$route.path.indexOf('/live/planFunction') > -1;
      },
      rewardCompute: function () {
        let voArr = this.keyList.filter(item => item.type === 'ui.hide_reward')[0];
        return !(voArr && voArr.value > 0);
      },
      giftCompute: function () {
        let voArr = this.keyList.filter(item => item.type === 'ui.hide_gifts')[0];
        return !(voArr && voArr.value > 0);
      },
      dialogPosition: function () {
        let voArr = this.keyList.find(item => item.type === 'ui.dialog_position');
        return !!voArr?.value;
      },
      shareCompute: function () {
        let voArr = this.keyList.filter(item => item.type === 'ui.watch_hide_share')[0];
        return !(voArr && voArr.value > 0);
      },
      likeCompute: function () {
        let voArr = this.keyList.filter(item => item.type === 'ui.watch_hide_like')[0];
        return !(voArr && voArr.value > 0);
      },
      likeNumCompute: function () {
        let item = this.keyList.filter(item => item.type === 'ui.watch_hide_like')[0]?.child;
        return item?.value === 1;
      },
      chatCompute: function () {
        let voArr = this.liveKeyList.filter(item => item.type === 'ui.watch_record_no_chatting')[0];
        return !(voArr && voArr.value > 0);
      },
      chapterCompute: function () {
        let voArr = this.liveKeyList.filter(item => item.type === 'ui.watch_record_chapter')[0];
        return !(voArr && voArr.value > 0);
      },
      reservationDesc() {
        if (this.functionOpen) {
          return '已开启，使用当前活动功能配置设置';
        } else {
          return '开启后，将使用当前活动功能配置设置';
        }
      },
      // 保存的上传图片的附加数据
      saveImageData() {
        let imageData = {
          path: 'avatar',
          type: 'image'
        };
        if (this.upload_process_data) {
          imageData.process = this.upload_process_data;
        }
        return imageData;
      },
      disabledPlaybackExpirationBtn() {
        if (this.playbackExpirationDateObj.type === '1') {
          return !(
            this.playbackExpirationDateObj.dateRange &&
            this.playbackExpirationDateObj.dateRange.length &&
            this.playbackExpirationDateObj.dateRange[0] &&
            this.playbackExpirationDateObj.dateRange[1]
          );
        } else if (this.playbackExpirationDateObj.type === '2') {
          return !(
            this.playbackExpirationDateObj.timeRange?.length &&
            this.playbackExpirationDateObj.timeRange[0] &&
            this.playbackExpirationDateObj.timeRange[1]
          );
        }
        return false;
      },
      disabledPlaybackExpirationDateRange() {
        if (this.playbackExpirationDateObj.type === '2') {
          return !(
            this.playbackExpirationDateObj.timeRange?.length &&
            this.playbackExpirationDateObj.timeRange[0] &&
            this.playbackExpirationDateObj.timeRange[1]
          );
        }
        return false;
      }
    },
    methods: {
      handleSwitchClick(item) {
        if (item.disabled) {
          this.$vhMessage.warning('合规自查中，该功能暂不支持使用');
        }
      },
      changeSwitch(type) {
        this.switchType = type;
      },

      messageInfo(title, type) {
        this.vm = this.$vhMessage({
          showClose: true,
          duration: 2000,
          message: title,
          type: type,
          customClass: 'zdy-info-box'
        });
      },
      changeStatus(callback, item, type, value) {
        let params = {
          permission_key: item.type,
          status: Number(callback)
        };

        if (value !== undefined) {
          params.value = value;
        }

        if (this.isInWebinar) {
          params.webinar_id = this.$route.params.str;
        }

        console.log('当前参数传递：', params);
        this.$fetch('planFunctionEdit', params)
          .then(res => {
            let reportData = {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.isInWebinar ? this.$route.params.str : '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            };
            if (type === 1) {
              this.$vhall_paas_port({
                k: Number(callback) === 1 ? item.num + 1 : item.num,
                data: reportData
              });
            } else {
              this.$vhall_paas_port({
                k: Number(callback) === 1 ? item.num : item.num + 1,
                data: reportData
              });
            }
            let str = `${!callback ? '开启' : '关闭'}`;
            //添加强制登录配置
            if (
              item.type === 'ui.watch_record_no_chatting' ||
              item.type === 'ui.watch_record_chapter' ||
              item.type === 'chat_report' ||
              item.type === 'pc_chat_report' ||
              item.type === 'watch.wechat_open' ||
              item.type === 'hard_login' ||
              item.type === 'ui.hidden_custom_menu'
            ) {
              str = `${!callback ? '关闭' : '开启'} `;
            }
            if (this.vm) {
              this.vm.close();
            }
            if (item.type === 'ui.dialog_position') {
              this.messageInfo(
                `已经设置弹窗位置为 ${item.value == 1 ? '靠右' : '居中'}`,
                'success'
              );
            } else if (item.type === 'ui.hidden_watch_like_data') {
              this.messageInfo(`${item.value === 1 ? '开启' : '关闭'} 隐藏点赞数据`, 'success');
            } else if (item.type === 'record_watch_expire') {
              if (params.status === 1) {
                this.messageInfo(`保存回放有效期成功`, 'success');
              } else {
                this.messageInfo(`关闭回放有效期`, 'success');
              }
            } else {
              this.messageInfo(`${str} ${item.key_name}`, 'success');
            }
            item.value = Number(callback);
          })
          .catch(res => {
            if (this.vm) {
              this.vm.close();
            }
            this.messageInfo(res.msg || `${str} ${item.key_name}`, 'error');
          });
      },
      changeAvatar(key, status, value) {
        const params = {
          permission_key: key,
          status,
          value
        };
        this.$fetch('planFunctionEdit', params).then(res => {
          if (res.code == 200) {
            if (status == 0) {
              this.$vhMessage.success('已切换为系统默认头像');
              return;
            }
            if (status == 1 && !this.domain_url) {
              this.$vhMessage.success('已切换为上传头像，请点击上传');
            } else {
              this.$vhMessage.success('头像设置成功');
            }
          }
        });
      },
      planSuccessRender(data) {
        let dataVo = JSON.parse(data);
        console.log(dataVo, '功能配置');

        let permissions;
        if (this.isInWebinar) {
          permissions = JSON.parse(sessionOrLocal.get('WEBINAR_PES', 'localStorage'));
        } else {
          permissions = JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'));
        }

        this.keyList = [
          {
            type: 'ui.hide_reward',
            key_name: '打赏功能',
            num: this.isInWebinar ? 100085 : 100621,
            openShow: '开启后，观看页显示打赏功能',
            closeShow: '已开启，观看页显示打赏功能',
            activeValue: 0,
            inactiveValue: 1,
            value: Number(dataVo['ui.hide_reward']) || 0,
            disabled: true,
            isShow: !this.isEmbed
          },
          {
            type: 'ui.watch_hide_like',
            key_name: '点赞功能',
            num: this.isInWebinar ? 100087 : 100623,
            openShow: '开启后，观看页显示点赞功能',
            closeShow: '已开启，观看页显示点赞功能',
            activeValue: 0,
            inactiveValue: 1,
            value: Number(dataVo['ui.watch_hide_like']) || 0,
            isShow: true,
            child: {
              type: 'ui.hidden_watch_like_data',
              value: Number(dataVo['ui.hidden_watch_like_data']) || 0,
              activeValue: 1,
              inactiveValue: 0
            }
          },
          {
            type: 'ui.hide_gifts',
            key_name: '礼物功能',
            num: this.isInWebinar ? 100089 : 100625,
            openShow: '开启后，观看页显示礼物功能',
            closeShow: '已开启，观看页显示礼物功能',
            activeValue: 0,
            inactiveValue: 1,
            value: Number(dataVo['ui.hide_gifts']) || 0,
            isShow: true
          },
          {
            type: 'ui.watch_hide_share',
            key_name: '分享功能',
            num: this.isInWebinar ? 100091 : 100627,
            openShow: '开启后，观看页显示分享功能（包含微信内分享）',
            closeShow: '已开启，观看页显示分享功能（包含微信内分享）',
            activeValue: 0,
            inactiveValue: 1,
            value: Number(dataVo['ui.watch_hide_share']) || 0,
            isShow: !this.isEmbed
          },
          {
            type: 'watch.wechat_open',
            key_name: '微信观看',
            openShow: '开启后，观众只能在微信环境内观看直播',
            closeShow: '已开启，观众只能在微信环境内观看直播',
            value: Number(dataVo['watch.wechat_open']) || 0,
            activeValue: 1,
            inactiveValue: 0,
            isShow: !this.isEmbed
          },
          {
            // 举报反馈
            type: 'pc_chat_report',
            key_name: 'PC举报反馈',
            num: this.isInWebinar ? 100093 : 100629,
            openShow: '开启后，PC观看页显示举报反馈入口',
            closeShow: '已开启，PC观看页显示举报反馈入口',
            activeValue: 1,
            inactiveValue: 0,
            value: Number(dataVo['pc_chat_report']) || 0,
            isShow: !this.isEmbed
          },
          {
            // 举报反馈
            type: 'chat_report',
            key_name: '手机举报反馈',
            num: this.isInWebinar ? 100093 : 100629,
            openShow: '开启后，手机观看页显示举报反馈入口',
            closeShow: '已开启，手机观看页显示举报反馈入口',
            activeValue: 1,
            inactiveValue: 0,
            value: Number(dataVo['chat_report']) || 0,
            isShow: !this.isEmbed
          },
          {
            type: 'ui.dialog_position',
            key_name: '弹窗位置',
            form_type: 'radio',
            num: 100091,
            txt: '设置问卷及快问快答在PC观看端展示位置',
            value: Number(dataVo['ui.dialog_position']) || 0,
            isShow: true
          }
        ];

        // ui.hidden_custom_menu  隐藏自定义菜单（开启后观看端自定义菜单会隐藏，播放器位置和大小会做响应调整）
        // 1.隐藏自定义菜单，0.不隐藏自定义菜单
        this.hideMenuList = [
          {
            type: 'ui.hidden_custom_menu',
            key_name: '隐藏菜单',
            activeValue: 1,
            inactiveValue: 0,
            openShow:
              '开启后，PC观看端不展示自定义菜单区域<br/>（使用文档/白板功能，请不要将菜单隐藏，否则将看不到视频画面）',
            closeShow:
              '已开启，PC观看端不展示自定义菜单区域<br/>（使用文档/白板功能，请不要将菜单隐藏，否则将看不到视频画面）',
            value: Number(dataVo['ui.hidden_custom_menu']) || 0
          },
          {
            type: 'ui.hidden_webinar_state_and_mode',
            key_name: '活动状态',
            activeValue: 0,
            inactiveValue: 1,
            openShow: '已开启，观看端展示活动状态标签和直播模式标签',
            closeShow: '开启后，观看端展示活动状态标签和直播模式标签',
            value: Number(dataVo['ui.hidden_webinar_state_and_mode']) || 0
          }
        ];

        this.liveKeyList = [
          {
            type: 'ui.watch_record_no_chatting',
            key_name: '回放禁言',
            num: this.isInWebinar ? 100093 : 100629,
            openShow: '已开启，回放/点播不支持聊天',
            closeShow: '开启后，回放/点播不支持聊天',
            value: Number(dataVo['ui.watch_record_no_chatting']) || 0,
            isShow: true
          }
        ];

        //初始化登录设置
        this.loginSettingList = [];

        if (permissions['ui.record_chapter'] > 0) {
          this.liveKeyList.push({
            type: 'ui.watch_record_chapter',
            key_name: '回放章节',
            num: this.isInWebinar ? 100095 : 100631,
            openShow: '已开启，回放/点播观看端显示文档章节',
            closeShow: '开启后，回放/点播观看端显示文档章节',
            value: Number(dataVo['ui.watch_record_chapter']) || 0,
            isShow: true
          });
        }
        //新增登录相关配置
        if (permissions['show_set_login'] > 0) {
          this.loginSettingList.push({
            type: 'hard_login',
            key_name: '强制登录',
            num: '', //数据打点的key值
            openShow: '已开启，预约/观看直播前，需完成登录 (嵌入页调起外层登录)',
            closeShow: '开启后，预约/观看直播前，需完成登录 (嵌入页调起外层登录)',
            value: Number(dataVo['hard_login']) || 0,
            isShow: true
          });
        }
      },
      planErrorRender(err) {
        this.$vhMessage({
          message: err.msg,
          showClose: true,

          type: 'error',
          customClass: 'zdy-info-box'
        });
        this.keyList = null;
      },

      planFunctionGet() {
        let params;
        if (this.isInWebinar) {
          params = {
            webinar_id: this.functionOpen ? this.$route.params.str : '',
            webinar_user_id: this.functionOpen ? this.userId : '',
            scene_id: 3
          };
        } else {
          params = {
            type: 2,
            scene_id: 3
          };
        }
        this.$fetch('planFunctionGet', this.$params(params))
          .then(res => {
            if (res.data) {
              const permission_value = JSON.parse(res.data.permission_value);
              const permissions = JSON.parse(res.data.permissions);
              this.isInWebinar &&
                (this.functionOpen = permissions['is_function_cofig'] > 0 ? true : false);
              this.domain_url =
                permission_value.system_default_avatar.length > 1
                  ? permission_value.system_default_avatar
                  : '';
              this.avatarType = Number(permissions.system_default_avatar_switch);
              this.planSuccessRender(res.data.permission_value);

              // 初始化 回放有效期配置
              try {
                const record_watch_expireStr = permission_value?.record_watch_expire;
                if (record_watch_expireStr) {
                  const record_watch_expire = JSON.parse(record_watch_expireStr);

                  // {
                  //   "status": this.playbackExpirationDateObj.status, //回放有效期开关 open: 开启  close: 关闭
                  //   "type": this.playbackExpirationDateObj.type, //类型：1.全天，2.部分时段
                  //   "start_date": "",//开始日期 2024-06-10 00:00:00  type=1时此项必填，格式为 yyyy-MM-dd HH:mm:ss；type=2时此项非必填，格式为 yyyy-MM-dd
                  //   "end_date": "",//结束日期 2024-06-28 23:59:59  同上
                  //   "start_time": "",//开始时间 08:10:00  type=2时此项必填，type=1时不接收此值
                  //   "end_time": ""//结束时间 09:10:00  同上
                  // }
                  const timeRange = [
                    record_watch_expire?.start_time || '',
                    record_watch_expire?.end_time || ''
                  ];
                  const dateRange = [
                    record_watch_expire?.start_date || '',
                    record_watch_expire?.end_date || ''
                  ];
                  this.playbackExpirationDateObj = {
                    status: record_watch_expire?.status || 0,
                    type: record_watch_expire?.type || '1',
                    timeRange,
                    dateRange
                  };

                  if (this.playbackExpirationDateObj.type === '1') {
                    // 保存 全天 编辑数据
                    this.playbackExpirationDateType1 = {
                      dateRange
                    };
                  } else {
                    // 保存 部分时段 编辑数据
                    this.playbackExpirationDateType2 = {
                      dateRange
                    };
                  }
                }
              } catch (e) {
                console.error(e);
              }
            }
          })
          .catch(res => {
            console.log(res);
            this.planErrorRender(res);
          });
      },
      closefunctionOpen() {
        let params = {
          webinar_id: this.$route.params.str,
          permission_key: 'is_function_cofig',
          status: Number(this.functionOpen)
        };
        console.log('当前参数传递：', params);
        this.$fetch('planFunctionEdit', params)
          .then(res => {
            this.$vhall_paas_port({
              k: this.functionOpen ? 100082 : 100083,
              data: {
                business_uid: this.userId,
                user_id: '',
                webinar_id: this.$route.params.str,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            if (!this.functionOpen) {
              this.functionOpen = false;
              this.planFunctionGet();
              this.$vhMessage({
                message: '正在使用账号下功能配置',
                showClose: true,
                type: 'warning',
                customClass: 'zdy-info-box'
              });
            } else {
              this.functionOpen = true;
              this.planFunctionGet();
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `操作失败`,
              showClose: true,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      toSettingDetail() {
        this.$vhall_paas_port({
          k: 100084,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: this.$route.params.str,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        const { href } = this.$router.resolve({ path: '/setting/function' });
        window.open(href, '_blank');
      },
      UploadAvatarSuccess(res, file) {
        if (res.data) {
          this.domain_url = res.data.domain_url;
          this.changeAvatar('system_default_avatar', undefined, this.domain_url);
        }
      },
      cropComplete(cropperData, url) {
        if (cropperData) {
          this.upload_process_data = buildOssUploadProcessData({
            cropperData: cropperData
          });
        } else {
          this.upload_process_data = '';
        }

        this.$nextTick(() => {
          // 这段代码将在下一个DOM更新循环后执行
          this.$refs.webinar_uploader.upload();
        });
      },
      resetChooseFile() {
        this.$refs.webinar_uploader.resetChooseFile();
      },
      deleteImage() {
        this.domain_url = '';
        this.changeAvatar('system_default_avatar', undefined, this.domain_url);
      },
      uploadError(err, file, fileList) {
        console.log('uploadError', err, file, fileList);
        this.$vhMessage({
          message: '头像上传失败',
          showClose: true,
          // duration: 0,
          type: 'error',
          customClass: 'zdy-info-box'
        });
      },
      handleUploadChange(file, fileList) {
        console.log('handleUploadChange----->', file, fileList);
        if (file.status == 'ready') {
          this.$checkUploadType(file.raw, this, 1, 2, () => {
            let reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = e => {
              this.$refs.avatarCropper.showModel(e.target.result, 2);
            };
          });
        }
      },
      playbackExpirationDateTypeChange(val) {
        console.log('playbackExpirationDateTypeChange val: ' + val);
        if (this.playbackExpirationDateObj.type === '1') {
          // 保存 全天 编辑数据
          this.playbackExpirationDateObj.dateRange = this.playbackExpirationDateType1.dateRange;
        } else {
          // 保存 部分时段 编辑数据
          this.playbackExpirationDateObj.dateRange = this.playbackExpirationDateType2.dateRange;
        }
        // this.playbackExpirationHandler()
      },
      timeRangeChange(val) {
        console.log('timeRangeChange val: ' + val);
        if (val && val.length) {
          let startTime = val[0];
          let endTime = val[1];
          if (startTime && endTime) {
            if (compareTimes(startTime, endTime)) {
              this.$vhMessage({
                message: '开始时间不能大于结束时间',
                showClose: true,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            }
          }
        }
        // this.playbackExpirationHandler()
      },
      dateRangeChange(val) {
        console.log('dateRangeChange val: ' + val);
        if (this.playbackExpirationDateObj.type === '1') {
          // 保存 全天 编辑数据
          this.playbackExpirationDateType1.dateRange = val;
        } else {
          // 保存 部分时段 编辑数据
          this.playbackExpirationDateType2.dateRange = val;
        }
        // this.playbackExpirationHandler()
      },
      playbackExpirationStatusChange(val) {
        console.log('playbackExpirationStatusChange val: ' + val);
        if (!val) {
          // 关闭时，直接调用接口
          this.playbackExpirationHandler();
        } else {
          // 开启时，需要 点击 【保存回放有效期】后调用接口
        }
      },
      playbackExpirationHandler() {
        let value = {
          status: this.playbackExpirationDateObj.status, //回放有效期开关 open: 开启  close: 关闭
          type: this.playbackExpirationDateObj.type, //类型：1.全天，2.部分时段
          start_date: '', //开始日期 2024-06-10 00:00:00  type=1时此项必填，格式为 yyyy-MM-dd HH:mm:ss；type=2时此项非必填，格式为 yyyy-MM-dd
          end_date: '', //结束日期 2024-06-28 23:59:59  同上
          start_time: '', //开始时间 08:10:00  type=2时此项必填，type=1时不接收此值
          end_time: '' //结束时间 09:10:00  同上
        };
        if (this.playbackExpirationDateObj.status + '' === '1') {
          if (this.playbackExpirationDateObj.type + '' === '2') {
            // 部分时段
            if (
              this.playbackExpirationDateObj.timeRange &&
              this.playbackExpirationDateObj.timeRange.length &&
              this.playbackExpirationDateObj.timeRange[0] &&
              this.playbackExpirationDateObj.timeRange[1]
            ) {
              value.start_time = this.playbackExpirationDateObj.timeRange[0] || '';
              value.end_time = this.playbackExpirationDateObj.timeRange[1] || '';

              if (compareTimes(value.start_time, value.end_time)) {
                this.$vhMessage({
                  message: '开始时间不能大于结束时间',
                  showClose: true,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
                return false;
              }

              if (
                this.playbackExpirationDateObj.dateRange &&
                this.playbackExpirationDateObj.dateRange.length
              ) {
                value.start_date =
                  this.playbackExpirationDateObj.dateRange[0]?.substring(0, 10) || '';
                value.end_date =
                  this.playbackExpirationDateObj.dateRange[1]?.substring(0, 10) || '';
              }
            } else {
              // 部分时段时，时间必填
              this.$vhMessage({
                message: '请选择时间',
                showClose: true,
                type: 'error',
                customClass: 'zdy-info-box'
              });
              return false;
            }
          } else {
            // 全天
            value.start_time = '';
            value.end_time = '';
            if (
              this.playbackExpirationDateObj.dateRange &&
              this.playbackExpirationDateObj.dateRange.length
            ) {
              value.start_date = this.playbackExpirationDateObj.dateRange[0] || '';
              value.end_date = this.playbackExpirationDateObj.dateRange[1] || '';
            } else {
              // 全天时段时，日期必填
              this.$vhMessage({
                message: '请选择日期',
                showClose: true,
                type: 'error',
                customClass: 'zdy-info-box'
              });
              return false;
            }
          }
        }
        this.changeStatus(
          this.playbackExpirationDateObj.status,
          { type: 'record_watch_expire' },
          3,
          JSON.stringify(value)
        );
      }
    },
    created() {
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
      this.planFunctionGet();
    }
  };
</script>

<style lang="less" scoped>
  .label__r12 {
    display: inline-block;
    width: 84px;
    text-align: right;
  }
  @import '../../common/css/common.less';
  @import '../../common/css/base.less';
  .page-padding {
    padding: 0 0;
    .title_text {
      color: #999;
      font-size: 14px;
      span {
        color: #3562fa;
        cursor: pointer;
        vertical-align: middle;
      }
    }
  }
  .plan-func-main {
    display: flex;
    padding: 48px 0;
    background: #fff;
    min-height: 816px;
    border-radius: 4px;
  }

  .plan-func-form {
    width: 510px;
    margin-right: 64px;
    position: relative;
  }
  .hide-white {
    position: absolute;
    width: 100%;
    height: 720px;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 9;
  }
  //用来遮挡示意图下面部分，100%宽度区域
  .hide-white2 {
    position: absolute;
    width: calc(100% + 515px);
    height: calc(100% - 720px);
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 9;
  }
  .h1__title {
    margin-bottom: 32px;
  }
  .div__func {
    min-height: 190px;
    background: @background_white;
    border-radius: 4px;
    margin: 0 auto 20px auto;
    &.div__playback {
      margin-right: -514px; // 确保内容宽度铺满
    }
  }
  .div__view__title {
    margin-left: 56px;
    font-size: @font_size_16;

    font-weight: 400;
    color: @font_color_h1;
    line-height: 22px;
  }
  .switch__list {
    padding-top: 24px;
    padding-bottom: 32px;
    li {
      display: block;
      list-style-type: none;
      margin-bottom: 32px;
      margin-left: 88px;
      line-height: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &.radio__box {
        display: flex;
        .radio_content {
          width: 0;
          flex-grow: 1;
        }
        .setting_txt {
          color: #8c8c8c;
          font-size: 14px;
          margin-bottom: 10px;
        }
      }

      .watch-hide-like-num {
        margin-left: 96px;
        margin-top: 10px;
      }

      &.record_expire_date {
        display: flex;
        margin-left: 74px;
        .record_expire_date_right {
          flex: 1;
          .record_expire_date_text {
            //display: flex;
            //align-items: center;
            //gap: 4px;
            .vh-switch {
              margin-right: 4px;
            }

            span {
              margin-right: 4px;
              line-height: normal;
            }
          }
          .record_expire_date_text {
            margin-bottom: 10px;
          }
          .record_expire_date_radio_group {
            margin-bottom: 0;
          }
          .record_expire_date_picker_box {
            .record_expire_date_picker {
              width: 240px;
              &.record_expire_date_time_picker {
                width: 360px;
              }
            }
            &.record_expire_date_picker_box {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  .plan-func-preview {
    position: relative;
    height: max-content; // 避免占高，遮挡底部 回放有效期 部分时段选择区域小手
  }
  .plan-func-pc {
    width: 440px;
    height: 254px;
    background-image: url('../../common/images/plan-function/pc-dafault2.png');
    background-size: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
    -webkit-transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -o-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
    .exam_preview {
      position: absolute;
      width: 150px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      &.right {
        left: auto;
        right: 0;
        transform: translateY(-50%);
      }
    }
    &.zj {
      background-image: url('../../common/images/plan-function/pc-zj2.png');
    }
    .share-span {
      position: absolute;
      top: 23px;
      right: 53px;
      display: inline-block;
      width: 8px;
      height: 10px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-left: 2px;
      background-image: url('../../common/images/plan-function/share-pc.png');
    }
    .chat-stop {
      position: absolute;
      bottom: 35px;
      right: 75px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 12px;
      transform: scale(0.6);
      &.isChatStop {
        right: 85px;
      }
    }
    .icon-spans {
      position: absolute;
      bottom: 31px;
      right: 150px;
      span {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-left: 2px;
      }
      .reward-span {
        background-image: url('../../common/images/plan-function/pc-reward@2x.png');
      }
      .like-span {
        background-image: url('../../common/images/plan-function/pc-like@2x.png');
      }
      .gift-span {
        background-image: url('../../common/images/plan-function/pc-gift@2x.png');
      }
    }
  }
  .plan-func-app {
    width: 420px;
    height: 684px; // 避免手机预览底部遮挡 回放有效期文案
    margin-top: -24px;
    margin-left: -47px;
    background-image: url('../../common/images/plan-function/phone-default.png');
    background-size: 100%;
    background-position: center;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 54px;
    -webkit-transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -o-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
    &.visible {
      opacity: 0;
      filter: alpha(opacity=0);
    }
    &.zj {
      background-image: url('../../common/images/plan-function/phone-zj.png');
    }
    .chat-span {
      position: absolute;
      bottom: 60px;
      left: 78px;
      height: 12px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 12px;
    }
    .icon-spans {
      position: absolute;
      bottom: 50px;
      right: 60px;
      span {
        display: inline-block;
        width: 28px;
        height: 28px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .like-span {
        background-image: url('../../common/images/plan-function/phone-like@2x.png');
        &.is-hide-like-num {
          background-image: url('../../common/images/plan-function/praise-icon.png');
          background-size: 20px 20px;
          background-position: center;
        }
      }
      .gift-span {
        background-image: url('../../common/images/plan-function/phone-gift@2x.png');
      }
      .reward-span {
        background-image: url('../../common/images/plan-function/phone-reward@2x.png');
      }
    }
  }
  .upload_box {
    margin-top: 32px;
    margin-left: 88px;
  }
  .upload__avatar {
    margin-top: 10px;
    width: 130px;
    height: 130px;
    /deep/ .vh-upload--picture-card {
      height: 100%;
    }
  }
  .notice_txt {
    color: #999;
    font-size: 12px;
    margin-top: 10px;
    line-height: 16px;
  }

  .preview_status_tooltip {
    img {
      width: 240px;
    }
  }
</style>
<style lang="less">
  .vh-tooltip__popper {
    &.preview_status {
      background: transparent !important;
      box-shadow: none;
      padding: 0;
    }
  }
  .playback-expired-popover {
    padding: 0;
    img {
      margin-bottom: -10px;
    }
    &.app {
      border-radius: 34px;
    }
  }
</style>
