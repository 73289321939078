export const imgPositionSizeMap = {
  1: {
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  2: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left top'
  },
  3: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }
};
export const imgParseMap = {
  1: {
    'object-fit': 'fill'
  },
  2: {
    'object-fit': 'cover',
    'object-position': 'left top'
  },
  3: {
    'object-fit': 'contain',
    'object-position': 'center'
  }
};
