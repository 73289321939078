<template>
  <div class="summary-wrapbox">
    <div class="summary-top">
      <div
        :class="{
          'summary-top-text': true,
          bold: WEBINAR_PES['ai_creation'] != 1
        }"
      >
        {{
          WEBINAR_PES['ai_creation'] != 1
            ? '「概要总结」是收费功能，请联系您的客户经理或专属售后，也可拨打400-888-970转2咨询'
            : '设置后，观看端将让视频的核心内容以总结方式呈现，让关键信息一目了然。请在回放管理中创作「概要总结」'
        }}
      </div>
      <vh-button
        v-if="WEBINAR_PES['ai_creation'] != 1"
        class="summary-top-btn"
        @click="contactCustormer"
      >
        在线客服
      </vh-button>
      <vh-button
        v-if="WEBINAR_PES['ai_creation'] == 1"
        class="summary-top-btn active"
        @click="goPage"
      >
        马上创作
      </vh-button>
    </div>
    <div class="summary-middle">
      <div class="summary-middle-tip">
        将活动视频内容，转化成文字。
        <span>沉淀讲解内容，关键信息一目了然。</span>
      </div>
      <div class="summary-middle-message">
        <div class="summary-middle-message-direction">
          <div class="summary-middle-message-direction-item">
            <i
              class="summary-middle-message-direction-item-icon vh-iconfont vh-a-line-livemanagement"
            ></i>
            <div class="summary-middle-message-direction-item-text">视频</div>
          </div>
          <img
            class="summary-middle-message-direction-icon"
            src="../../../../../common/images/custom-tab/moment-arrow.png"
            alt=""
          />
          <div class="summary-middle-message-direction-item">
            <i class="summary-middle-message-direction-item-icon vh-iconfont vh-wenben"></i>
            <div class="summary-middle-message-direction-item-text">文字</div>
          </div>
        </div>
        <div class="summary-middle-message-list">
          <div class="summary-middle-message-list-item">
            <div class="summary-middle-message-list-item-title">
              对活动视频进行智能解析
              <br />
              AI快转生成文字和关键词
            </div>
            <div class="summary-middle-message-list-item-text">
              摘要模式：提取关键信息，自动总结，核心内容一目了然
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="summary-bottom">
      <div class="summary-bottom-item">
        <h1>
          管理端 - 智能提取关键信息，
          <br />
          自动总结，让核心内容一目了然
        </h1>
        <img
          class="summary-bottom-console-img"
          src="../../../../../common/images/custom-tab/summary-console.png"
        />
      </div>
      <div class="summary-bottom-item">
        <h1>
          观看端 - 活动内容清晰明确，
          <br />
          查看摘要适用于回放/录播场景
        </h1>
        <img
          class="summary-bottom-wap-img"
          src="../../../../../common/images/custom-tab/summary-wap.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { sessionOrLocal } from '@/utils/utils';
  export default {
    data() {
      return {
        WEBINAR_PES: {}
      };
    },
    created() {
      const permissions = sessionOrLocal.get('WEBINAR_PES', 'localStorage');
      this.WEBINAR_PES = JSON.parse(permissions);
    },
    methods: {
      // 马上创作
      goPage() {
        const liveDetailInfo = JSON.parse(sessionOrLocal.get('liveDetailInfo'));
        // if ((process.env.VUE_APP_AI_HOST || '').indexOf('localhost') > -1) {
        //   const token = sessionOrLocal.get('token', 'localStorage') || '';
        //   window.location.href = `${process.env.VUE_APP_AI_HOST}video/summary/${liveDetailInfo.record_id}?token=${token}`;
        // } else {
        //   window.location.href = `${process.env.VUE_APP_AI_HOST}v3/saas-web-ai/video/summary/${liveDetailInfo.record_id}`;
        // }
        console.log(liveDetailInfo, window.location.origin);
        // window.location.href = `${window.location.origin}/live/playback/${liveDetailInfo.id}`;
        window.open(`${window.location.origin}/v3/live/playback/${liveDetailInfo.id}`, '_blank');
      },

      // 联系客服
      contactCustormer() {
        window.open(`https://vhall.s4.udesk.cn/im_client/?web_plugin_id=15038`, '_blank');
      }
    }
  };
</script>

<style lang="less" scoped>
  .summary-wrapbox {
    padding: 1px 0;
    box-sizing: border-box;

    .summary-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-text {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 22px;
        &.bold {
          font-weight: 600;
        }
      }
      &-btn {
        width: 104px;
        height: 36px;
        padding: 0;
        border: 1px solid #fb2626;
        font-size: 14px;
        color: #fb2626;
        border-radius: 18px;
        margin-top: 24px;

        &.active {
          background: #fb2626;
          border: none;
          color: #ffffff;
        }
      }
    }

    .summary-middle {
      margin-top: 24px;

      &-tip {
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        display: flex;

        span {
          color: #fb2626;
          font-weight: 600;
        }
      }

      &-message {
        margin-top: 12px;
        background: #faf7f6;
        height: 204px;

        &-direction {
          padding: 12px 37px;
          display: flex;
          justify-content: center;
          align-items: center;

          &-icon {
            width: 20px;
            height: 20px;
            display: block;
            margin: 0 32px;
          }

          &-item {
            height: 54px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px 32px;
            border-radius: 27px;
            background: #ffffff;

            &-icon {
              margin-right: 16px;
              color: #fb2626;
              font-size: 20px;
            }

            &-text {
              font-size: 16px;
              font-weight: 500;
              color: #262626;
            }
          }
        }

        &-list {
          display: flex;
          flex-direction: column;
          align-items: center;

          &-item {
            width: 171px;
            &:last-child {
              margin-top: 8px;
            }
            &-title {
              line-height: 22px;
              font-size: 14px;
              font-weight: 600;
              color: #262626;
            }
            &-text {
              margin-top: 4px;
              line-height: 22px;
              font-size: 14px;
              font-weight: 400;
              color: #262626;
            }
          }
        }
      }
    }

    .summary-bottom {
      height: 311px;
      background: #ebefff;
      margin-top: 12px;
      position: relative;
      padding: 12px 24px;
      display: flex;
      justify-content: center;

      &-item {
        min-width: 162px;
        h1 {
          line-height: 20px;
          font-size: 12px;
          font-weight: 600;
          color: #262626;
        }
      }

      &-console-img {
        width: 210px;
        margin-top: 16px;
      }
      &-wap-img {
        display: block;
        width: 110px;
        margin: 0 auto;
        margin-top: 16px;
      }
    }
  }
</style>
