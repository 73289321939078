import Layout from '@/layout/index';
import other from './other';

const router = [
  {
    path: '/datahub',
    name: 'datahub',
    redirect: '/datahub/overview',
    component: Layout,
    meta: {
      title: '数据总览',
      name: 'datahub',
      icon: 'saasicon_datahub_overview_img'
    },
    children: [
      {
        path: 'overview',
        component: () => import('@/views/DataHub/index.vue'),
        meta: {
          auth: true,
          title: '数据总览',
          icon: 'saasicon_datahub_overview_img',
          level: 1
        }
      }
    ]
  },
  {
    path: '/datahub/lives',
    name: 'LiveAnalysis',
    redirect: '/datahub/lives/overview',
    component: Layout,
    meta: {
      title: '活动分析',
      name: 'Datahub',
      icon: 'saasicon_datahub_lives_img'
    },
    children: [
      {
        path: 'overview',
        component: () => import('@/views/DataHub/index.vue'),
        meta: {
          auth: true,
          title: '活动分析',
          icon: 'saasicon_datahub_lives_img',
          level: 1,
          name: 'sysHome'
        }
      }
    ]
  },
  {
    path: '/datahub/invite',
    redirect: '/datahub/invite/overview',
    name: 'InviteAnalysis',
    component: Layout,
    meta: {
      title: '邀约分析',
      name: 'Datahub',
      icon: 'saasicon_datahub_invite_img'
    },
    children: [
      {
        path: 'overview',
        component: () => import('@/views/DataHub/index.vue'),
        meta: {
          auth: true,
          title: '邀约分析',
          icon: 'saasicon_datahub_invite_img',
          level: 1,
          name: 'sysHome'
        }
      }
    ]
  },
  {
    path: '/datahub/interact',
    redirect: '/datahub/interact/overview',
    name: 'InteractAnalysis',
    component: Layout,
    meta: {
      title: '互动分析',
      name: 'Datahub',
      icon: 'saasicon_datahub_interact_img'
    },
    children: [
      {
        path: 'overview',
        component: () => import('@/views/DataHub/index.vue'),
        meta: {
          auth: true,
          title: '互动分析',
          icon: 'saasicon_datahub_interact_img',
          level: 1,
          name: 'sysHome'
        }
      }
    ]
  },
  {
    path: '/datahub/live',
    name: 'SingleLive',
    component: Layout,
    hidden: true,
    meta: {
      title: '单活动',
      name: 'SingleLive',
      icon: 'saasicon_home_img'
    },
    children: [
      {
        path: 'detail/:id',
        component: () => import('@/views/DataHub/index.vue'),
        meta: {
          auth: true,
          title: '单活动',
          icon: 'saasicon_home_img',
          level: 1,
          name: 'SingleLive',
          activeMenu: '/datahub/lives/overview'
        }
      }
    ]
  },
  {
    path: '/datahub/user',
    name: 'datahub',
    component: Layout,
    meta: {
      title: '用户分析',
      icon: 'saasicon_datahub_user_img'
    },
    children: [
      {
        path: 'overview',
        component: () => import('@/views/DataHub/index.vue'),
        meta: {
          name: 'User',
          title: '用户分析'
        }
      }
    ]
  },
  // 原用户运营
  {
    path: '/datahub/operation',
    name: 'datahub',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '用户运营',
      icon: 'saasicon_datahub_user_operation_img'
    },
    children: [
      {
        path: 'leads',
        component: () => import('@/views/DataHub/index.vue'),
        meta: {
          name: 'Leads',
          title: '用户池'
        }
      }
    ]
  },
  ...other
];
export default router;
