import Vue from 'vue';
import WujieVue from 'wujie-vue2';
import router from '../router';
import hostMap from './hostMap';
// import plugins from './plugin';
import lifecycles from './lifecycle';

const { setupApp, preloadApp, bus } = WujieVue;

Vue.use(WujieVue);

// 在 xxx-sub 路由下子应用将激活路由同步给主应用，主应用跳转对应路由高亮菜单栏
bus.$on('sub-route-change', (name, route) => {
  if (window.$wujie) {
    return false;
  }
  const mainPath = `/${name}${route.fullPath}`;
  const currentPath = router.currentRoute.path;
  if (mainPath !== currentPath) {
    router.push({ path: mainPath });
  }
});



// 子应用采用降级iframe方案
const degrade = !window.Proxy || !window.CustomElementRegistry;

const props = {
  jump: name => {
    router.push({ name });
  }
};

/**
 * 配置应用，主要是设置默认配置
 * preloadApp、startApp的配置会基于这个配置做覆盖
 */
setupApp({
  name: 'watch',
  url: hostMap('CMS'),
  exec: true,
  props,
  // plugins,
  degrade,
  ...lifecycles
});

// preloadApp({
//   name: 'cms'
// });
