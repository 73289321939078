<template>
  <div class="vh-desc">
    顶峰时期，Flash
    Player在全球有10亿次安装量，超过200万Flash开发者。如今，Flash因为封闭和能耗问题，并且Flash权限过高且运行黑盒，Chrome开始禁掉Flash。紧跟时代趋势，深切客户需求的，我们紧跟发展的步伐上线H5播放器功能！新版本不仅为企业客户降低了运营操作的成本，也通过提升观众用户的使用体验，来达到助力企业客户高效获客，轻松转化的目的。
  </div>
</template>
<script>
  export default {};
</script>
<style lang="less" scoped>
  .vh-desc {
    line-height: 24px;
    text-indent: 2em;
    color: #1a1a1a;
  }
</style>
