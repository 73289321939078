<template>
  <div>
    <question :questionId="questionId" ref="preQuestions"></question>
  </div>
</template>
<script>
  import question from './question';
  export default {
    props: ['questionId'],
    components: {
      question
    }
  };
</script>

<style lang="less" scoped>
  #showQuestion {
    ::v-deep .line {
      background: #fb3a32;
    }
    ::v-deep .h5-text i {
      color: #fb3a32;
    }
    ::v-deep .q-title {
      padding: 0 15px !important;
    }
  }
</style>
