<template>
  <vh-dialog
    title="图片处理"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="appendToBody"
    :modal-append-to-body="modalAppendToBody"
    width="600px"
  >
    <div class="cropper_content">
      <div class="cropper_list">
        <vh-radio-group v-model="showMode" v-if="!hideMode">
          <vh-radio :label="1">全屏拉伸</vh-radio>
          <vh-radio :label="2">等比缩放自适应</vh-radio>
          <vh-radio :label="3">等比缩放显示全图</vh-radio>
        </vh-radio-group>
      </div>
      <div class="cropper_content_wrapper">
        <div class="cropper_image" v-if="isShowImages">
          <img :style="blurStyle" :src="url" />
        </div>
        <div class="cropper_content_box" v-if="!isShowImages">
          <vue-cropper
            ref="cropper"
            class="cropper_img"
            :src="url"
            :aspect-ratio="ratio"
            :viewMode="1"
            :style="{ width: width + 'px', height: height + 'px' }"
            :autoCropArea="1"
          ></vue-cropper>
        </div>
        <!-- <div class="image_cropper">
          <div class="image_cropper_item">
            <span class="t_label">模糊度</span>
            <vh-slider
              v-model="blurryDegree"
              :max="100"
              :disabled="!url"
              style="width: 400px"
            ></vh-slider>
            <span class="wid_block">{{ blurryDegree }}</span>
          </div>
          <div class="image_cropper_item">
            <span class="t_label">亮度</span>
            <vh-slider
              v-model="lightDegree"
              :max="100"
              :disabled="!url"
              style="width: 400px"
            ></vh-slider>
            <span class="wid_block">{{ lightDegree }}</span>
          </div>
        </div> -->
      </div>
      <div class="cropper_content_btn">
        <div>
          <!-- 一个按钮会有样式问题 裁剪&取消裁剪-->
          <vh-button size="medium" plain round @click="changeValue" v-if="isShowImages">
            裁剪
          </vh-button>
          <vh-button size="medium" plain round @click="changeValue" v-if="!isShowImages">
            取消裁剪
          </vh-button>
          <vh-button size="medium" plain type="text" v-preventReClick @click="resetCropper">
            重新上传
          </vh-button>
        </div>
        <div>
          <vh-button size="medium" type="info" plain round v-preventReClick @click="cancelCropper">
            取消
          </vh-button>
          <vh-button size="medium" type="primary" round v-preventReClick @click="cropperSure">
            确定
          </vh-button>
        </div>
      </div>
    </div>
  </vh-dialog>
</template>

<script>
  import vueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';

  export default {
    props: {
      // 比例
      ratio: {
        type: Number,
        default: 16 / 9
      },
      width: {
        default: 536
      },
      height: {
        default: 300
      },
      appendToBody: {
        default: false,
        required: false
      },
      modalAppendToBody: {
        default: true,
        required: false
      },
      hideMode: {
        default: false
      },
      currentMode: {
        default: 1,
        required: false
      }
    },
    data() {
      return {
        dialogVisible: false,
        isShowImages: true,
        showMode: 1,
        cropperImgUrl: '',
        // cropperData: {
        //   x: 0,
        //   y: 0,
        //   width: 0,
        //   height: 0
        // },
        cropperData: '',
        url: '',
        index: 1,
        blurryDegree: 0,
        lightDegree: 100
      };
    },
    computed: {
      blurStyle() {
        return {
          filter: `blur(${this.blurryDegree}px)`,
          opacity: this.lightDegree / 100
        };
      }
    },
    components: {
      vueCropper
    },
    methods: {
      showModel(url, index, mode) {
        this.url = url;
        this.index = index || 0;
        this.dialogVisible = true;
        if(mode){
          this.showMode = parseInt(mode, 10);
        }else{
          this.showMode = parseInt(this.currentMode, 10);
        }
        this.isShowImages = true;
        this.cropperData = '';
        // this.cropperData = {
        //   x: 0,
        //   y: 0,
        //   width: 0,
        //   height: 0
        // };
      },
      changeValue() {
        this.isShowImages = !this.isShowImages;
      },
      cancelCropper() {
        this.dialogVisible = false;
        this.isShowImages = true;
        this.$refs.cropper && this.$refs.cropper.destroy();
      },
      cropperSure() {
        if (!this.isShowImages) {
          this.cropperData = this.$refs.cropper.getData();
        }
        /**
         * cropperData:裁剪数据
         * url: 图片地址
         * showMode: 选择的图片方式
         * index: 一个页面可能用到多次裁剪组件，用来区分
         * blurryDegree: 模糊度
         * lightDegree: 亮度
         */
        this.$emit(
          'cropComplete',
          this.cropperData,
          this.url,
          Number(this.showMode),
          this.index,
          this.blurryDegree,
          this.lightDegree
        );
        this.resetCropperData();
      },
      resetCropperData() {
        this.dialogVisible = false;
        this.url = '';
        this.cropperData = '';
        this.blurryDegree = 0;
        this.lightDegree = 100;
        this.$refs.cropper && this.$refs.cropper.destroy();
      },
      resetCropper() {
        this.resetCropperData();
        this.$emit('resetUpload', this.index);
      }
    }
  };
</script>
<style lang="less" scoped>
  .cropper_content {
    width: 100%;
    &_wrapper {
      width: 100%;
      position: relative;
    }
    .cropper_list {
      padding-bottom: 10px;
    }
    .cropper_image {
      width: 100%;
      height: 300px;
      border-radius: 4px;
      background: #8c8c8c;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: scale-down;
      }
    }
    &_box {
      background: #8c8c8c;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .cropper_img {
        height: 300px;
        width: 536px;
        margin: 0 auto;
      }
    }
    // .image_cropper {
    //   width: 100%;
    //   margin-top: 16px;
    //   &_item {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     span {
    //       color: #595959;
    //     }
    //     .t_label {
    //       display: inline-block;
    //       width: 60px;
    //     }
    //     .wid_block {
    //       display: inline-block;
    //       width: 30px;
    //     }
    //   }
    // }
    &_btn {
      padding: 24px 0 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>
