<template>
  <div class="vh-preview-box">
    <component
      :is="componentName"
      :info="info"
      :mode="mode || 1"
      @updateInfo="updateInfo"
      :pre="pre"
    ></component>
  </div>
</template>
<script>
  import ComponentImgTxt from './component-img-txt';
  import ComponentQrCode from './component-qr-code';
  import ComponentVideo from './component-video';
  import ComponentTextlink from './component-text-link';
  import ComponentImglink from './component-img-link';
  import ComponentSpecial from './component-special';
  import ComponentTitle from './component-title';
  import ComponentHr from './component-hr';
  import ComponentRank from './component-rank';
  import componentThirdlink from './component-third-link';

  import { componentMap } from '../componentMap';
  import eventsType from '../../EventConts';

  export default {
    props: {
      info: {
        required: true
      },
      mode: {
        required: false
      },
      pre: {
        required: true
      }
    },

    components: {
      'component-img-txt': ComponentImgTxt,
      'component-qr-code': ComponentQrCode,
      'component-video': ComponentVideo,
      'component-special': ComponentSpecial,
      'component-text-link': ComponentTextlink,
      'component-img-link': ComponentImglink,
      'component-title': ComponentTitle,
      'component-hr': ComponentHr,
      'component-rank': ComponentRank,
      'component-third-link': componentThirdlink
    },

    mounted() {
      console.log('当前组件信息！', this.info);
    },

    data() {
      return {};
    },

    computed: {
      componentName: function () {
        console.log('component-name:: =>', componentMap[this.info.component_id]);
        return `component-${componentMap[this.info.component_id]}`;
      }
    },
    methods: {
      updateInfo(val) {
        console.log('自定组件更新信息 updateInfo------>', val);
        this.$EventBus.$emit(eventsType.UPDATE_COMPONENT_CONTENT, val);
      }
    }
  };
</script>
<style lang="less" scoped>
  .vh-preview-box {
    user-select: none;
    color: #1a1a1a;
    line-height: 24px;
  }
</style>
